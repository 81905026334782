import React, { Component } from 'react';
import { any, array, func, object, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import invariant from 'invariant';
import {
  updateUI,
  massUpdateUI,
  setDefaultUI,
  mountUI,
  unmountUI,
} from './actions';

import getUIState from './utils';

export function ui(key, opts = {}) {
  if (typeof key === 'object') {
    opts = key;
    key = opts.key;
  }

  const connector = connect(
    state => {
      return { ui: getUIState(state) };
    },
    dispatch =>
      // mirrors the original object, but with each function immediately dispatching the action
      // returned by the corresponding action creator. If you passed a function as actionCreators,
      // the return value will also be a single function.
      bindActionCreators(
        {
          updateUI,
          massUpdateUI,
          setDefaultUI,
          mountUI,
          unmountUI,
        },
        dispatch,
      ),
    opts.mergeProps,
    opts.options,
  );

  return WrappedComponent => {
    return connector(
      class UI extends Component {
        constructor(props, ctx, queue) {
          super(props, ctx, queue);

          if (key === undefined) {
            // If the key is undefined generate a new random hex key for the
            // current component's UI scope.
            this.key =
              (WrappedComponent.displayName || WrappedComponent.name) +
              Math.floor(Math.random() * (1 << 30)).toString(16);
          } else {
            this.key = key;
          }

          this.getMergedContextVars(ctx);
        }

        static propTypes = {
          ui: object.isRequired,
          setDefaultUI: func.isRequired,
          updateUI: func.isRequired,
          massUpdateUI: func.isRequired,
        };

        static childContextTypes = {
          uiKey: string,
          uiPath: array,
          uiVars: object,

          updateUI: func,
          resetUI: func,
        };

        static contextTypes = {
          store: any,

          uiKey: string,
          uiPath: array,
          uiVars: object,

          updateUI: func,
          resetUI: func,
        };

        componentWillMount() {
          if (this.props.ui.getIn(this.uiPath) === undefined && opts.state) {
            const state = this.getDefaultUIState(opts.state);
            this.context.store.dispatch(
              mountUI(this.uiPath, state, opts.reducer),
            );
          }
        }

        componentWillReceiveProps(nextProps) {
          const ui = getUIState(this.context.store.getState());
          if (ui.getIn(this.uiPath) === undefined && opts.state) {
            const state = this.getDefaultUIState(opts.state, nextProps);
            this.props.setDefaultUI(this.uiPath, state);
          }
        }

        getDefaultUIState(uiState, props = this.props) {
          const globalState = this.context.store.getState();
          let state = { ...uiState };
          Object.keys(state).forEach(k => {
            if (typeof state[k] === 'function') {
              state[k] = state[k](this.props, globalState);
            }
          });
          return state;
        }

        componentWillUnmount() {
          if (opts.persist !== true) {
            // if (window && window.requestAnimationFrame) {
            //   window.requestAnimationFrame(() =>
            //     this.props.unmountUI(this.uiPath),
            //   );
            // } else {
            //   this.props.unmountUI(this.uiPath);
            // }
          }
        }

        getMergedContextVars(ctx = this.context) {
          if (!this.uiVars || !this.uiPath) {
            const uiPath = ctx.uiPath || [];
            this.uiPath = uiPath.concat(this.key);

            const state = opts.state || {};
            this.uiVars = { ...ctx.uiVars } || {};
            Object.keys(state).forEach(
              k => (this.uiVars[k] = this.uiPath),
              this,
            );
          }

          return [this.uiVars, this.uiPath];
        }

        getChildContext() {
          let [uiVars, uiPath] = this.getMergedContextVars();

          return {
            uiKey: this.key,
            uiVars,
            uiPath,

            updateUI: this.updateUI,
            resetUI: this.resetUI,
          };
        }

        resetUI = () => {
          this.props.setDefaultUI(
            this.uiPath,
            this.getDefaultUIState(opts.state),
          );
        };

        updateUI = (name, value) => {
          const [uiVars] = this.getMergedContextVars();
          const uiVarPath = uiVars[name];

          if (typeof name === 'object' && value === undefined) {
            this.props.massUpdateUI(this.uiVars, name);
            return;
          }

          invariant(
            uiVarPath,
            `The '${name}' UI variable is not defined in the UI context in "` +
              (WrappedComponent.displayName || WrappedComponent.name) +
              '" ' +
              'or any parent UI context. Set this variable using the "state" ' +
              'option in the @ui decorator before using it.',
          );

          this.props.updateUI(uiVarPath, name, value);
        };

        mergeUIProps() {
          const ui = getUIState(this.context.store.getState());

          return (
            Object.keys(this.uiVars).reduce((props, k) => {
              props[k] = ui.getIn(this.uiVars[k].concat(k));
              return props;
            }, {}) || {}
          );
        }

        render() {
          return (
            <WrappedComponent
              {...this.props}
              uiKey={this.key}
              uiPath={this.uiPath}
              ui={this.mergeUIProps()}
              resetUI={this.resetUI}
              updateUI={this.updateUI}
            />
          );
        }
      },
    );
  };
}

export default { ui };
