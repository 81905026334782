import React, { Component } from 'react';
import Button from './button';
import classNames from 'classnames';

export class SocialSharingBar extends Component {
  state = { copied: false };

  copyToClipboard = () => {
    const copyTextarea = document.querySelector('#uniqueLink');
    copyTextarea.select();
    /* eslint-disable */
    try {
      document.execCommand('copy');
      this.setState({ copied: true }, this.setCopiedTimeout);
    } catch (err) {}
    /* eslint-enable */
  };

  setCopiedTimeout = () => {
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };

  renderCopy = () => (
    <div className="copy-box flex-span">
      <div className="input-addon">
        <input
          id="uniqueLink"
          className="form-control"
          type="text"
          value={this.props.url || ''}
          readOnly
        />

        <button
          onClick={this.copyToClipboard}
          className={classNames('button-copy', { copied: this.state.copied })}
        >
          {this.state.copied ? 'Copied' : 'Copy'}
        </button>
      </div>
    </div>
  );

  render() {
    return (
      <div className="social-sharing-bar">
        <div className="buttons">
          <Button domain="FACEBOOK" {...this.props} />
          <Button domain="TWITTER" {...this.props} />
        </div>
        {this.renderCopy()}
      </div>
    );
  }
}
