import { connect } from 'react-redux';
import { attemptGetTiles } from '_thunks/tiles';
import {
  attemptSearchTileTemplates,
  attemptCreateSuggestion,
} from '_thunks/tileTemplates';
import { attemptGetShelves } from '_thunks/shelves';
import {
  attemptSearchPublicShelves,
  attemptGetPublicShelf,
} from '_thunks/publicShelves';
import { resetPublicShelves } from '_actions/publicShelves';
import { resetTileTemplates } from '_actions/tileTemplates';
import { Container } from './container';

const mapDispatchToProps = dispatch => ({
  getTiles: () => dispatch(attemptGetTiles()),
  searchTileTemplates: data => dispatch(attemptSearchTileTemplates(data)),
  searchPublicShelves: () => dispatch(attemptSearchPublicShelves()),
  resetTileTemplates: () => dispatch(resetTileTemplates()),
  resetPublicShelves: () => dispatch(resetPublicShelves()),
  getPublicShelf: id => dispatch(attemptGetPublicShelf(id)),
  getShelves: () => dispatch(attemptGetShelves()),
  createSuggestion: data => dispatch(attemptCreateSuggestion(data)),
});

const mapStateToProps = (state, props) => {
  return {
    shelfId: state.shelves[0] && state.shelves[0].id,
    publicShelfId: props.match.params.id,
    user: state.user,
  };
};

export const HomePage = connect(mapStateToProps, mapDispatchToProps)(Container);

export default { HomePage };
