import React, { PureComponent } from 'react';
import { SocialSharingBar } from '_components';
import {Close} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";

export class PublishForm extends PureComponent {
  renderSharing = () => (
    <div className="form-content sharing">
      {this.props.loading ? null : <span className="text">Share Your Shelf</span>}
      {this.props.loading ? (
        <span className="text">Publishing Form</span>
      ) : (
        <SocialSharingBar
          key={2}
          id={this.props.id}
          url={this.props.publicUrl}
        />
      )}
      {this.props.loading ? null : (
        <div className="done">
          <div className="button" onClick={this.props.handleClose}>
            Done Sharing
          </div>
          <div className="button-underline" onClick={this.props.unpublishShelf}>
            Unpublish
          </div>
        </div>
      )}
    </div>
  );

  renderPublishing = () => (
    <div className="form-content unpublish">
      {this.props.loading ? null : <span className="text">Successfully unpublished!</span>}
      {this.props.loading ? <span className="text">Unpublishing Form</span> : null}
      {this.props.loading ? null : (
        <div className="done">
          <div className="button" onClick={this.props.handleClose}>
            Done
          </div>
          <div className="button-underline" onClick={this.props.publishShelf}>
            Publish
          </div>
        </div>
      )}
    </div>
  );

  render() {
    return (
      <div className="publish-form">
        <div className="modal-header">
            <div className="modal-header-inner">
                <div className="modal-header-actions">
                    <IconButton aria-label="delete" onClick={this.props.handleClose}>
                        <Close />
                    </IconButton>
                </div>
            </div>
        </div>
          <div className="modal-content">
              <img
                  className="public-shelf-image-preview"
                  src={this.props.image}
                  alt=""
              />
              {this.props.isPublished
                  ? this.renderSharing()
                  : this.renderPublishing()}
          </div>

      </div>
    );
  }
}
