import update from 'immutability-helper';
import * as R from 'ramda';

import { SET_USERS, UPDATE_USER } from '_actions/users';

export function user(state = {}, action) {
  switch (action.type) {
    case UPDATE_USER:
      return update(state, { $merge: action.user });
    default:
      return state;
  }
}

export default function users(state = [], action) {
  const index = R.findIndex(R.propEq('id', action.id), state);
  const updatedAtIndex = {
    $splice: [[index, 1, user(state[index], action)]],
  };

  switch (action.type) {
    case SET_USERS:
      return update(state, { $set: action.users });
    case UPDATE_USER:
      return update(state, updatedAtIndex);
    default:
      return state;
  }
}
