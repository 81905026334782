import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import flow from 'lodash/flow';
import { TileContainer } from './container';

class _Tile extends PureComponent {
  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }
  render() {
    const {
      tile = {},
      isDragging,
      connectDragSource,
      connectDropTarget,
      isShelf,
      isOver,
      canDrop,
      isDeleting,
      listType,
      placeholder,
    } = this.props;

    const tileClassname = 'tile grab';
    let customClassName =
      isDragging || isDeleting || !tile.id ? ' tile-placeholder dragging' : '';
    customClassName =
      isOver && canDrop ? `${customClassName} over` : customClassName;

    return connectDragSource(
      connectDropTarget(
        <div
          ref={c => (this.tile = c)}
          key={tile.id}
          className={`tile${customClassName} ${tileClassname}`}
        >
          <TileContainer
            customClassName={customClassName}
            isOverAndCanDrop={isOver && canDrop}
            isShelf={isShelf}
            item={tile}
            placeholder={placeholder}
            listType={listType}
            isDragging={isDragging}
          />
        </div>,
      ),
    );
  }
}

const cardSource = {
  canDrag: function(props) {
    return !props.placeholder;
  },
  beginDrag(props) {
    return {
      index: props.index,
      listId: props.listId,
      listType: props.listType,
      shelfPos: props.shelfPos,
      bucketPos: props.bucketPos,
      isShelf: props.isShelf,
      item: props.item,
      placeholder: props.placeholder,
      tileSelector: props.tileSelector,
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult() || {};
    const target = dropResult.targetProps;
    if (dropResult.delete) props.removeCard({ source: item, target });
    if (dropResult.moved) {
      if (
        !target.placeholder &&
        target.listType === 'BUCKET' &&
        item.listType === 'SHELF'
      ) {
        // shelf -> bucket, check if tile is the same and position, if so only remove from shelf

        if (
          target.item.id === item.item.id &&
          target.item.position === item.bucketPos
        ) {
          // remove from shelf
          return props.removeCard({
            source: item,
            target,
            shelfOnly: true,
          });
        }
      }
      props.moveCard({ source: item, target });
    }
  },
};

const cardTarget = {
  canDrop(props, monitor) {
    const item = monitor.getItem();

    if (props.placeholder) return true;

    if (props.listType === 'SEARCH') {
      return false;
    }

    if (
      props.listType === item.listType &&
      props.item.position === item.item.position
    ) {
      return false;
    }

    if (item.isSuggestion && !props.placeholder) {
      // only create a new suggestion on a placeholder
      return false;
    }

    if (props.listType === 'SHELF' && item.listType === 'BUCKET') {
      // bucket -> shelf, check if tile is the same and position, if so cancel drop
      return !(
        item.isShelf &&
        props.item.id === item.item.id &&
        props.item.position === item.shelfPos
      );
    }

    return true;
  },
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return {};
    }
    const item = monitor.getItem();

    if (item.isSuggestion && props.placeholder)
      return {
        moved: true,
        placeholder: props.placeholder,
        targetProps: props,
      };
    if (
      (props.listType === 'BUCKET' || props.listType === 'SHELF') &&
      item.listType === 'SEARCH' &&
      item.item &&
      props.tile.tileTemplateId &&
      props.tile.tileTemplateId === item.item
    ) {
      return {};
    }

    return { moved: true, placeholder: props.placeholder, targetProps: props };
  },
};

function selector(state, props) {
  // if (props.placeholder) return {};

  const shelfTilePos =
    (state.shelves && state.shelves[0] && state.shelves[0].tilePositions) || {};
  const bucketTilePos =
    (state.buckets && state.buckets[0] && state.buckets[0].tilePositions) || {};

  let item = props.item || {};
  if (props.listType === 'BUCKET' || props.listType === 'SHELF') {
    item = state.tileViews.move[props.listType][props.position] || item;
  }
  const tile = props.tileSelector && item && props.tileSelector(state, item);
  const shelfPosObj =
    props.listType === 'BUCKET' && tile
      ? Object.values(shelfTilePos).find(x => {
          return tile && x && x.id === tile.id;
        })
      : null;

  let bucketPosObj =
    props.listType === 'SHELF' && tile
      ? Object.values(bucketTilePos).find(x => {
          return tile && x && x.id === tile.id;
        })
      : null;

  return {
    isDeleting:
      props.listType === 'BUCKET' || props.listType === 'SHELF'
        ? state.tileViews.remove[item.id]
        : false,
    tile,
    isShelf: !!shelfPosObj,
    shelfPos: shelfPosObj && shelfPosObj.position,
    bucketPos: bucketPosObj && bucketPosObj.position,
  };
}

export const Tile = flow(
  DropTarget('TILE', cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })),
  DragSource('TILE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  })),
  connect(selector),
)(_Tile);
