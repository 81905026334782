import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropdownContext } from './dropdownContext';
import { omit } from '../utils';

export class DropdownItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    divider: PropTypes.bool,
    header: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    toggle: PropTypes.bool,
  };

  static defaultProps = {
    toggle: true,
  };

  static contextType = DropdownContext;

  onClick = e => {
    if (this.props.disabled || this.props.header || this.props.divider) {
      e.preventDefault();
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }

    if (this.props.toggle) {
      this.context.toggle(e);
    }
  };

  getTabIndex = e => {
    if (this.props.disabled || this.props.header || this.props.divider) {
      return '-1';
    }

    return '0';
  };

  render() {
    const tabIndex = this.getTabIndex();
    const role = tabIndex > -1 ? 'menuitem' : undefined;
    let { className, divider, header, ...props } = omit(this.props, ['toggle']);

    const classes = classNames(className, {
      disabled: props.disabled,
      'dropdown-item': !divider && !header,
      'dropdown-header': header,
      'dropdown-divider': divider,
    });

    return (
      <button
        type="button"
        {...props}
        tabIndex={tabIndex}
        role={role}
        className={classes}
        onClick={this.onClick}
      />
    );
  }
}

export default { DropdownItem };
