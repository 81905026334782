export const SET_COLLECTIONS = 'SET_COLLECTIONS';
export const ADD_COLLECTION = 'ADD_COLLECTION';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION';
export const SET_COLLECTION_DATA = 'SET_COLLECTION_DATA';
export const REMOVE_COLLECTION_TILE_TEMPLATE =
  'REMOVE_COLLECTION_TILE_TEMPLATE';

export function setCollections(collections) {
  return {
    type: SET_COLLECTIONS,
    collections,
  };
}

export const addCollection = collection => ({
  type: ADD_COLLECTION,
  id: collection.id,
  collection,
});

export const removeCollection = id => ({
  type: REMOVE_COLLECTION,
  id,
});

export const updateCollection = collection => ({
  type: UPDATE_COLLECTION,
  id: collection.id,
  collection,
});

export const setCollectionData = ({ id, ...data }) => ({
  type: SET_COLLECTION_DATA,
  id,
  data,
});

export const removeCollectionTileTemplate = (id, tileTemplateId) => ({
  type: REMOVE_COLLECTION_TILE_TEMPLATE,
  id,
  tileTemplateId,
});
