import React, { Component } from 'react';
import { Deck } from '_components';

export class Journeys extends Component {
  render() {
    return (
      <React.Fragment>
        <Deck />
      </React.Fragment>
    );
  }
}

export default { Journeys };
