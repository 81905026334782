import React, { Component } from 'react';
import classnames from 'classnames';
import { FB_APP_ID } from '_utils/env';
import objectToGetParams from '../../utils/objectToGetParams';

export default class extends Component {
  submit = () => {
    const sharingUrl = this.getSharingUrl();
    window.open(sharingUrl, null, 'toolbar=0,status=0,width=548,height=325');
  };

  getSharingUrl = () => {
    switch (this.props.domain) {
      case 'FACEBOOK':
        return (
          'https://www.facebook.com/dialog/share' +
          objectToGetParams({
            app_id: FB_APP_ID,
            display: 'popup',
            href: this.props.url,
          })
        );
      case 'TWITTER':
        return (
          'https://twitter.com/share' +
          objectToGetParams({
            url: this.props.url,
            text: 'My Shelf',
            via: 'Sixtiles',
          })
        );
      default:
        break;
    }
  };

  getIcon = () => {
    switch (this.props.domain) {
      case 'FACEBOOK':
        return 'fa-facebook-f';
      case 'TWITTER':
        return 'fa-twitter';
      default:
        break;
    }
  };

  render() {
    return (
      <div
        className={classnames('button-inner', this.props.domain)}
        onClick={this.submit}
      >
        <i className={`fab ${this.getIcon()}`} />
      </div>
    );
  }
}
