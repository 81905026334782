import { normalizeTilePositions } from '../normalize/tilePositions';

export const UPDATE_TILE_VIEW_REMOVE = 'UPDATE_TILE_VIEW_REMOVE';
export const UPDATE_TILE_VIEW_MOVE = 'UPDATE_TILE_VIEW_MOVE';

export const updateTileViewRemove = ({ id, status }) => ({
  type: UPDATE_TILE_VIEW_REMOVE,
  id,
  status,
});

export const updateTileViewPos = ({
  results = { target: { positions: [] }, source: { positions: [] } },
  status,
}) => {
  const { target, source } = results;
  let targetPos = normalizeTilePositions(target.positions).tilePositions || {};
  const sourcePos =
    normalizeTilePositions(source.positions).tilePositions || {};

  if (source.type === target.type) {
    targetPos = {
      ...sourcePos,
      ...targetPos,
    };
  }
  const res = {
    type: UPDATE_TILE_VIEW_MOVE,
    BUCKET: {},
    SHELF: {},
    [source.type]: sourcePos,
    [target.type]: targetPos,
    status,
  };
  return res;
};
