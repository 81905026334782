export function convertHex(hex) {
  const strippedHex = hex.replace('#', '');
  const red = parseInt(strippedHex.substring(0, 2), 16);
  const green = parseInt(strippedHex.substring(2, 4), 16);
  const blue = parseInt(strippedHex.substring(4, 6), 16);

  const rgb = `${red}, ${green}, ${blue}`;
  return rgb;
}

export default { convertHex };
