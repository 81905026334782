import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ui } from '../../decorators/ui';
import { boundMethod } from 'autobind-decorator';
import { CardDragLayer } from '../dragLayer';
import { DeckBoards } from './deckBoards';
import { Top6Board } from '../boards';
import Pack from './pack';
//import SimpleBar from 'simplebar-react';

@ui()
class _Deck extends Component {
  state = {
    activePack: null,
    currentDeckCards: null,
    snap: 0,
  };

  componentDidMount() {
    const { packTemplates } = this.props.ui;
    if (packTemplates && packTemplates[0]) {
      this.load({ currentTarget: { dataset: { id: packTemplates[0].id } } });
    }
  }

  @boundMethod
  move(card, index) {
    setTimeout(function() {
      card.classList.toggle('loaded');
    }, 100 * index);
  }

  @boundMethod
  fade(card, index) {
    setTimeout(function() {
      card.querySelector('.content').classList.toggle('loaded');
    }, 100 * index);
  }

  @boundMethod
  fadeOut(card, index) {
    setTimeout(function() {
      card.querySelector('.content').classList.toggle('loaded');
    }, 100 * index);
  }

  @boundMethod
  getPack(id) {
    return this.props.ui.packTemplates.filter(pack => pack.id === id)[0];
  }

  @boundMethod
  load(e) {
    // converting nodelist to array for some browsers
    const packId = e.currentTarget.dataset.id;
    const pack = this.getPack(packId);
    const { activePack, currentDeckCards } = this.state;

    if (activePack && activePack.id === packId) return;

    if (currentDeckCards) {
      const cards = [].slice.call(
        document.querySelectorAll(
          `.deck-cards[data-parent="${currentDeckCards}"] .card-container`,
        ),
      );
      cards.forEach(this.move);
      cards.forEach(this.fadeOut);

      setTimeout(() => {
        this.loadNewCards(pack);
      }, 500);
    } else {
      this.loadNewCards(pack);
    }
  }

  @boundMethod
  loadNewCards(pack) {
    this.setState(
      {
        activePack: pack,
      },
      () => {
        setTimeout(() => {
          const cards = [].slice.call(
            document.querySelectorAll(
              `.deck-boards[data-parent="${pack.id}"] .board-container`,
            ),
          );
          cards.forEach(this.move);
          setTimeout(() => {
            cards.forEach(this.fade);
          }, 100 * cards.length);
          this.setState({
            currentDeckCards: pack.id,
          });
        }, 300);
      },
    );
  }

  @boundMethod
  renderDeck({ id, cardTemplates }) {
    const { activeJourney = {} } = this.props.ui;
    if (cardTemplates) {
      return (
        <DeckBoards
          data={cardTemplates}
          parent={id}
          tileId={activeJourney && activeJourney.id}
          tileTemplateId={activeJourney && activeJourney.tileTemplateId}
          bgColor={activeJourney && activeJourney.hexColour}
          nounColor={activeJourney && activeJourney.nounHexColour}
          top6Title={activeJourney && activeJourney.apiUrl}
          isTop6={activeJourney && activeJourney.s3Url}
        />
      );
    }
  }

  @boundMethod
  setHoveredPack(e) {
    const { activePack } = this.state;
    if (activePack && activePack.id === e.currentTarget.dataset.id) {
      return;
    }
    this.setState({
      hoveringPack: e.currentTarget.dataset.id,
    });
  }

  @boundMethod
  unsetHoveredPack(e) {
    const { activePack } = this.state;
    if (activePack && activePack.id === e.currentTarget.dataset.id) {
      return;
    }
    this.setState({
      hoveringPack: null,
    });
  }

  renderTop6 = ({ id, cardTemplates }) => {
    const { dispatch, top6 = {} } = this.props;
    const { activePack } = this.state;
    const { activeJourney = {} } = this.props.ui;
    if (!activeJourney || !activeJourney.s3Url || activeJourney.s3Url !== "true") return null;
    return (
      <Top6Board
        data={top6[activePack && activePack.id] || []}
        numPositions={6}
        packId={activePack.id}
        isTop6
        top6Title={activeJourney && activeJourney.apiUrl}
        cardTemplates={cardTemplates}
        dispatch={dispatch}
        parent={activePack && activePack.id}
        tileId={activeJourney && activeJourney.id}
        tileTemplateId={activeJourney && activeJourney.tileTemplateId}
        bgColor={activeJourney && activeJourney.hexColour}
        nounColor={activeJourney && activeJourney.nounHexColour}
      />
    );
  };

  render() {
    const { packTemplates } = this.props.ui;
    const { activePack } = this.state;
    return (
      <div className="deck-container">
        <CardDragLayer snapToGrid={false} />
        <div className="options-wrapper">
          <div className="options">
            {packTemplates.map(pack =>
              <Pack
                pack={pack}
                key={pack && pack.id}
                activePack={this.state.activePack}
                ui={this.props.ui}
                onClick={this.load}
              />,
            )}
          </div>
          {activePack && this.renderTop6(activePack)}
        </div>
        {activePack && this.renderDeck(activePack)}
      </div>
    );
  }
}
function mapStateTopProps(state, props) {
  return {
    top6: state.top6,
  };
}

export const Deck = connect(mapStateTopProps)(_Deck);

export default { Deck };
