export const uploadImage = async ({ uploadUrl, image }) => {
  return new Promise((resolve, reject) => {
    const buffer = new Buffer(
      image.replace(/^data:image\/\w+;base64,/, ''),
      'base64',
    );

    const xhr = new XMLHttpRequest();

    xhr.open('PUT', uploadUrl, false);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve();
        } else {
          // console.log('in error', xhr);
          reject({ error: true, payload: xhr.statusText });
        }
      }
    };
    xhr.setRequestHeader('Content-Type', 'image/png');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.send(buffer);
  });
};
