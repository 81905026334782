import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTileEditForm } from '_store/actions/tileEdit';
import TileEdit from './edit';
import { boundMethod } from 'autobind-decorator';

class _TileEditModal extends Component {
  constructor(props) {
    super(props);
    this.tileEdit = React.createRef();
  }

  @boundMethod
  onSave() {
    this.tileEdit.current.wrappedInstance.onSave();
  }

  @boundMethod
  handleUndo() {
    const { dispatch, tile } = this.props;
    dispatch(setTileEditForm(tile));
  }

  render() {
    return (
      <TileEdit
        {...this.props}
        ref={this.tileEdit}
        onClose={this.props.onClose}
      />
    );
  }
}

function select(state) {
  return {
    isDirty: state.form.tile.isDirty,
    isSaving: state.form.tile.isSaving,
  };
}

export const TileEditModal = connect(select)(_TileEditModal);

export default { TileEditModal };
