import React, { PureComponent } from 'react';

import {
  TileDragLayer,
  Shelf,
  Bucket,
  PublicShelvesSearch,
  PublicShelf,
  TileTemplatesSearch,
  TileJourneysModal,
} from '_components';
import { ui } from '../../decorators/ui';
import { boundMethod } from 'autobind-decorator';

@ui({
  key: 'Home',
  state: {
    activeJourney: null,
    activeJourneyTemplate: null,
    packTemplates: [],
    activePack: null,
  },
})
class _Container extends PureComponent {
  state = {
    view: 'TEMPLATES',
    hidden: false,
  };

  componentDidMount() {
    this.props.getTiles();
    this.props.searchTileTemplates();
    this.props.searchPublicShelves();
    if (this.props.publicShelfId)
      this.props.getPublicShelf(this.props.publicShelfId);
    this.props.getShelves();
  }

  switchView = e => {
    const { index } = e.currentTarget.dataset;
    if (index === this.state.view) {
      if (index === 'PEOPLE') {
        this.props.searchPublicShelves();
      } else {
        this.props.searchTileTemplates();
      }
      return;
    }
    this.setState({
      hidden: false,
      view: index,
    });
    if (index === 'TEMPLATES') {
      this.props.resetPublicShelves();
      this.props.searchPublicShelves();
    } else {
      this.props.resetTileTemplates();
      this.props.searchTileTemplates();
    }
  };

  activateSearch = data => {
    this.props.searchTileTemplates(data);
    this.setState({
      hidden: false,
      view: 'TEMPLATES',
    });
  };

  renderSearch = () =>
    this.state.view === 'PEOPLE'
      ? <PublicShelvesSearch
          createSuggestion={this.props.createSuggestion}
          view={this.state.view}
          onSwitch={this.switchView}
          onClose={this.onCloseTiles}
          hidden={this.state.hidden}
          activateSearch={this.activateSearch}
        />
      : <TileTemplatesSearch
          createSuggestion={this.props.createSuggestion}
          onSwitch={this.switchView}
          view={this.state.view}
          activateSearch={this.activateSearch}
          onClose={this.onCloseTiles}
          hidden={this.state.hidden}
        />;

  onClose = () => {
    this.props.history.push('/');
  };

  onCloseTiles = () => {
    this.setState({ hidden: true, view: null });
  };

  renderPublicShelf = () =>
    <div className="public-shelf-filter">
      <div className="header search-close" onClick={this.onClose}>
        <i className=" icon fas fa-times" />
        <span>Close</span>
      </div>
      <PublicShelf id={this.props.publicShelfId} />
    </div>;

  @boundMethod
  onCloseJourneysModal() {
    const { resetUI } = this.props;
    resetUI();
  }

  render() {
    const { activeJourney } = this.props.ui;

    return (
      <div className="home-page">
        <div className="home-content">
          <TileDragLayer snapToGrid={false} />
          <div className="lhs-wrapper">
            <Shelf id={this.props.shelfId} />
            <Bucket />
          </div>
          <div className="rhs-wrapper">
            {this.props.publicShelfId ? this.renderPublicShelf() : null}
            {!this.props.publicShelfId ? this.renderSearch() : null}
          </div>
        </div>
        <TileJourneysModal
          open={!!activeJourney}
          title={activeJourney && activeJourney.noun}
          onClose={this.onCloseJourneysModal}
        />
      </div>
    );
  }
}

export const Container = _Container;

export default { Container };
