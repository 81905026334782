import { connect } from 'react-redux';
import {
  attemptGetBuckets,
  attemptMoveBucketTile,
  attemptDeleteBucketTile,
} from '_thunks/buckets';
import { BucketContainer } from './container';

function tileSelector(state, props = {}) {
  const item =
    props.id === 'temp'
      ? { id: 'temp', tileTemplateId: props.templateId }
      : state.tiles[props.id] || {};
  const template = state.tileTemplates.byId[item.tileTemplateId] || {};

  return {
    ...template,
    ...item,
    id: item.id,
  };
}

const mapStateToProps = state => {
  const bucket = (state.buckets && state.buckets[0]) || {};
  const { bucketId, position } = state.suggestion;
  return {
    tileSelector,
    bucket,
    suggestedPos: bucketId === bucket.id && position,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  getBuckets: () => dispatch(attemptGetBuckets()),
  moveBucketTile: data =>
    dispatch(attemptMoveBucketTile({ ...data, tilePositionIds: props.ids })),
  deleteBucketTile: data => dispatch(attemptDeleteBucketTile(data)),
});

export const Bucket = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BucketContainer);

export default { Bucket };
