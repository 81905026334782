import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class DelayedRender extends Component {
  static propTypes = {
    delay: PropTypes.number.isRequired,
    children: PropTypes.element,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      render: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const delayedClassNames = this.refs.noDelayed.className;
      this.setState({
        render: true,
        classNames: delayedClassNames,
      });
    }, this.props.delay);
  }

  render() {
    const { children, className } = this.props;
    return this.state.render ? (
      <div className={this.state.classNames}>{children}</div>
    ) : (
      <div className={className} ref="noDelayed" />
    );
  }
}

export default { DelayedRender };
