import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Reference } from 'react-popper';
import { DropdownContext } from './dropdownContext';

export class DropdownToggle extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    'aria-haspopup': PropTypes.bool,
  };

  static defaultProps = {
    'aria-haspopup': true,
  };

  static contextType = DropdownContext;

  onClick = e => {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }

    this.context.toggle(e);
  };

  render() {
    const { className, ...props } = this.props;
    const classes = classNames(className, 'dropdown-toggle', 'icon-button');
    const children = props.children || null;

    return (
      <Reference>
        {({ ref }) => (
          <div
            {...props}
            ref={ref}
            className={classes}
            onClick={this.onClick}
            aria-expanded={this.context.isOpen}
            children={children}
          >
            <span className="caret" />
          </div>
        )}
      </Reference>
    );
  }
}

export default { DropdownToggle };
