const shelf = {
  small: {
    top: [24, 64, 2, 45, 87],
    left: [0, -28.28, -25.28, -29.28, -29.28],
  },
  large: {
    top: [24, 71, 0, 48, 95],
    left: [0, -32, -28, -32, -32],
  },
};
const bucket = {
  small: {
    top: [10, 48.9, 88, 23, 63],
    left: [0, -28.28, -27.28, -27.28, -27.28],
  },
  large: {
    top: [24, 68, 112, 39, 84],
    left: [0, -31, -31, -31, -32],
  },
};
const search = {
  small: {
    top: [10, 48.9, 88, 23, 63],
    left: [0, -28.28, -27.28, -27.28, -27.28],
  },
  large: {
    top: [24, 68, 112, 39, 84],
    left: [0, -31, -31, -31, -32],
  },
};

export const tilePositions = {
  SHELF: shelf,
  BUCKET: bucket,
  SEARCH: search,
};
export default { tilePositions };
