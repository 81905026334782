import React from 'react';
import classnames from 'classnames';
import PriorityPositionTop6 from './priorityPositionTop6';
import BoardTop6Item from './boardTop6Item';
import { ui } from '../../decorators/ui';

@ui()
class Top6Board extends React.Component {
  renderPriority = i => {
    const {
      packId,
      sequence,
      dispatch,
      tileId,
      tileTemplateId,
      cardTemplates,
      data,
    } = this.props;
    const x = 0;
    const y = i;

    const cardId = data[y];
    return (
      <PriorityPositionTop6
        key={i}
        x={x}
        y={y}
        packId={packId}
        sequence={sequence}
        boardId={cardId}
        dispatch={dispatch}
        tileId={tileId}
        tileTemplateId={tileTemplateId}
        cardTemplate={cardTemplates.find(x => x.id === cardId)}
      >
        {this.renderTop6Item(y)}
      </PriorityPositionTop6>
    );
  };

  renderTop6Item = y => {
    const {
      data,
      id,
      packId,
      sequence,
      dispatch,
      bgColor,
      nounColor,
      tileId,
      tileTemplateId,
      updateUI,
    } = this.props;
    const cardId = data[y];
    return cardId
      ? <BoardTop6Item
          id={cardId}
          sequence={sequence}
          priorityPosition={y}
          bgColor={bgColor}
          nounColor={nounColor}
          dispatch={dispatch}
          tileId={tileId}
          boardId={id}
          packId={packId}
          tileTemplateId={tileTemplateId}
          updateUI={updateUI}
        />
      : null;
  };

  render() {
    const { numPositions, id, isLast, isTop6, top6Title } = this.props;

    const priorities = [];
    for (let i = 1; i < numPositions + 1; i += 1) {
      priorities.push(this.renderPriority(i));
    }
    const shouldRenderTitle = top6Title === 'https://www.google.com/api' ? false : top6Title;
    return (
      <div
        key={id}
        className={classnames('board content', { 'top-6': isTop6 })}
        style={
          isLast
            ? {
                width: '242px',
                minWidth: '242px',
                paddingRight: '22px',
                transition: 'none',
              }
            : null
        }
      >
        <div className="items">
          {priorities}
        </div>
        <h2 className="top-6-title">{shouldRenderTitle || 'Top 6'}</h2>
      </div>
    );
  }
}
export default Top6Board;
