// import * as ActionTypes from '../actions';
import {
  TILE_TEMPLATES_REQUEST,
  TILE_TEMPLATES_SUCCESS,
  TILE_TEMPLATES_FAILURE,
  TILE_TEMPLATES_RESET,
} from '../actions/tileTemplates';
import {
  PUBLIC_SHELVES_REQUEST,
  PUBLIC_SHELVES_SUCCESS,
  PUBLIC_SHELVES_FAILURE,
  PUBLIC_SHELVES_RESET,
} from '../actions/publicShelves';
import paginate from './paginate';
import { combineReducers } from 'redux';

const pagination = combineReducers({
  tileTemplates: paginate({
    mapActionToKey: action => action.value,
    types: [
      TILE_TEMPLATES_REQUEST,
      TILE_TEMPLATES_SUCCESS,
      TILE_TEMPLATES_FAILURE,
      TILE_TEMPLATES_RESET,
    ],
  }),
  publicShelves: paginate({
    mapActionToKey: action => action.value,
    types: [
      PUBLIC_SHELVES_REQUEST,
      PUBLIC_SHELVES_SUCCESS,
      PUBLIC_SHELVES_FAILURE,
      PUBLIC_SHELVES_RESET,
    ],
  }),
});

export default pagination;
