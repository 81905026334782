import { request } from '_utils/api';

export const getTiles = () => request('get', '/tile?expand=tiletemplate');
export const getTile = id => request('get', `/tile/${id}`);

export const getTilesById = ids => request('post', '/tile', { body: ids });

export const createTile = data => {
  return request('post', '/tile', { body: data });
};

export const updateTile = selected =>
  request('put', `/tile/${selected.id}`, { body: selected });

export const deleteTile = ({ id }) => request('del', `/tile/${id}`);
