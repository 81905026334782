import React, { Component } from 'react';
import classNames from 'classnames';
import { convertHex } from '../utils';
import { connect } from 'react-redux';
import MouseOverText from './mouseOverText';
import { TileEditModal } from './editModal';
import { Menu } from '..';
import { attemptGetCategoryImages } from '../../store/thunks/categoryImages';
import {
  clearTileEditForm,
  // setTileEditForm,
} from '../../store/actions/tileEdit';
import { resetEditImage, setEditImage } from 'shared-code';
import { boundMethod } from 'autobind-decorator';
import { ui } from '../../decorators/ui';
import insetImage from '../../assets/images/inset.png';
import overlayImage from '../../assets/images/overlay.png';

@ui()
class _TileContainer extends Component {
  state = {
    showJourneysModal: false,
    showEditModal: false,
    isMouseOver: false,
  };

  renderShelfPos = style => {
    return (
      <div className="marker shelf-pos-marker" style={style}>
        <div className="inner">
          <span>6</span>
        </div>
      </div>
    );
  };

  renderExpandIcon = style => {
    return (
      <div className="marker expand-marker" style={style}>
        <div className="inner">
          <span>+</span>
        </div>
      </div>
    );
  };

  openEditModal = () => {
    if (this.state.showEditModal) return;
    const { listType, placeholder, dispatch, item } = this.props;
    if (listType === 'SEARCH' || placeholder) return;
    this.setState({
      showEditModal: true,
    });
    const { categoryId } = item;
    dispatch(attemptGetCategoryImages(categoryId));
    dispatch(clearTileEditForm(item));
    dispatch(setEditImage('tile', item));
  };

  openJourneysModal = () => {
    const { listType, placeholder, tileTemplate, updateUI, item } = this.props;
    if (
      listType === 'SEARCH' ||
      placeholder ||
      !tileTemplate ||
      !tileTemplate.packTemplates
    )
      return;
    updateUI({
      activeJourney: item,
      activeJourneyTemplate: tileTemplate,
      packTemplates: tileTemplate.packTemplates,
    });
  };

  onCloseEditModal = () => {
    this.props.dispatch(resetEditImage('tile'));
    this.setState({
      showEditModal: false,
    });
  };

  @boundMethod
  handleMenuClick(option) {
    switch (option) {
      case 'JOURNEYS':
        this.openJourneysModal();
        break;
      case 'EDIT':
        this.openEditModal();
        break;
      default:
        break;
    }
  }

  @boundMethod
  handleMouseEnter() {
    this.setState({
      isMouseOver: true,
    });
  }

  @boundMethod
  handleMouseLeave() {
    this.setState({
      isMouseOver: false,
    });
  }

  render() {
    const { item, isDragging, isShelf, listType, tileTemplate } = this.props;

    const style = {
      '--hex-texture-wrap': item.hexTextureWrap,
    };

    if (item.hexColour) {
      style['--hex-color'] = item.hexColour;
      style['--rgb-color'] = convertHex(item.hexColour);
    }

    const nounStyle = {};
    const textStyle = {};

    if (item.nounHexColour) {
      nounStyle['--noun-hex-color'] = item.nounHexColour;
    }

    if (item.textHexColour) {
      textStyle['--text-hex-color'] = item.textHexColour;
    }

    const { isMouseOver } = this.state;
    const { placeholder, isOverAndCanDrop } = this.props;

    const menuOptions = [];

    if (
      listType !== 'SEARCH' &&
      !placeholder &&
      !!tileTemplate &&
      tileTemplate.packTemplates
    ) {
      menuOptions.push({
        id: 0,
        key: 'JOURNEYS',
        icon: 'fa-clone',
        className: 'journeys',
      });
    }

    menuOptions.push({
      id: 1,
      key: 'EDIT',
      icon: 'fa-pencil-alt',
      className: 'edit',
    });

    return (
      <React.Fragment>
        {this.state.showEditModal
          ? <TileEditModal
              tile={this.props.item}
              open={this.state.showEditModal}
              onClose={this.onCloseEditModal}
            />
          : null}
        <div
          className={'tile-container'}
          style={style}
          onDoubleClick={
            !!tileTemplate && !!tileTemplate.packTemplates
              ? this.openJourneysModal
              : null
          }
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {listType !== 'SEARCH' && isMouseOver && !placeholder
            ? <Menu onClickMenu={this.handleMenuClick} options={menuOptions} />
            : null}

          {/* {isShelf ? this.renderExpandIcon(style) : null} */}
          {isShelf ? this.renderShelfPos(style) : null}
          <div className="tile-inner">
            {!item.id &&
              <div
                className="tile-image-inset"
                style={{ backgroundImage: `url(${insetImage})` }}
              />}
            {
              <div
                className={classNames('tile-image-overlay', {
                  active: isOverAndCanDrop,
                })}
                style={{ backgroundImage: `url(${overlayImage})` }}
              />
            }
            <div className="tile-image-container">
              {(item.imgurlCustom || item.imgurlDefault || item.imgurl) &&
                <img
                  className="tile-image"
                  alt=""
                  src={item.imgurlCustom || item.imgurlDefault || item.imgurl}
                />}
            </div>
            {item.noun && !isDragging
              ? <MouseOverText
                  id={item.id}
                  noun={item.noun}
                  text={item.text}
                  style={style}
                  textStyle={textStyle}
                  nounStyle={nounStyle}
                  dragging={isDragging}
                />
              : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { item } = props;
  const { tileTemplates } = state;

  if (!item || !item.id || !tileTemplates) return {};
  return {
    tileTemplate: tileTemplates.byId[item.tileTemplateId] || {},
  };
};

export const TileContainer = connect(mapStateToProps)(_TileContainer);

export default { TileContainer };
