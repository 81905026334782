import React, { Component } from 'react';
import { connect } from 'react-redux';
import Board from './board';
import { ui } from '../../decorators/ui';
@ui()
class _BoardContainer extends Component {
  render() {
    const {
      board,
      numPositions,
      dispatch,
      sequence,
      packId,
      data: { card },
      bgColor,
      nounColor,
      tileId,
      tileTemplateId,
      isLast,
    } = this.props;
    if (!board) return null;

    return (
      <div
        ref={c => {
          this.board = c;
        }}
        className="board-container not-dragging"
        style={
          isLast
            ? {
                width: 'auto',
                minWidth: 'auto',
              }
            : null
        }
      >
        <Board
          id={card && card.cardTemplateId}
          packId={packId}
          sequence={sequence}
          card={card}
          cardX={board.cardPosition[0]}
          cardY={board.cardPosition[1]}
          numPositions={numPositions}
          dispatch={dispatch}
          bgColor={bgColor}
          nounColor={nounColor}
          tileId={tileId}
          tileTemplateId={tileTemplateId}
          isLast={isLast}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const packBoards = state.boards[props.packId] || [];
  const board = packBoards[props.sequence];

  return {
    board,
  };
};

export const BoardContainer = connect(mapStateToProps)(_BoardContainer);

export default { BoardContainer };
