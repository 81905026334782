import React, { Component } from 'react';
import classNames from 'classnames';
import withScrolling from '../scrollZone';
import { TileGrid, DisplayTile } from '../../components';

const ScrollingComponent = withScrolling('div');

export default class extends Component {
  onLeaveChange = () => {
    if (this.scrollLock) {
      clearTimeout(this.scrollLock);
      this.scrollLock = null;
    }
  };

  onEnterChange = () => {
    if (this.scrollLock) return;
    if (!this.scrollLock) {
      this.scrollLock = setTimeout(this.onScroll, 1000);
    }
  };

  onScroll = () => {
    const { id, onScroll } = this.props;
    onScroll(id);
    this.scrollLock = null;
    this.onEnterChange();
  };

  renderItem = (item, index) => {
    return (
      <DisplayTile
        key={item}
        item={item}
        index={index}
        moveCard={this.props.moveShelfTile}
        removeCard={this.props.deleteShelfTile}
        listType={this.listType}
        tileSelector={this.props.tileSelector}
        placeholder={true}
      />
    );
  };

  renderRows = c => {
    const renderedItems = [];
    for (let r = 0; r < 1; r++) {
      renderedItems.push(this.renderItem(r));
    }

    if (c === 2 && this.props.id === 'UP') {
      renderedItems.push(this.renderItem(1));
    }

    return renderedItems;
  };

  render() {
    const { id } = this.props;

    return (
      <React.Fragment>
        <div
          key={0}
          className={classNames('bucket-scroll-area-tiles', id)}
          style={{ position: 'absolute' }}
        >
          <TileGrid
            containerClass={classNames(
              'bucket-scroll-area-tiles-container',
              id,
            )}
            listType={'SHELF'}
            renderRows={this.renderRows}
            noRows={1}
            noCols={5}
          />
        </div>
        <ScrollingComponent
          key={1}
          className={classNames('bucket-scroll-area ', id)}
          onEnter={this.onEnterChange}
          onLeave={this.onLeaveChange}
        />
      </React.Fragment>
    );
  }
}
