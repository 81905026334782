import { connect } from 'react-redux';
import { attemptCloneTile } from '_thunks/tiles';
import { ShelfContainer } from './container';

function tileSelector(state, props = {}) {
  return {
    ...props,
  };
}

const mapStateToProps = (state, props) => {
  const id = props.id;
  return {
    tileSelector,
    shelf: state.publicShelves.byId[id] || {},
    public: true,
    unauthenticated: !state.user,
    type: 'SEARCH',
  };
};

const mapDispatchToProps = dispatch => ({
  moveShelfTile: data => dispatch(attemptCloneTile(data)),
});

export const PublicShelf = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShelfContainer);

export default { PublicShelf };
