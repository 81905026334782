import update from 'immutability-helper';
import { LOAD_TOP6, MOVE_TOP6_ITEM,REMOVE_TOP6_ITEM } from '../actions/cardBoard/top6';

export default function top6(state = {}, action) {
  switch (action.type) {
    case LOAD_TOP6:
      const { packId, top6, top6PositionIds } = action.payload;
      const items = {};
      top6PositionIds.forEach(x => {
        items[x] = top6[x].cardTemplateId;
      });

      return update(state, {
        [packId]: { $set: items },
      });
    case MOVE_TOP6_ITEM: {
      const {
        currentPosition,
        newPosition,
        packId,
        incomingId,
      } = action.payload;
      const newState = state[packId];

      return update(state, {
        [packId]: {
          [newPosition]: {
            $set: incomingId,
          },
          [currentPosition]: {
            $set: newState[newPosition],
          },
        },
      });
    }
    case REMOVE_TOP6_ITEM: {
      const {
        currentPosition,
        packId,
      } = action.payload;

      return update(state, {
        [packId]: {
          [currentPosition]: {
            $set: undefined,
          },
        },
      });
    }
    default:
      return state;
  }
}
