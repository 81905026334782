import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function EmptyMessage({ message, classes }) {
  return (
    <div className={classNames('empty-message', classes)}>
      <h3>{message}</h3>
    </div>
  );
}

EmptyMessage.propTypes = {
  message: PropTypes.string.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

EmptyMessage.defaultProps = {
  classes: null,
};

export default { EmptyMessage };
