import React, { Component } from 'react';
import { Tile, TileGrid } from '_components';
import { DisplayTile } from '../tile';
import { PublishButton } from './publishButton';
import { ShelfUser } from './user';
import classNames from 'classnames';

export class ShelfContainer extends Component {
  constructor(props) {
    super(props);

    this.listType = props.type;
    this.noRows = [1, 1, 2, 1, 1];

    this.offset = [0, 1, 2, 4, 5];
  }

  getTileComponent = item => {
    if (this.props.unauthenticated) {
      return DisplayTile;
    }

    return Tile;
  };

  renderItem = (item, index) => {
    const TileComponent = this.getTileComponent(item);
    const position = (item && item.position) || index + 1;

    return (
      <TileComponent
        key={position}
        item={item}
        index={index}
        moveCard={this.props.moveShelfTile}
        removeCard={this.props.deleteShelfTile}
        listType={this.listType}
        listId={this.props.shelf.id}
        tileSelector={this.props.tileSelector}
        placeholder={!item}
        isPublic={this.props.public}
        position={position}
      />
    );
  };

  shouldRenderItem = index => {
    const tilePositions = this.props.shelf.tilePositions || {};
    return this.renderItem(tilePositions[index + 1], index);
  };

  renderRows = c => {
    const noRows = this.noRows[c];
    const offset = this.offset[c];
    const renderedItems = [];
    for (let r = 0; r < noRows; r++) {
      renderedItems.push(this.shouldRenderItem(offset + r));
    }

    return renderedItems;
  };

  renderUser = () => <ShelfUser key={3} shelf={this.props.shelf} />;

  render() {
    const containerClass = 'shelf';
    const noCols = 5;
    this.shelf = React.createRef();

    return (
      <div
        className={classNames('shelf-container', { public: this.props.public })}
      >
        {this.props.public ? this.renderUser() : null}
        <div className="shelf-grid-wrapper">
          <TileGrid
            key={0}
            containerClass={containerClass}
            listType={'SHELF'}
            renderRows={this.renderRows}
            removeCard={this.removeCard}
            noCols={noCols}
            ref={this.shelf}
          />
        </div>
        {this.props.public
          ? null
          : [
              <PublishButton
                key={1}
                id={this.props.shelf.id}
                isPublished={this.props.shelf.publishFlag}
                shelfRef={this.shelf}
              />,
            ]}
      </div>
    );
  }
}

export default { ShelfContainer };
