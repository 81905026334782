import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import { PublishForm } from './form';
import { domtoimage } from '_utils/dom-to-image';
import {IconInverseButton as IconButton} from 'shared-code';

const styles = {
  backdrop: {
    backgroundColor: 'rgba(227, 237, 247, 0.5) !important',
  },
  paper: {
    borderRadius: '40px !important',
    backgroundColor: 'rgb(227, 237, 247) !important',
  },

  menuPaper: {
    borderRadius: '20px !important',
    backgroundColor: 'rgb(227, 237, 247) !important',
  }
};

class _PublishButtonContainer extends Component {
  state = {
    anchorEl: null,
    loading: false,
    publicUrl: null,
    image: null,
    showSimpleMenu: false,
    showMenu: false,
  };

  componentDidMount() {
    this.timer = null;
  }

  handlePng = () => {
    const node = this.props.shelfRef.current;
    const id = this.props.id;
    domtoimage
      .toPng(node, {
        bgcolor: 'rgba(255,255,255,0)',
        canvasWidth: 1200,
        canvasHeight: 700,
        style: {
          marginRight: 0,
        },
      })
      .then(dataUrl => {
        this.setState({
          image: dataUrl,
          loading: true,
          anchorEl: this.popover,
          showMenu: false,
          showMainMenu: false,
        });
        return this.props
          .publishShelfWithImage({ id, image: dataUrl })
          .then(data =>
            this.setState({
              loading: false,
              publicUrl:
                data && data.version
                  ? `https://sharing.sixtilesbeta.com/${this.props
                      .id}/${data.version}`
                  : null,
            }),
          );
      })
      .catch(error => {
        console.error('oops, something went wrong!', error);
        this.props.handleError('Unable to publish shelf.');
      });
  };

  unpublishShelf = () => {
    this.props.unpublishShelf();
  };

  publishShelf = () => {
    this.props.publishShelf();
  };

  togglePopout = () => {
    if (!this.state.anchorEl) {
      this.handlePng();
    } else {
      this.setState({
        anchorEl: null,
        loading: false,
        publicUrl: null,
        image: null,
      });
    }
  };

  toggleMainMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  showSimpleMenu = () => {
    this.setState({
      showSimpleMenu: true,
    });
  };

  hideSimpleMenu = () => {
    this.setState({
      showSimpleMenu: false,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = () => {
    if (this.timer !== null) {
      this.handleDblClick();
    } else {
      this.timer = setTimeout(() => {
        this.handleSingleClick();
      }, 250);
    }
  };

  handleSingleClick = () => {
    this.toggleMainMenu();
    clearTimeout(this.timer);
    this.timer = null;
  }

  handleDblClick = () => {
    this.togglePopout();
    clearTimeout(this.timer);
    this.timer = null;
  };

  renderSharingPopover = (classes) => (
    <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={this.state.anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
        classes={{ paper: classes.paper, root: classes.backdrop }}
    >
      <PublishForm
          id={this.props.id}
          image={this.state.image}
          loading={this.state.loading}
          publicUrl={this.state.publicUrl}
          handleClose={this.handleClose}
          isPublished={this.props.isPublished}
          publishShelf={this.publishShelf}
          unpublishShelf={this.unpublishShelf}
      />
    </Popover>
  );

  renderMainMenu = (classes) => (
      <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={this.popover}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(this.state.showMenu)}
          onClose={this.toggleMainMenu}
          classes={{ paper: classes.menuPaper, root: classes.backdrop }}
      >
        <ul className="user-menu">
          <li onClick={this.togglePopout}>Publish/Unpublish</li>
          <li>Settings</li>
          <li className="divider" />
          <li onClick={this.props.logout}>Logout</li>
        </ul>
      </Popover>
  );

  render() {
    const {classes} = this.props;
    return (
      <div
        variant="contained"
        aria-owns={'custom-tile'}
        aria-haspopup="true"
        ref={c => (this.popover = c)}
        onClose={this.handleClose}
        className={classNames('publish-shelf', {
          loading: this.state.loading,
        })}
      >
        {this.state.loading && !Boolean(this.state.anchorEl)
          ? <span className="publish-loader">
              <CircularProgress />
            </span>
          : this.props.id &&
            <IconButton
              id="published"
              onClick={this.handleClick}
              // onMouseEnter={this.showSimpleMenu}
              // onMouseLeave={this.hideSimpleMenu}
              label={<i className="fas fa-user" />}
              title={'User Menu'}
              isDomElement
            />}
        {this.renderSharingPopover(classes)}
        {this.renderMainMenu(classes)}
      </div>
    );
  }
}

export const PublishButtonContainer = withStyles(styles)(_PublishButtonContainer);

export default PublishButtonContainer;
