export const LOAD_BOARDS = 'LOAD_BOARDS';
export const MOVE_CARD = 'MOVE_CARD';

export const loadBoards = (packId, cards) => (dispatch, getState) => {
  const cardIds = getState().cards.byId;
  const parsedCards = cards
    .map(x => ({ ...x, ...(cardIds[x.id] || {}) }))
    .sort((a, b) => a.sequence - b.sequence);

  return dispatch({
    type: LOAD_BOARDS,
    payload: {
      packId,
      cards: parsedCards,
    },
  });
};

export function moveCard(packId, oldSequence, newSequence, newCardPosition) {
  return {
    type: MOVE_CARD,
    payload: {
      packId,
      oldSequence,
      newSequence,
      newCardPosition,
    },
  };
}
