import Auth from '@aws-amplify/auth';
import { setCurrentUser } from '_actions/user';

export const attemptSetCurrentUser = () => dispatch =>
  Auth.currentUserInfo()
    .then(data => {
      dispatch(setCurrentUser(data));
      return data;
    })
    .catch
    // do nothing
    ();

export const attemptLogout = () => () =>
  Auth.signOut()
    .then(data => {
      return data;
    })
    .catch
    // do nothing
    ();
