import { getTiles, createTile, deleteTile, updateTile } from '_api/tiles';
import {
  setTiles,
  addTile,
  removeTile,
  updateTile as updateTileAction,
} from '_actions/tiles';
import { to, attemptUpdateEntityImages, uploadTileImage } from 'shared-code';
import imgurlUtils from '_utils/imgurl';
import { dispatchError } from '_utils/api';
import { attemptMoveTileTemplate } from './tileTemplates';
import { updateTileViewRemove } from '../actions/tileViews';
import { updateEditImageError } from 'shared-code/lib/store/actions';

export const attemptGetTiles = () => dispatch =>
  getTiles()
    .then(data => {
      dispatch(setTiles(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptCreateTile = item => dispatch =>
  createTile(item)
    .then(data => {
      dispatch(addTile(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteTile = item => (dispatch, getState) => {
  const tile = getState().tiles[item.id];

  const packTemplateIds = tile.packs;
  dispatch(updateTileViewRemove({ id: item.id, status: true }));

  return deleteTile(item)
    .then(data => {
      dispatch(removeTile(item.id, packTemplateIds));
      return data;
    })
    .catch(dispatchError(dispatch))
    .finally(() =>
      dispatch(updateTileViewRemove({ d: item.id, status: false })),
    );
};

export const attemptCloneTile = ({ source, ...rest }) => dispatch => {
  const { item: { hexColour, nounHexColour, textHexColour } } = source;
  const customDetails = {
    hexColour,
    nounHexColour,
    textHexColour,
  };

  return dispatch(
    attemptMoveTileTemplate(
      {
        source: {
          item: {
            ...source.item,
            id: null,
          },
        },
        customDetails,
        ...rest,
      },
      true,
    ),
  );
};

export const attemptEditTile = item => dispatch =>
  updateTile(item)
    .then(data => {
      dispatch(updateTileAction(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateTile = () => async (dispatch, getState) => {
  let imgResponse = null;
  let err = null;
  const state = getState();
  const { data } = state.form.tile;
  const { imageHasChanged, croppedImage } = state.editImage.tile;

  if (imageHasChanged) {
    [err, imgResponse] = await to(
      dispatch(
        attemptUpdateEntityImages({
          croppedImage,
          entity: data,
          entityType: 'tile',
          newImage: {
            ...state.editImage.tile,
            copyrightChecked:
              state.editImage.tile.isImgSelected ||
              !!state.editImage.tile.copyrightChecked ||
              (data.imgurlReference &&
                data.imgurlReference.includes('category') &&
                !state.editImage.tile.isImgUploaded),
          },
          imageHasChanged,
          id: data.id,
          categoryId: data.categoryId,
          updateEntityImage: uploadTileImage,
        }),
      ),
    );
    if (err) {
      dispatch(
        updateEditImageError(
          'tile',
          (err && err.message) || 'Oops something went wrong',
        ),
      );

      throw err;
    }
  }

  const newImagePaths = imgurlUtils.parseNewImagePaths({
    type: 'tile',
    editImage: state.editImage.tile,
    imgResponse,
    entity: data,
  });

  const updatedData = {
    ...data,
    ...newImagePaths,
  };
  return dispatch(attemptEditTile(updatedData));
};
