import { getCategoryImages, createCategoryImage } from '_api/categoryImages';
import { setCategoryImages, addCategoryImage } from '_actions/categoryImages';

import { dispatchError } from '_utils/api';

export const attemptGetCategoryImages = id => (dispatch, getState) => {
  const category = getState().categories.find(x => x.id === id);
  if (category && category.images) return;
  return getCategoryImages(id)
    .then(data => {
      dispatch(setCategoryImages({ id, images: data }));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptCreateCategoryImage = item => dispatch =>
  createCategoryImage(item)
    .then(data => {
      dispatch(addCategoryImage(data));
      return data;
    })
    .catch(dispatchError(dispatch));
