export const REMOVE_PACK = 'REMOVE_PACK';
export const ADD_PACK = 'ADD_PACK';
export const SET_PACK = 'SET_PACK';
export const SET_PACKS = 'SET_PACKS';
export const UPDATE_PACK = 'UPDATE_PACK';

export const addPack = (tileId, pack) => ({
  type: ADD_PACK,
  id: pack.packTemplateId,
  tileId,
  pack,
});

export const removePack = (tileId, pack) => ({
  type: REMOVE_PACK,
  id: pack.packTemplateId,
  tileId,
});
