import { MOUNT_UI_STATE } from './consts';

export function mountUI(key, defaults) {
  return {
    type: MOUNT_UI_STATE,
    payload: {
      key,
      defaults,
    },
  };
}

export default { mountUI };
