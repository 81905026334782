import { safelyParseJSON } from '_utils/data';

export const SET_CATEGORY_IMAGES = 'SET_CATEGORY_IMAGES';
export const ADD_CATEGORY_IMAGE = 'ADD_CATEGORY_IMAGE';
export const REMOVE_CATEGORY_IMAGE = 'REMOVE_CATEGORY_IMAGE';

export function setCategoryImages({ id, images }) {
  const parsedimages = images.map(image => ({
    ...image,
    cropImgInfo: image.cropImgInfo && safelyParseJSON(image.cropImgInfo),
  }));
  return {
    type: SET_CATEGORY_IMAGES,
    id,
    images: parsedimages,
  };
}

export const addCategoryImage = data => ({
  type: ADD_CATEGORY_IMAGE,
  id: data.categoryId,
  image: data,
});

export const removeCategoryImage = ({ id, imageId }) => ({
  type: REMOVE_CATEGORY_IMAGE,
  id,
  imageId,
});
