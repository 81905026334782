import React from 'react';
import PropTypes from 'prop-types';

export const Checkbox = ({ id, ...props }) => {
  return (
    <React.Fragment>
      <input id={id} data-id={id} type="checkbox" {...props} />
      <label htmlFor={id} />
    </React.Fragment>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: null,
  checked: false,
};
export default { Checkbox };
