import {
  getTileTemplates,
  searchTileTemplates,
  updateTileTemplate as editTileTemplate,
  deleteTileTemplate,
  createTileTemplate,
} from '_api/tileTemplates';
import {
  setTileTemplates,
  removeTileTemplate,
  updateTileTemplate,
  addTileTemplate,
  fetchTileTemplateStatus,
  updateSearchTerm,
} from '_actions/tileTemplates';
import { updateTileViewPos } from '_actions/tileViews';
import { attemptCreateBucketTile } from '_thunks/buckets';
import { getNewTilePositions, updateTilePositions } from '_thunks/utils';
import { attemptCreateTile } from '_thunks/tiles';
import { dispatchError, handleError } from '_utils/api';
import { getNextAvailblePosition } from '../../utils/tileMovement';
import { loadPage } from './pagination';

export const attemptGetTileTemplates = () => dispatch =>
  getTileTemplates()
    .then(data => {
      dispatch(setTileTemplates(data));
      return data;
    })
    .catch(dispatchError(dispatch));

const getIds = (target, getState) => {
  if (target.placeholder) return [];
  const state = getState();
  switch (target.listType) {
    case 'BUCKET':
      return state.buckets.find(x => x.id === target.listId).tilePositionIds;
    case 'SHELF':
      return state.shelves.find(x => x.id === target.listId).tilePositionIds;
    default:
      return [];
  }
};

export const attemptMoveTileTemplate = (
  { source, target, customDetails },
  isPublic,
) => async (dispatch, getState) => {
  const ids = getIds(target, getState);
  const tiles = getState().tiles;
  const tilePositionIds = getState().buckets[0].tilePositionIds;
  const tilePositions = getState().buckets[0].tilePositions;

  const sourceTileTemplateId = isPublic
    ? source.item.tileTemplateId
    : source.item;

  let tile = Object.values(tiles).find(
    x => x.tileTemplateId === sourceTileTemplateId,
  );

  if (tile && target.listType === 'BUCKET') {
    // get current position in bucket
    const tilePos = tilePositionIds
      .map(pos => tilePositions[pos])
      .find(x => x.id === tile.id);

    if (tilePos) {
      source.position = tilePos.position;
      source.listType = 'BUCKET';
    }

    source.id = tile.id;
  }

  if (tile && target.listType === 'SHELF') {
    // TODO: check if tile is already in shelf so there can be a swap

    // move existing tile from bucket to shelf, if already there, move that one
    const resultShelf = getNewTilePositions(source, target, ids);

    resultShelf.target.positions = resultShelf.target.positions.map(x => {
      return {
        position: x.position,
        id: x.id || tile.id,
      };
    });
    dispatch(
      updateTileViewPos({
        results: resultShelf,
        status: true,
      }),
    );

    await dispatch(updateTilePositions(resultShelf));
    return dispatch(
      updateTileViewPos({
        status: false,
      }),
    );
  }

  const tileId = (tile && tile.id) || 'temp';
  source.id = tileId;
  const results = getNewTilePositions(source, target, ids);

  results.target.positions = results.target.positions.map(x => {
    return {
      position: x.position,
      id: x.id || tileId,
      templateId: tileId === 'temp' ? sourceTileTemplateId : null,
    };
  });

  if (target.listType === 'SHELF') {
    // create bucket pos
    const bucketIds = getState().buckets[0].tilePositionIds;
    const nextPos = getNextAvailblePosition(bucketIds, 1, 'BUCKET');
    results.source = {
      type: 'BUCKET',
      id: getState().buckets[0].id,
      positions: [
        {
          position: nextPos,
          id: tileId,
          templateId: tileId === 'temp' ? sourceTileTemplateId : null,
        },
      ],
    };
  }
  dispatch(
    updateTileViewPos({
      results,
      status: true,
    }),
  );

  if (!tile) {
    tile = await dispatch(
      // create bucket pos
      attemptCreateTile({
        tileTemplateId: sourceTileTemplateId,
        ...(customDetails || {}),
        bucketId: target.listId,
      }),
    );

    let foundIndex = results.target.positions.findIndex(v => v.id === 'temp');
    if (foundIndex > -1)
      results.target.positions[foundIndex] = {
        id: tile.id,
        position: results.target.positions[foundIndex].position,
      };

    foundIndex = results.source.positions.findIndex(v => v.id === 'temp');
    if (foundIndex > -1)
      results.source.positions[foundIndex] = {
        id: tile.id,
        position: results.source.positions[foundIndex].position,
      };

    if (!tile) {
      console.log('error: failed to create Bucket Tile');
      return; // should be created at this point
    }
  }

  await dispatch(updateTilePositions(results));
  dispatch(
    updateTileViewPos({
      status: false,
    }),
  );
};

export const attemptClearSuggestion = () => {
  return {
    type: 'CLEAR_SUGGESTION',
  };
};

export const attemptCompleteCreateSuggestion = ({
  item,
  bucketId,
  position,
}) => async dispatch => {
  const template = await dispatch(attemptCreateTileTemplate(item));
  if (template.error) return template;
  if (!template.id) return;
  const resp = await dispatch(
    attemptCreateBucketTile({
      bucketId,
      position,
      tileTemplateId: template.id,
    }),
  );

  dispatch(attemptClearSuggestion());
  return resp;
};

export const attemptUpdateSuggestionData = data => ({
  type: 'UPDATE_SUGGESTION_DATA',
  data,
});

export const attemptCreateSuggestion = ({ target }) => {
  return {
    type: 'CREATE_SUGGESTION',
    data: {
      bucketId: target.listType === 'BUCKET' && target.listId,
      position: target.position,
    },
  };
};

export const attemptCreateTileTemplate = item => dispatch =>
  createTileTemplate(item)
    .then(data => {
      dispatch(addTileTemplate(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteTileTemplate = id => dispatch =>
  deleteTileTemplate(id)
    .then(data => {
      dispatch(removeTileTemplate(id));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateTileTemplate = item => dispatch =>
  editTileTemplate(item)
    .then(data => {
      dispatch(updateTileTemplate(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateTileTemplates = selected => dispatch => {
  const actions = [];
  selected.forEach(element => {
    actions.push(dispatch(attemptUpdateTileTemplate(element)));
  });
  return Promise.all(actions);
};

export const fetchSearchTileTemplates = (query, nextPageUrl) => dispatch => {
  dispatch(fetchTileTemplateStatus('REQUEST', query));
  return searchTileTemplates(query, nextPageUrl)
    .then(data => {
      const normalizedActionResp = setTileTemplates(data);
      dispatch(normalizedActionResp);
      dispatch(
        fetchTileTemplateStatus(
          'SUCCESS',
          query,
          normalizedActionResp.tileTemplateIds,
        ),
      );

      return data;
    })
    .catch(e => {
      dispatch(handleError(e));
      dispatch(fetchTileTemplateStatus('FAILURE', query, e));
    });
};

export const attemptSearchTileTemplates = query => dispatch => {
  dispatch(updateSearchTerm(query));
  return dispatch(
    loadPage(null, 'tileTemplates', query, null, fetchSearchTileTemplates),
  );
};
