import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import getDisplayName from 'react-display-name';
import { DragDropContextConsumer } from 'react-dnd';
import hoist from 'hoist-non-react-statics';

export function createScrollingComponent(WrappedComponent) {
  class ScrollingComponent extends Component {
    static displayName = `Scrolling(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      // eslint-disable-next-line react/forbid-prop-types
      dragDropManager: PropTypes.object.isRequired,
      onScrollChange: PropTypes.func,
    };

    constructor(props, ctx) {
      super(props, ctx);

      this.wrappedInstance = React.createRef();

      this.scaleX = 0;
      this.scaleY = 0;
      this.frame = null;

      this.attached = false;
      this.dragging = false;
    }

    componentDidMount() {
      // eslint-disable-next-line react/no-find-dom-node
      this.container = findDOMNode(this.wrappedInstance.current);

      if (
        this.container &&
        typeof this.container.addEventListener === 'function'
      ) {
        this.container.addEventListener('dragenter', this.handleEnterEvent);
        this.container.addEventListener('dragleave', this.handleLeaveEvent);
      }

      const { dragDropManager } = this.props;
      this.clearMonitorSubscription = dragDropManager
        .getMonitor()
        .subscribeToStateChange(() => this.handleMonitorChange());
    }

    componentWillUnmount() {
      if (
        this.container &&
        typeof this.container.removeEventListener === 'function'
      ) {
        this.container.removeEventListener('dragenter', this.handleEnterEvent);
        this.container.removeEventListener('dragleave', this.handleLeaveEvent);
      }

      this.clearMonitorSubscription();
      this.stopScrolling();
    }

    stopScrolling() {
      this.detach();
    }

    handleEnterEvent = () => {
      if (this.dragging) {
        this.props.onEnter();
      }
    };

    handleLeaveEvent = () => {
      if (this.dragging) {
        this.props.onLeave();
      }
    };

    handleMonitorChange() {
      const { dragDropManager } = this.props;
      const isDragging = dragDropManager.getMonitor().isDragging();

      if (!this.dragging && isDragging) {
        this.dragging = true;
      } else if (this.dragging && !isDragging) {
        this.dragging = false;
      }
    }

    attach() {
      this.attached = true;
      this.container.addEventListener('dragenter', this.handleEnterEvent);
      this.container.addEventListener('dragleave', this.handleLeaveEvent);
    }

    detach() {
      this.attached = false;
      this.container.removeEventListener('dragenter', this.handleEnterEvent);
      this.container.removeEventListener('dragleave', this.handleLeaveEvent);
    }

    render() {
      const {
        // not passing down these props
        onScrollChange,
        onEnter,
        onLeave,
        ...props
      } = this.props;

      return <WrappedComponent ref={this.wrappedInstance} {...props} />;
    }
  }

  return hoist(ScrollingComponent, WrappedComponent);
}

export default function createScrollingComponentWithConsumer(WrappedComponent) {
  const ScrollingComponent = createScrollingComponent(WrappedComponent);
  return props => (
    <DragDropContextConsumer>
      {({ dragDropManager }) =>
        dragDropManager === undefined ? null : (
          <ScrollingComponent {...props} dragDropManager={dragDropManager} />
        )
      }
    </DragDropContextConsumer>
  );
}
