import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import classNames from 'classnames';
import { attemptMoveCard } from '_store/thunks/cards';
import ItemTypes from './itemTypes';

class PriorityPosition extends Component {
  render() {
    const {
      connectDropTarget,
      isOver,
      canDrop,
      children,
      isTruncated,
    } = this.props;

    return connectDropTarget(
      <div
        className={classNames('priority-position-container', {
          truncated: isTruncated,
          'has-content': children !== null,
        })}
      >
        <div
          className={classNames('position', {
            'drop-zone': !isOver && canDrop,
            'drop-over': isOver && canDrop,
          })}
        >
          {children}
        </div>
      </div>,
    );
  }
}

export default DropTarget(
  ItemTypes.CARD,
  {
    canDrop(props, monitor) {
      return true;
    },

    drop(props, monitor) {
      const item = monitor.getItem();
      props.dispatch(
        attemptMoveCard(
          props.packId,
          item.sequence,
          props.sequence,
          [props.x, props.y],
          props,
          item,
        ),
      );
    },
  },
  (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    };
  },
)(PriorityPosition);
