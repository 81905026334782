import update from 'immutability-helper';

import { SET_PACKS, UPDATE_PACK, ADD_PACK, REMOVE_PACK } from '_actions/packs';
import { ADD_PACK_CARD, REMOVE_CARD, UPDATE_CARDS } from '_actions/cards';
import { SET_TILES, REMOVE_TILE } from '_actions/tiles';

function updateIds(state, ids) {
  if (ids.length === 0) return state;
  return [...new Set(state.concat(ids))];
}

function removeIds(state, ids = []) {
  const newState = state;
  if (ids.length === 0) return state;
  return newState.filter(x => !ids.includes(x));
}

function removePacksById(state, ids = []) {
  let newState = state;
  ids.forEach(id => {
    newState = update(newState, {
      [id]: {
        $set: undefined,
      },
    });
  });
  return newState;
}

function removeCards(state, { id, cards }) {
  let newState = state;
  cards.forEach(card => {
    newState = update(newState, {
      byId: {
        [id]: {
          cards: {
            $set: state.byId[id].cards.filter(x => x !== card),
          },
        },
      },
    });
  });
  return newState;
}

function updateCards(state, action) {
  let newState = state;
  action.cardIds.forEach(card => {
    newState = update(newState, {
      byId: {
        [action.packTemplateId]: {
          cards: {
            $set: updateIds(
              state.byId[action.packTemplateId].cards,
              action.cardIds,
            ),
          },
        },
      },
    });
  });
  return newState;
}

export default function packs(state = { all: [], byId: {} }, action) {
  switch (action.type) {
    case SET_TILES:
    case SET_PACKS:
      return update(state, {
        all: { $set: updateIds(state.all, action.packsAll) },
        byId: { $set: action.packs || {} },
      });
    case ADD_PACK:
      return update(state, {
        all: { $push: [action.id] },
        byId: {
          [action.id]: {
            $set: action.pack,
          },
        },
      });
    case ADD_PACK_CARD:
      return update(state, {
        byId: {
          [action.id]: {
            cards: {
              $set: updateIds(state.byId[action.id].cards || [], [
                action.cardId,
              ]),
            },
          },
        },
      });
    case REMOVE_TILE:
      return update(state, {
        all: { $set: removeIds(state.all, action.packTemplateIds) },
        byId: { $set: removePacksById(state.byId, action.packTemplateIds) },
      });

    case REMOVE_PACK:
      return update(state, {
        byId: { [action.id]: { status: { $set: 'UNSUBSCRIBED' } } },
      });
    case REMOVE_CARD:
      return removeCards(state, {
        id: action.packId,
        cards: [action.id],
      });
    case UPDATE_CARDS: {
      return updateCards(state, action);
    }
    case UPDATE_PACK:
      return update(state, {
        byId: {
          [action.id]: {
            $set: action.pack,
          },
        },
      });
    default:
      return state;
  }
}
