import React, { Component } from 'react';
import { Tile } from '_components';
import { PlaceholderTile } from '../tile/placeholder';
import { tilePositions } from '_utils/tilePositioning';

class InternalComponent extends Component {
  componentDidMount() {
    this.props.setEventListeners && this.props.setEventListeners();
  }
  renderItem = (item, index, isOverflow) => {
    const TileComponent = !item ? PlaceholderTile : Tile;
    const position = (item && item.position) || index + 1;
    return (
      <TileComponent
        item={item}
        index={index}
        moveCard={this.props.moveCard}
        removeCard={this.props.removeCard}
        listId={this.props.listId}
        listType={this.props.listType}
        position={(item && item.position) || index + 1}
        tileSelector={this.props.tileSelector}
        isOverflow={isOverflow}
        placeholder={!item}
        key={position}
      />
    );
  };

  calcMarginTop = col => {
    const colIndex = col % 5;
    return tilePositions[this.props.listType].large.top[colIndex];
  };

  calcMarginLeft = col => {
    const colIndex = col % 5;
    return tilePositions[this.props.listType].large.left[colIndex];
  };

  renderRows = (col, noCols, noRows, remainder) => {
    const renderedItems = [];
    if (col === 4 && this.props.query)
      renderedItems.push(this.renderItem(null, noCols * 0 + col, true));
    for (let r = 0; r < noRows; r++) {
      const index = noCols * r + col;
      if (
        col === 4 &&
        r === noRows - 1 &&
        !this.props.items[index] &&
        this.props.query
      )
        continue;
      renderedItems.push(
        this.renderItem(
          this.props.items[index],
          col === 4 && this.props.query ? index + 1 : index,
        ),
      );
    }

    for (let r = noRows; r < noRows + remainder; r++) {
      renderedItems.push(this.renderItem(null, noCols * r + col, true));
    }

    return renderedItems;
  };

  renderCol = (c, noCols, noRows, remainder, renderRows) => (
    <div
      className={`tile-grid-col ${c}`}
      style={{
        marginTop: this.calcMarginTop(c),
        marginLeft: this.calcMarginLeft(c),
      }}
      key={c}
    >
      {renderRows(c, noCols, noRows, remainder)}
    </div>
  );

  renderGrid = () => {
    const {
      noCols,
      maxRows,
      lastPosition,
      renderRows: renderCustomRows,
      items = [],
    } = this.props;
    const renderRows = renderCustomRows || this.renderRows;

    const noRows =
      items.length > 0
        ? Math.ceil((lastPosition || items.length + 1) / noCols)
        : maxRows;

    const remainder = maxRows - noRows === 0 ? 1 : maxRows - noRows;

    const grid = [];

    for (let c = 0; c < noCols; c++) {
      const col = this.renderCol(c, noCols, noRows, remainder, renderRows);
      grid.push(col);
    }
    return grid;
  };

  render() {
    return (
      <div
        id={this.props.listType}
        className={`tile-grid ${this.props.containerClass}`}
        ref={this.props.gridRef}
        key={'tile-grid'}
      >
        {this.renderGrid()}
      </div>
    );
  }
}

export const TileGrid = React.forwardRef((props, ref) => (
  <InternalComponent gridRef={ref} {...props} />
));

export default { TileGrid };
