import { connect } from 'react-redux';
import { attemptDeleteShelfTile, attemptMoveShelfTile } from '_thunks/shelves';
import { ShelfContainer } from './container';

function tileSelector(state, props = {}) {
  const item =
    props.id === 'temp'
      ? { id: 'temp', tileTemplateId: props.templateId }
      : state.tiles[props.id] || {};
  const template = state.tileTemplates.byId[item.tileTemplateId] || {};

  return {
    ...template,
    ...item,
    id: item.id,
  };
}

const mapStateToProps = (state, props) => {
  const id = props.id;
  return {
    tileSelector,
    shelf: state.shelves.find(x => x.id === id) || {},
    bucketId: state.buckets[0] && state.buckets[0].id,
    type: 'SHELF',
  };
};

const mapDispatchToProps = dispatch => ({
  deleteShelfTile: data => dispatch(attemptDeleteShelfTile(data)),

  moveShelfTile: data => dispatch(attemptMoveShelfTile(data)),
});

export const Shelf = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShelfContainer);
