import update from 'immutability-helper';

const initialState = {
  position: null,
  bucketId: null,
  isCreating: false,
  isSaving: false,
  isDirty: false,
  tileTemplate: {
    id: null,
    name: null,
    imgurl: null,
    hexColour: '#d71f30',
    nounHexColour: '#ffffff',
    textHexColour: undefined,
    text: null,
    typeId: null,
    textShow: true,
    nounShow: true,
    active: false,
  },
};

export default function suggestion(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_SUGGESTION':
      return update(state, {
        $set: {
          ...state,
          isCreating: true,
          ...action.data,
        },
      });
    case 'UPDATE_SUGGESTION_DATA':
      return update(state, {
        $set: {
          ...state,
          tileTemplate: {
            ...action.data,
          },
          isDirty: true,
        },
      });
    case 'CLEAR_SUGGESTION':
      return update(state, {
        $set: initialState,
      });
    case '@@router/LOCATION_CHANGE':
      return initialState;
    default:
      return state;
  }
}
