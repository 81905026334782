import { request } from '_utils/api';

export const createCard = (tileId, data) => {
  return request('post', `/tile/${tileId}/pack/${data.packId}/card`, {
    body: data,
  });
};

export const updateCard = (tileId, data) => {
  return request('put', `/tile/${tileId}/pack/${data.packId}/card/${data.id}`, {
    body: data,
  });
};
