import R from '_utils/ramda';
import { connect } from 'react-redux';
import { RouterContainer } from './routerContainer';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

const mapStateToProps = R.pick([]);

const _Router = connect(mapStateToProps)(RouterContainer);
export const Router = DragDropContext(HTML5Backend)(_Router);

export default { Router };
