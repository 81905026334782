import React, { Component } from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { boundMethod } from 'autobind-decorator';

export class TwoWayModalHeader extends Component {
  renderSaveAction = () => {
    const { isDirty, onSave, isSaving } = this.props;

    return (
      <IconButton
        title="Save"
        disabled={!isDirty}
        className="modal-header-save"
        onClick={onSave}
      >
        {isSaving ? (
          <CircularProgress size={20} />
        ) : (
          <SaveIcon title="Save Tile" />
        )}
      </IconButton>
    );
  };

  renderUndoAction = () => {
    const { isDirty, onUndo } = this.props;

    return (
      <IconButton disabled={!isDirty} title="Reset" onClick={onUndo}>
        <UndoIcon />
      </IconButton>
    );
  };

  @boundMethod
  renderMenuOrTitle(menuItems, activeMenuIndex, onMenuChange, title) {
    if (menuItems && menuItems.length > 0) {
      return (
        <div className="modal-header-menu">
          {menuItems &&
            menuItems.map(item => (
              <div
                className={classNames('menu-item', {
                  'is-active': item.id === activeMenuIndex,
                })}
                key={item.id}
                data-index={item.id}
                onClick={onMenuChange}
              >
                {item.title}
              </div>
            ))}
        </div>
      );
    } else if (title) {
      return (
        <div className="modal-header-title">
          <span>{title}</span>
        </div>
      );
    }
  }

  render() {
    const {
      className,
      menuItems,
      title,
      onClose,
      onUndo,
      onSave,
      onMenuChange,
      activeMenuIndex,
    } = this.props;
    return (
      <div
        className={classNames(
          'modal-header',
          'two-way-modal-header',
          className,
        )}
        onClose={onClose}
      >
        <div className="modal-header-inner">
          {this.renderMenuOrTitle(
            menuItems,
            activeMenuIndex,
            onMenuChange,
            title,
          )}
          <div className="modal-header-actions">
            {onSave && this.renderSaveAction()}
            {onUndo && this.renderUndoAction()}
            <IconButton
              title="Close"
              className="modal-header-close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default { TwoWayModalHeader };
