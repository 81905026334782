import update from 'immutability-helper';

import {
  SET_PUBLIC_SHELVES,
  SET_PUBLIC_SHELF_DATA,
  UPDATE_PUBLIC_SHELVES_SEARCH,
} from '_actions/publicShelves';

const initialState = {
  all: [],
  byId: {},
  search: null,
};

export function updatedShelfData(state, id, data) {
  return update(state, {
    byId: {
      [id]: {
        tilePositions: { $set: data.tilePositions },
        tilePositionIds: { $set: data.tilePositionIds },
      },
    },
  });
}

export function updateIds(state, ids = []) {
  if (ids.length === 0) return state.all;
  return [...new Set(...state.all, ...ids)];
}

export function clearPublicShelf(state, id) {
  const index = state.all.indexOf(id);
  if (index === -1) return state;
  return update(state, {
    byId: { $unset: [id] },
    all: { $splice: [[index, 1]] },
  });
}

export default function publicShelves(state = initialState, action) {
  switch (action.type) {
    case SET_PUBLIC_SHELVES:
      return update(state, {
        all: { $set: updateIds(state, action.publicShelfIds) },
        byId: { $merge: action.publicShelves },
      });
    case SET_PUBLIC_SHELF_DATA:
      return updatedShelfData(state, action.id, action.data);
    case UPDATE_PUBLIC_SHELVES_SEARCH:
      return update(state, { search: { $set: action.value } });
    default:
      return state;
  }
}
