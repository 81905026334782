import { request } from '_utils/api';

export const getBuckets = () => request('get', `/bucket`);

export const getBucket = id => request('get', `/bucket/${id}`);

export const getBucketTilePositions = id =>
  request('get', `/bucket/${id}/tileposition`);

export const editBucketTilePositions = ({ id, tilePositions }) => {
  return request('post', `/bucket/${id}/tileposition`, {
    body: tilePositions,
  });
};

export const getBucketTilePosition = ({ id, position }) =>
  request('get', `/bucket/${id}/tileposition/${position}`);

export const deleteBucketTilePosition = ({ id, position }) =>
  request('del', `/bucket/${id}/tileposition/${position}`);
