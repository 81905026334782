import React, { Component } from 'react';
import classNames from 'classnames';
import { IconInverseButton as IconButton } from 'shared-code';

export class ArrowTile extends Component {
  onArrowClick = e => {
    this.props.onClick(this.props.direction);
  };
  render() {
    const { isDragging, direction } = this.props;

    const opacity = isDragging ? 1 : 1;

    return (
      <div
        ref={c => (this.tile = c)}
        className={classNames('arrow tile', direction)}
        style={{ opacity }}
      >
        <div className="tile-container">
          <IconButton
            onClick={this.onArrowClick}
            isDomElement
            id={'arrow'}
            label={
              direction === 'UP'
                ? // <CaretUpCircleIcon />
                  <i className="fas fa-chevron-circle-up" />
                : // <CaretDownCircleIcon />
                  <i className="fas fa-chevron-circle-down" />
            }
          />
        </div>
      </div>
    );
  }
}
