export const SET_COLLECTION_TYPES = 'SET_COLLECTION_TYPES';
export const ADD_COLLECTION_TYPE = 'ADD_COLLECTION_TYPE';
export const UPDATE_COLLECTION_TYPE = 'UPDATE_COLLECTION_TYPE';
export const REMOVE_COLLECTION_TYPE = 'REMOVE_COLLECTION_TYPE';

export function setCollectionTypes(collectionTypes) {
  return {
    type: SET_COLLECTION_TYPES,
    collectionTypes,
  };
}

export const addCollectionType = collectionType => ({
  type: ADD_COLLECTION_TYPE,
  id: collectionType.id,
  collectionType,
});

export const removeCollectionType = id => ({
  type: REMOVE_COLLECTION_TYPE,
  id,
});

export const updateCollectionType = collectionType => ({
  type: UPDATE_COLLECTION_TYPE,
  id: collectionType.id,
  collectionType,
});
