import * as React from 'react';
import { DragLayer } from 'react-dnd';
import { TileDragPreview, SuggestionTile } from '_components';

function getItemStyles(props) {
  const { initialOffset, currentOffset, item } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  const transform = item.isSuggestion
    ? `translate(${x + 140}px, ${y - 137}px)`
    : `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
class _TileDragLayer extends React.PureComponent {
  renderItem = () => {
    const { item, itemType } = this.props;
    switch (itemType) {
      case 'TILE':
        if (item.isSuggestion)
          return <SuggestionTile isPreview item={item.item} />;
        return (
          <TileDragPreview item={item.item} tileSelector={item.tileSelector} />
        );

      default:
        return null;
    }
  };
  render() {
    const { isDragging } = this.props;

    if (!isDragging) {
      return null;
    }

    return (
      <div className="drag-layer">
        <div style={getItemStyles(this.props)}>{this.renderItem()}</div>
      </div>
    );
  }
}
export const TileDragLayer = DragLayer(monitor => {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
})(_TileDragLayer);
