import { request } from '_utils/api';

export const createPack = (tileId, data) => {
  return request('post', `/tile/${tileId}/pack`, { body: data });
};

export const updatePack = (tileId, data) => {
  return request('put', `/tile/${tileId}/pack/${data.id}`, {
    body: data,
  });
};
