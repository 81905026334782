import { MASS_UPDATE_UI_STATE } from './consts';

export function massUpdateUI(uiVars, transforms) {
  return {
    type: MASS_UPDATE_UI_STATE,
    payload: {
      uiVars,
      transforms,
    },
  };
}

export default { massUpdateUI };
