import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoginForm, SignupForm } from '_components';
import classNames from 'classnames';

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class NestedAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignup: true,
    };
  }

  toggleView = () => {
    this.setState({ isSignup: !this.state.isSignup });
  };

  render() {
    const { isSignup } = this.state;

    return (
      <section className="auth-wrapper nested">
        <div className={classNames('auth-content', { in: isSignup })}>
          <LoginForm
            {...this.props}
            isSignup={isSignup}
            nested={true}
            updateByPropertyName={updateByPropertyName}
          />
          <SignupForm
            {...this.props}
            isSignup={isSignup}
            nested={true}
            updateByPropertyName={updateByPropertyName}
          />
        </div>

        <div
          className={classNames('switch-button', {
            'hidden-element': !isSignup,
          })}
        >
          <h4 className="title is-4" onClick={this.toggleView}>
            Already a member? Sign in
          </h4>
        </div>
        <div
          className={classNames('switch-button', {
            'hidden-element': isSignup,
          })}
        >
          <h4 className="title is-4" onClick={this.toggleView}>
            New Here? Sign Up
          </h4>
        </div>
      </section>
    );
  }
}

NestedAuth.propTypes = {
  isSignup: PropTypes.bool,
};

NestedAuth.defaultProps = {
  isSignup: false,
};
export default { NestedAuth };
