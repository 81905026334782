import { normalizeTop6Positions } from '../../normalize/top6Positions';

export const LOAD_TOP6 = 'LOAD_TOP6';
export const MOVE_TOP6_ITEM = 'MOVE_TOP6_ITEM';
export const REMOVE_TOP6_ITEM = 'REMOVE_TOP6_ITEM';

export const loadTop6 = (packId, cards) => (dispatch, getState) => {
  const cardIds = getState().cards.byId;
  const items = cards.map(x => ({ ...x, ...(cardIds[x.id] || {}) }));
  const { top6PositionIds, top6Positions } = normalizeTop6Positions(items);
  return dispatch({
    type: LOAD_TOP6,
    payload: {
      packId,
      top6PositionIds,
      top6: top6Positions,
    },
  });
};

export function moveTop6Item(packId, currentPosition, newPosition, incomingId) {
  return {
    type: MOVE_TOP6_ITEM,
    payload: {
      packId,
      currentPosition,
      newPosition,
      incomingId,
    },
  };
}

export function removeTop6Item(packId, currentPosition,incomingId) {
  return {
    type: REMOVE_TOP6_ITEM,
    payload: {
      packId,
      currentPosition,
      incomingId,
    },
  };
}

