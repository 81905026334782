import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Manager } from 'react-popper';
import classNames from 'classnames';
import { DropdownContext } from './dropdownContext';
import { omit, keyCodes } from '../utils';

export class Dropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    group: PropTypes.bool,
    isOpen: PropTypes.bool,
    active: PropTypes.bool,
    addonType: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['prepend', 'append']),
    ]),
    size: PropTypes.string,
    toggle: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    setActiveFromChild: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: false,
    active: false,
    addonType: false,
    setActiveFromChild: false,
  };

  static childContextTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
  };

  getChildContext() {
    return {
      toggle: this.props.toggle,
      isOpen: this.props.isOpen,
    };
  }

  componentDidMount() {
    this.handleProps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.handleProps();
    }
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  getMenuCtrl = () => {
    if (this._$menuCtrl) return this._$menuCtrl;
    this._$menuCtrl = this.container.querySelector('[aria-expanded]');
    return this._$menuCtrl;
  };

  getMenuItems = () => {
    return [].slice.call(this.container.querySelectorAll('[role="menuitem"]'));
  };

  addEvents = () => {
    ['click', 'touchstart', 'keyup'].forEach(event =>
      document.addEventListener(event, this.handleDocumentClick, true),
    );
  };

  removeEvents = () => {
    ['click', 'touchstart', 'keyup'].forEach(event =>
      document.removeEventListener(event, this.handleDocumentClick, true),
    );
  };

  handleDocumentClick = e => {
    if (
      e &&
      (e.which === 3 || (e.type === 'keyup' && e.which !== keyCodes.tab))
    )
      return;
    const container = this.container;

    if (
      container.contains(e.target) &&
      container !== e.target &&
      (e.type !== 'keyup' || e.which === keyCodes.tab)
    ) {
      return;
    }

    this.toggle(e);
  };

  handleKeyDown = e => {
    if (
      /input|textarea/i.test(e.target.tagName) ||
      (keyCodes.tab === e.which && e.target.getAttribute('role') !== 'menuitem')
    ) {
      return;
    }

    e.preventDefault();

    if (this.props.disabled) return;

    if (this.getMenuCtrl() === e.target) {
      if (
        !this.props.isOpen &&
        [keyCodes.space, keyCodes.enter, keyCodes.up, keyCodes.down].indexOf(
          e.which,
        ) > -1
      ) {
        this.toggle(e);
        setTimeout(() => this.getMenuItems()[0].focus());
      }
    }

    if (this.props.isOpen && e.target.getAttribute('role') === 'menuitem') {
      if ([keyCodes.tab, keyCodes.esc].indexOf(e.which) > -1) {
        this.toggle(e);
        this.getMenuCtrl().focus();
      } else if ([keyCodes.space, keyCodes.enter].indexOf(e.which) > -1) {
        e.target.click();
        this.getMenuCtrl().focus();
      } else if (
        [keyCodes.down, keyCodes.up].indexOf(e.which) > -1 ||
        ([keyCodes.n, keyCodes.p].indexOf(e.which) > -1 && e.ctrlKey)
      ) {
        const $menuitems = this.getMenuItems();
        let index = $menuitems.indexOf(e.target);
        if (keyCodes.up === e.which || (keyCodes.p === e.which && e.ctrlKey)) {
          index = index !== 0 ? index - 1 : $menuitems.length - 1;
        } else if (
          keyCodes.down === e.which ||
          (keyCodes.n === e.which && e.ctrlKey)
        ) {
          index = index === $menuitems.length - 1 ? 0 : index + 1;
        }
        $menuitems[index].focus();
      } else if (keyCodes.end === e.which) {
        const $menuitems = this.getMenuItems();
        $menuitems[$menuitems.length - 1].focus();
      } else if (keyCodes.home === e.which) {
        const $menuitems = this.getMenuItems();
        $menuitems[0].focus();
      } else if (e.which >= 48 && e.which <= 90) {
        const $menuitems = this.getMenuItems();
        const charPressed = String.fromCharCode(e.which).toLowerCase();
        for (let i = 0; i < $menuitems.length; i += 1) {
          const firstLetter =
            $menuitems[i].textContent &&
            $menuitems[i].textContent[0].toLowerCase();
          if (firstLetter === charPressed) {
            $menuitems[i].focus();
            break;
          }
        }
      }
    }
  };

  handleProps = () => {
    if (this.props.isOpen) {
      this.addEvents();
    } else {
      this.removeEvents();
    }
  };

  toggle = e => {
    if (this.props.disabled) {
      return e && e.preventDefault();
    }

    return this.props.toggle(e);
  };

  render() {
    const {
      className,
      isOpen,
      group,
      size,
      setActiveFromChild,
      active,
      addonType,
      ...attrs
    } = omit(this.props, ['toggle', 'disabled', 'inNavbar']);

    let subItemIsActive = false;
    if (setActiveFromChild) {
      React.Children.map(
        this.props.children[1].props.children,
        dropdownItem => {
          if (dropdownItem && dropdownItem.props.active) subItemIsActive = true;
        },
      );
    }

    const classes = classNames(
      className,
      setActiveFromChild && subItemIsActive ? 'active' : false,
      {
        [`input-group-${addonType}`]: addonType,
        'btn-group': group,
        [`btn-group-${size}`]: !!size,
        dropdown: !group && !addonType,
        show: isOpen,
      },
    );

    return (
      <DropdownContext.Provider value={this.getChildContext()}>
        <Manager>
          <div
            ref={c => {
              this.container = c;
            }}
            {...attrs}
            onKeyDown={this.handleKeyDown}
            className={classes}
          />
        </Manager>
      </DropdownContext.Provider>
    );
  }
}

export default { Dropdown };
