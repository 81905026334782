import { SET_DEFAULT_UI_STATE } from './consts';

export function setDefaultUI(key, value) {
  return {
    type: SET_DEFAULT_UI_STATE,
    payload: {
      key,
      value,
    },
  };
}

export default { setDefaultUI };
