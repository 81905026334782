import { schema } from 'normalizr';

import { normalize } from 'normalizr';
import { parseNormalizedData } from '_utils/normalize';
import { normalizeTileTemplates } from './tileTemplates';

export const tile = new schema.Entity('tiles');
export const tileTemplate = new schema.Entity('tileTemplate');

const field = new schema.Entity(
  'fields',
  {},
  { idAttribute: 'fieldTemplateId' },
);

const card = new schema.Entity(
  'cards',
  {
    fields: [field],
  },
  { idAttribute: 'cardTemplateId' },
);

const pack = new schema.Entity(
  'packs',
  {
    cards: [card],
  },
  { idAttribute: 'packTemplateId' },
);

tile.define({
  tileTemplate,
  packs: [pack],
});
export const tileSchema = tile;
export const packSchema = pack;
export const packsSchema = [pack];
export const cardSchema = card;
export const cardsSchema = [card];
export const fieldsSchema = [field];

export const normalizeTiles = data => {
  const normalizedData = normalize(data, [tile]);

  const { tileIds, entities } = parseNormalizedData(normalizedData, 'tile');

  const tileTemplatesNormalized = normalizeTileTemplates(entities.tileTemplate);

  return { tileIds, ...entities, ...tileTemplatesNormalized };
};

export const normalizeCards = data => {
  const normalizedData = normalize(data, [card]);

  const { cardIds, entities } = parseNormalizedData(normalizedData, 'card');

  return { cardIds, ...entities };
};
