const getClosest = (counts, goal) => {
  if (counts.length === 0) return null;
  return counts.reduce((prev, curr) =>
    Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev,
  );
};

export const getNextAvailblePosition = (
  tilePositionIds,
  currentPosition,
  type,
) => {
  const missing = [];

  let lastPos;
  if (type === 'BUCKET') {
    lastPos = tilePositionIds[tilePositionIds.length - 1];
    if (!lastPos) lastPos = 1;
  } else lastPos = 6;

  for (var i = 1; i < lastPos + 1; i++) {
    if (tilePositionIds.indexOf(i) < 0) {
      missing.push(i);
    }
  }

  if (type === 'BUCKET') {
    missing.push(lastPos + 1);
  }

  return getClosest(missing, currentPosition);
};
