import { normalizeTileTemplates } from '../normalize/tileTemplates';
export const SET_TILE_TEMPLATES = 'SET_TILE_TEMPLATES';
export const ADD_TILE_TEMPLATE = 'ADD_TILE_TEMPLATE';
export const UPDATE_TILE_TEMPLATE = 'UPDATE_TILE_TEMPLATE';
export const REMOVE_TILE_TEMPLATE = 'REMOVE_TILE_TEMPLATE';
export const ADD_COLLECTION_TILE_TEMPLATE = 'ADD_COLLECTION_TILE_TEMPLATE';
export const TILE_TEMPLATES_REQUEST = 'TILE_TEMPLATES_REQUEST';
export const TILE_TEMPLATES_SUCCESS = 'TILE_TEMPLATES_SUCCESS';
export const TILE_TEMPLATES_FAILURE = 'TILE_TEMPLATES_FAILURE';
export const TILE_TEMPLATES_RESET = 'TILE_TEMPLATES_RESET';
export const UPDATE_TILE_TEMPLATES_SEARCH = 'UPDATE_TILE_TEMPLATES_SEARCH';

export function setTileTemplates(tileTemplates) {
  return {
    type: SET_TILE_TEMPLATES,
    ...normalizeTileTemplates(tileTemplates),
  };
}

export function updateSearchTerm(value = '') {
  return { type: UPDATE_TILE_TEMPLATES_SEARCH, value };
}

export function fetchTileTemplateStatus(status, value = '', result) {
  return {
    type: `TILE_TEMPLATES_${status}`,
    value,
    payload: {
      value,
      result,
    },
  };
}

export const addTileTemplate = tileTemplate => ({
  type: ADD_TILE_TEMPLATE,
  id: tileTemplate.id,
  tileTemplate,
});

export const addCollectionTileTemplate = (id, tileTemplate) => ({
  type: ADD_COLLECTION_TILE_TEMPLATE,
  id,
  tileTemplate,
});

export const updateTileTemplate = tileTemplate => ({
  type: UPDATE_TILE_TEMPLATE,
  tileTemplate,
  id: tileTemplate.id,
});

export const removeTileTemplate = id => ({
  type: REMOVE_TILE_TEMPLATE,
  id,
});

export const resetTileTemplates = () => ({
  type: TILE_TEMPLATES_RESET,
  value: '',
});
