import update from 'immutability-helper';
import { LOAD_BOARDS, MOVE_CARD } from '../actions/cardBoard/board';

export default function boards(state = {}, action) {
  switch (action.type) {
    case LOAD_BOARDS:
      const { packId, cards } = action.payload;
      const newState = [];
      cards.forEach(card => {
        newState.push({
          id: card.id,
          card,
          cardPosition: [0, card.priority - 1],
        });
      });
      return update(state, {
        [packId]: { $set: newState },
      });
    case MOVE_CARD: {
      const {
        packId,
        oldSequence,
        newSequence,
        newCardPosition,
      } = action.payload;

      const newState = [...state[packId]];

      if (oldSequence !== newSequence) {
        const temp = newState[newSequence];
        newState[newSequence] = newState[oldSequence];
        newState[oldSequence] = {
          ...temp,
          //cardPosition: newState[newSequence].cardPosition, for swap action
        };
      }
      newState[newSequence] = {
        ...newState[newSequence],
        cardPosition: newCardPosition,
      };

      return update(state, {
        [packId]: {
          [newSequence]: { $set: newState[newSequence] },
          [oldSequence]: { $set: newState[oldSequence] },
        },
      });
    }

    default:
      return state;
  }
}
