import { normalizeCards } from '../normalize/tiles';

export const ADD_CARD = 'ADD_CARD';
export const REMOVE_CARD = 'REMOVE_CARD';
export const SET_CARD = 'SET_CARD';
export const SET_CARDS = 'SET_CARDS';
export const UPDATE_CARD = 'UPDATE_CARD';
export const ADD_PACK_CARD = 'ADD_PACK_CARD';
export const UPDATE_CARDS = 'UPDATE_CARDS';

export const addCard = (tileId, card) => ({
  type: ADD_CARD,
  id: card.id,
  tileId,
  card,
});

export const removeCard = (tileId, card) => ({
  type: REMOVE_CARD,
  id: card.id,
  tileId,
});

export const updateCards = (packTemplateId, cards) => ({
  type: UPDATE_CARDS,
  packTemplateId,
  ...normalizeCards(cards),
});
