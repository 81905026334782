import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export default function MouseOverText({
  dragging,
  noun,
  text,
  nounStyle,
  textStyle,
  style,
}) {
  return (
    <div className="tile-text" style={nounStyle}>
      <span className="tile-shape-outside" />
      <Tooltip
        title={
          <React.Fragment>
            <div className="tooltip-container" style={style}>
              <span className="tooltip-text noun" style={nounStyle}>
                {noun}
              </span>
              <span className="tooltip-text text" style={textStyle}>
                {text}
              </span>
            </div>
          </React.Fragment>
        }
        enterDelay={500}
        leaveDelay={200}
        // open={true}
        classes={{
          popper: 'mouse-over-tooltip-popover',
          tooltip: 'mouse-over-tooltip',
          tooltipPlacementTop: 'mouse-over-tooltip-top',
          tooltipPlacementBottom: 'mouse-over-tooltip-bottom',
        }}
      >
        <p>{noun}</p>
      </Tooltip>
    </div>
  );
}
