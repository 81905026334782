import React from 'react';

export function AuthConfirmSignup(props) {
  // console.log('AuthConfirmSignup', props);
  return (
    <div className="confirm-signup">
      <div className="inner-content">
        <span className="confirm-signup-title">Verify Your Account</span>
        <span className="confirm-signup-text">
          We just sent you an email. Please click the verification link to
          continue.
        </span>
      </div>
    </div>
  );
}

export default { AuthConfirmSignup };
