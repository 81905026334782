import React from 'react';
import classNames from 'classnames';

export function Input({
  error,
  type,
  property,
  placeholder,
  onChange,
  onFocus,
  className,
  inputClassName,
}) {
  return (
    <fieldset
      className={classNames('form-fieldset-input', className, {
        'has--error': error,
      })}
      data-error={error}
    >
      <input
        className={classNames('form-input', inputClassName, {
          'has--error': error,
        })}
        type={type}
        data-property={property}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
      />
    </fieldset>
  );
}

export default { Input };
