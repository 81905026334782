import { connect } from 'react-redux';
import {
  attemptSearchTileTemplates,
  attemptMoveTileTemplate,
} from '_thunks/tileTemplates';

import { SearchContainer } from './container';

function tileSelector(state, id) {
  return state.tileTemplates.byId[id];
}

const mapStateToProps = (state, props) => {
  const query = state.tileTemplates.search || '';

  return {
    tileSelector,
    query,
    searchType: 'TILE_TEMPLATES',
    items:
      (state.pagination.tileTemplates[query] &&
        state.pagination.tileTemplates[query].ids) ||
      [],
    isFetching:
      state.pagination.tileTemplates[query] &&
      state.pagination.tileTemplates[query].isFetching,
  };
};

const mapDispatchToProps = dispatch => ({
  search: data => dispatch(attemptSearchTileTemplates(data)),
  move: data => dispatch(attemptMoveTileTemplate(data)),
});

export const TileTemplatesSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchContainer);

export default { TileTemplatesSearch };
