import { connect } from 'react-redux';
import {
  attemptPublishShelf,
  attemptUnpublishShelf,
  attemptPublishShelfWithImage,
} from '_thunks/shelves';
import {
  attemptLogout,
} from "_thunks/user";
import { errorNotification } from '_utils/api';
import { PublishButtonContainer } from './container';

const mapDispatchToProps = (dispatch, props) => ({
  publishShelf: () => dispatch(attemptPublishShelf(props.id)),
  publishShelfWithImage: data => dispatch(attemptPublishShelfWithImage(data)),
  unpublishShelf: () => dispatch(attemptUnpublishShelf(props.id)),
  handleError: e => dispatch(errorNotification(e)),
  logout: () => dispatch(attemptLogout()),
});

export const PublishButton = connect(
  null,
  mapDispatchToProps,
)(PublishButtonContainer);

export default { PublishButton };


