import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function Popout({ show, children }) {
  return (
    <div className={classNames('popout', { 'is-visible': show })}>
      <div className="popout-inner">{children}</div>
    </div>
  );
}

Popout.propTypes = {
  show: PropTypes.bool,
};

Popout.defaultProps = {
  show: false,
};

export default { Popout };
