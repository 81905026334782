import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import validate from 'validate.js';
import { Auth } from 'aws-amplify';
import { Error, Input, Button, Checkbox } from '_components';
import { signupConstraints } from '../constraints';
import R from '_utils/ramda';

const INITIAL_STATE = {
  name: '',
  username: '',
  email: '',
  password: '',
  termsChecked: false,
  error: {
    name: null,
    username: null,
    email: null,
    password: null,
    general: null,
  },
};

export class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  changeState = (type, event) => {
    const { changeAuthState } = this.props;
    changeAuthState(type, event);
  };

  onError = error => {
    if (typeof error === 'string') {
      this.setState({
        error: {
          ...this.state.error,
          general: error,
        },
      });
      return;
    }

    switch (error.code) {
      case 'UserNotFoundException':
        this.setState({
          error: {
            ...this.state.error,
            username: ['Username cannot be found.'],
          },
        });
        break;
      case 'NotAuthorizedException':
        this.setState({
          error: {
            ...this.state.error,
            general: error.message,
          },
        });
        break;
      default:
        this.setState({
          error: {
            ...this.state.error,
            general: error.message,
          },
        });
    }
  };

  onSubmit = event => {
    const { name, username, email, password, termsChecked } = this.state;
    const validations = validate(
      { name, username, email, password, termsChecked },
      signupConstraints,
    );

    if (!validations || R.isEmpty(validations)) {
      Auth.signUp({
        username,
        password,
        attributes: {
          email,
          name,
        },
      })
        .then(authData => {
          this.setState(() => ({ ...INITIAL_STATE }));

          return this.changeState('confirmSignUp', authData);

          // } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          //   this.changeState('requireNewPassword', user);
          // } else if (user.challengeName === 'MFA_SETUP') {
          //   this.changeState('TOTPSetup', user);
          // } else {
          // if (this.props.nested) {
          //   return this.props.history.push('/');
          // }
          // }
        })
        .catch(err => {
          // if (err.code === 'UserNotConfirmedException') {
          //   this.changeState('confirmSignUp');
          // } else if (err.code === 'PasswordResetRequiredException') {
          //   this.changeState('requireNewPassword');
          // } else {
          this.onError(err);
          // }
        });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ...validations,
        },
      });
    }

    event.preventDefault();
  };

  togglePassword = () => {
    this.setState({ password: !this.state.password });
  };

  toggleChecked = () => {
    this.setState({ termsChecked: !this.state.termsChecked });
  };

  handleNameChange = event => {
    const { updateByPropertyName } = this.props;
    this.setState(updateByPropertyName('name', event.target.value));
  };

  handleUsernameChange = event => {
    const { updateByPropertyName } = this.props;
    this.setState(updateByPropertyName('username', event.target.value));
  };

  handleEmailChange = event => {
    const { updateByPropertyName } = this.props;
    this.setState(updateByPropertyName('email', event.target.value));
  };

  handlePasswordChange = event => {
    const { updateByPropertyName } = this.props;
    this.setState(updateByPropertyName('password', event.target.value));
  };

  handleOnFocus = event => {
    const property = event.currentTarget.dataset.property;

    this.setState({
      error: {
        ...this.state.error,
        [`${property}`]: null,
      },
    });
  };

  render() {
    const { isSignup } = this.props;
    const { error } = this.state;
    return (
      <div
        className={classNames('content-wrapper', {
          'hidden-element': !isSignup,
        })}
      >
        <h1 className="auth-content-title title is-1">Find Your Tribe</h1>
        <h4 className="auth-content-subtitle subtitle is-4">
          Join the sixtiles community, home to lorem ipsum and lorem ipsum.
        </h4>
        {error.general ? <Error message={error.general} /> : null}
        <form className="form" noValidate="">
          <fieldset className="form-fieldset">
            <div className="form-input-box">
              <Input
                className="with--margin"
                inputClassName="registration-input margin-input"
                error={error.name && error.name[0]}
                type="text"
                property="name"
                placeholder="Full Name"
                onChange={this.handleNameChange}
                onFocus={this.handleOnFocus}
              />
              <Input
                className="with--margin"
                inputClassName="registration-input"
                error={error.username && error.username[0]}
                type="text"
                property="username"
                placeholder="Username"
                onChange={this.handleUsernameChange}
                onFocus={this.handleOnFocus}
              />
            </div>
            <div className="form-input-box box_margin">
              <Input
                className="with--margin"
                inputClassName="registration-input margin-input email"
                error={error.email && error.email[0]}
                type="email"
                property="email"
                placeholder="Email"
                onChange={this.handleEmailChange}
                onFocus={this.handleOnFocus}
              />

              <div className="sign-up-pass-wrapper">
                <Input
                  className="with--margin"
                  inputClassName="registration-input"
                  error={error.password && error.password[0]}
                  type={this.state.password ? 'password' : 'text'}
                  property="password"
                  placeholder="Password"
                  onChange={this.handlePasswordChange}
                  onFocus={this.handleOnFocus}
                />
                <div
                  onClick={this.togglePassword}
                  className="sign-up-pass-eye"
                />
              </div>
            </div>
          </fieldset>
          <div className="terms-wrapper">
            <Checkbox
              id="terms"
              name="terms"
              title="Terms And Conditions"
              checked={this.state.termsChecked}
              onChange={this.toggleChecked}
              type="checkbox"
            />
            <div>
              I have read the&nbsp;
              <Link className="terms-link" to="/privacy">
                Term &amp; Conditions
              </Link>
            </div>
          </div>
          <Button onClick={this.onSubmit} label="Sign Up" />
        </form>
      </div>
    );
  }
}

export default { SignupForm };
