import update from 'immutability-helper';
import {
  UPDATE_TILE_VIEW_REMOVE,
  UPDATE_TILE_VIEW_MOVE,
} from '_actions/tileViews';

export default function tileViews(
  state = {
    remove: {},
    move: {
      BUCKET: {},
      SHELF: {},
    },
  },
  action = {},
) {
  switch (action.type) {
    case UPDATE_TILE_VIEW_REMOVE:
      return update(state, {
        remove: {
          $set: {
            [action.id]: action.status ? true : undefined,
          },
        },
      });
    case UPDATE_TILE_VIEW_MOVE:
      return update(state, {
        move: {
          $set: {
            SHELF: action.status ? action.SHELF : {},
            BUCKET: action.status ? action.BUCKET : {},
          },
        },
      });
    default:
      return state;
  }
}
