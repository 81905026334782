import { UPDATE_UI_STATE } from './consts';

export function updateUI(key, name, value) {
  return {
    type: UPDATE_UI_STATE,
    payload: {
      key,
      name,
      value,
    },
  };
}

export default { updateUI };
