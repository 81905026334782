import React, { Component } from 'react';
import classnames from 'classnames';
import {
  TileGrid,
  PublicShelf,
  withScrollMonitor,
  SuggestionTile,
} from '_components';
import SearchHeader from './searchBox';

@withScrollMonitor
class _SearchContainer extends Component {
  componentDidMount() {
    if (this.props.view === 'PEOPLE' && this.props.setEventListeners)
      this.props.setEventListeners();
  }

  renderTileGrid = items => (
    <TileGrid
      containerClass={'explore'}
      listType={'SEARCH'}
      items={items}
      tileSelector={this.props.tileSelector}
      noCols={5}
      maxRows={6}
      query={this.props.query}
      moveCard={this.props.move}
      ref={this.props.scrollRef}
      setEventListeners={this.props.setEventListeners}
    />
  );

  renderPublicShelves = items =>
    items.map(item => <PublicShelf key={item} id={item} />);

  render() {
    const {
      hidden,
      items,
      search,
      searchType,
      isScrollingUp,
      direction,
      isScrolledToTop,
      view,
      activateSearch,
    } = this.props;

    return (
      <React.Fragment>
        <SearchHeader
          key={0}
          search={search}
          createSuggestion={this.props.createSuggestion}
          onSwitch={this.props.onSwitch}
          view={this.props.view}
          isFetching={this.props.isFetching}
          onClose={this.props.onClose}
          hidden={hidden}
          activateSearch={activateSearch}
          showBar={isScrolledToTop || isScrollingUp || direction === 'up'}
        />
        {!hidden ? (
          <div
            key={1}
            className={classnames(
              'rhs-search-container',
              {
                searching: this.props.query,
              },
              view,
            )}
            ref={this.props.scrollRef}
          >
            {this.props.query ? (
              <SuggestionTile moveCard={this.props.createSuggestion} />
            ) : null}
            {searchType === 'TILE_TEMPLATES'
              ? this.renderTileGrid(items)
              : this.renderPublicShelves(items)}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export const SearchContainer = _SearchContainer;

export default { SearchContainer };
