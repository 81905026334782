import { HomePage } from '_pages';

export default [
  {
    exact: true,
    path: '/home',
    tab: 'home',
    component: HomePage,
    title: 'Home',
  },
  {
    exact: true,
    path: '/',
    tab: 'home',
    component: HomePage,
    title: 'Home',
  },
  {
    exact: true,
    path: '/:id?/:timestamp?',
    tab: 'home',
    component: HomePage,
    title: 'Home',
  },
];
