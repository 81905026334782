import React, { Component } from 'react';

import { TileGrid, ArrowTile, TrashTile, Tile, CustomTile } from '_components';
import ScrollArea from './scrollArea';

const NO_COLS = 5;

export class BucketContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
    };
    this.maxRows = window.innerHeight <= 870 ? 2 : 3;
  }
  componentDidMount() {
    this.props.getBuckets().then(() => {});
  }

  arrowClick = direction => {
    if (direction === 'UP' && this.state.step === 0) return;
    this.setState({
      step: direction === 'UP' ? this.state.step - 1 : this.state.step + 1,
    });
  };

  renderItem = (item, index) => {
    let TileComponent = Tile;
    if (!item && this.props.suggestedPos === index + 1)
      TileComponent = CustomTile;
    const position = (item && item.position) || index + 1;

    return (
      <TileComponent
        key={position}
        item={item}
        index={index}
        moveCard={this.props.moveBucketTile}
        removeCard={this.props.deleteBucketTile}
        listType={'BUCKET'}
        listId={this.props.bucket.id}
        tileSelector={this.props.tileSelector}
        placeholder={!item}
        position={position}
      />
    );
  };

  renderTrashTile = () => {
    return <TrashTile key="TRASH" removeCard={this.props.removeCard} />;
  };

  shouldRenderItem = index => {
    const tilePositions = this.props.bucket.tilePositions || {};
    return this.renderItem(tilePositions[index + 1], index);
  };

  renderRows = c => {
    const renderedItems = [];
    for (let r = this.state.step; r < this.state.step + this.maxRows; r++) {
      renderedItems.push(this.shouldRenderItem(r * NO_COLS + c));
    }
    if (c === 0) renderedItems.push(this.renderTrashTile());

    return renderedItems;
  };

  render() {
    const { bucket = {}, tileSelector } = this.props;

    return (
      <div className="bucket-container">
        <ScrollArea id="UP" onScroll={this.arrowClick} />
        <TileGrid
          containerClass={'bucket'}
          items={bucket.tilePositions || {}}
          noCols={NO_COLS}
          maxRows={this.maxRows}
          listType={'BUCKET'}
          listId={bucket.id}
          moveCard={this.props.moveBucketTile}
          removeCard={this.props.deleteBucketTile}
          renderRows={this.renderRows}
          tileSelector={tileSelector}
          lastPosition={
            bucket.tilePositionIds && bucket.tilePositionIds.length > 0
              ? Math.max(...bucket.tilePositionIds)
              : null
          }
        />
        <ScrollArea id="DOWN" onScroll={this.arrowClick} />
        {this.state.step !== 0 ? (
          <ArrowTile direction="UP" onClick={this.arrowClick} />
        ) : null}
        <ArrowTile direction="DOWN" onClick={this.arrowClick} />
      </div>
    );
  }
}

export default { BucketContainer };
