import {
  getBuckets,
  getBucketTilePositions,
  editBucketTilePositions,
  getBucket,
} from '_api/buckets';
import {
  setBuckets,
  setBucketTilePositions,
  updateBucketTilePositions,
} from '_actions/buckets';
import { updateTileViewPos } from '../actions/tileViews';
import { getNewTilePositions, updateTilePositions } from '_thunks/utils';
import { attemptCreateTile, attemptDeleteTile } from '_thunks/tiles';

import { dispatchError } from '_utils/api';

export const attemptGetBuckets = () => dispatch =>
  getBuckets()
    .then(data => {
      dispatch(setBuckets(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptGetBucket = () => dispatch =>
  getBucket()
    .then(data => {
      dispatch(setBuckets([data]));

      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptGetBucketTilePositions = id => async dispatch => {
  const tilePositionsResponse = await getBucketTilePositions(id);
  if (tilePositionsResponse.error) dispatchError(dispatch);
  else {
    dispatch(
      setBucketTilePositions({
        id,
        tilePositions: tilePositionsResponse,
      }),
    );
  }
};

export const attemptCreateBucketTile = ({
  bucketId,
  position,
  tileTemplateId,
}) => dispatch =>
  dispatch(attemptCreateTile({ tileTemplateId, bucketId }))
    .then(data => {
      return dispatch(
        attemptUpdateBucketTilePositions({
          id: bucketId,
          tilePositions: [{ position, id: data.id }],
        }),
      );
    })
    .catch(dispatchError(dispatch));

export const attemptMoveBucketTile = ({ source, target }) => async (
  dispatch,
  getState,
) => {
  const ids = getState().shelves[0].tilePositionIds;
  const results = getNewTilePositions(source, target, ids);

  dispatch(
    updateTileViewPos({
      results,
      status: true,
    }),
  );
  await dispatch(updateTilePositions(results));
  dispatch(
    updateTileViewPos({
      status: false,
    }),
  );
};

export const attemptUpdateBucketTilePositions = ({
  id,
  tilePositions,
}) => dispatch =>
  editBucketTilePositions({
    id,
    tilePositions,
  })
    .then(data => {
      dispatch(updateBucketTilePositions(id, data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteBucketTile = ({ source }) => dispatch =>
  dispatch(attemptDeleteTile({ id: source.item.id }))
    .then(data => {
      return data;
    })
    .catch(dispatchError(dispatch));
