// Images
import avatar from './images/default-profile.png';
import logo from './images/logo_white.png';
import logoAlt from './images/logo_color.png';
import close from './images/close.svg';

export { avatar, logo, close };

export default {
  avatar,
  logo,
  close,
  logoAlt,
};
