import React from 'react';

export function AuthConfirmSignin(props) {
  return (
    <div className="confirm-signup">
      <div className="inner-content">
        <span className="confirm-signup-title">Verify Your Account</span>
        <span className="confirm-signup-text">
          Please verify your email address first by clicking the verification
          link.
        </span>
      </div>
    </div>
  );
}

export default { AuthConfirmSignin };
