import React from 'react';
import classnames from 'classnames';
import PriorityPosition from './priorityPosition';
import BoardCard from './boardCard';
import { ui } from '../../decorators/ui';
@ui()
class Board extends React.Component {
  renderPriority = i => {
    const {
      id,
      packId,
      sequence,
      dispatch,
      tileId,
      tileTemplateId,
      cardY,
      card,
    } = this.props;
    const x = 0;
    const y = i;

    return (
      <PriorityPosition
        key={i}
        x={x}
        y={y}
        packId={packId}
        sequence={sequence}
        boardId={id}
        dispatch={dispatch}
        tileId={tileId}
        tileTemplateId={tileTemplateId}
        isPriority={y === 0 && y === cardY}
        isTruncated={(y === 2 || y === 0) && y === cardY}
        card={card}
      >
        {this.renderCard(x, y)}
      </PriorityPosition>
    );
  };

  renderCard = (x, y) => {
    const {
      cardX,
      cardY,
      card,
      id,
      packId,
      sequence,
      dispatch,
      bgColor,
      nounColor,
      tileId,
      tileTemplateId,
      updateUI,
    } = this.props;
    const isCardHere = x === cardX && y === cardY;
    return isCardHere
      ? <BoardCard
          data={card}
          sequence={sequence}
          priorityPosition={cardY}
          bgColor={bgColor}
          nounColor={nounColor}
          dispatch={dispatch}
          tileId={tileId}
          boardId={id}
          packId={packId}
          tileTemplateId={tileTemplateId}
          updateUI={updateUI}
        />
      : null;
  };

  render() {
    const { numPositions, id, isLast, isTop6 } = this.props;

    const priorities = [];
    for (let i = 0; i < numPositions; i += 1) {
      priorities.push(this.renderPriority(i));
    }
    return (
      <div
        key={id}
        className={classnames('board content', { 'top-6': isTop6 })}
        style={
          isLast
            ? {
                width: '242px',
                minWidth: '242px',
                paddingRight: '22px',
                transition: 'none',
              }
            : null
        }
      >
        {priorities}
      </div>
    );
  }
}
export default Board;
