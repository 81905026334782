import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoginForm, SignupForm, SwitchContent, Logo } from '_components';
import classNames from 'classnames';

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

export class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignup: props.isSignup,
    };
  }

  toggleView = () => {
    this.setState({ isSignup: !this.state.isSignup });
  };

  render() {
    const { isSignup } = this.state;

    return (
      <section className="auth-wrapper">
        <div className={classNames('auth-content', { in: isSignup })}>
          <LoginForm
            {...this.props}
            isSignup={isSignup}
            updateByPropertyName={updateByPropertyName}
          />
          <SignupForm
            {...this.props}
            isSignup={isSignup}
            updateByPropertyName={updateByPropertyName}
          />
        </div>
        <SwitchContent isSignup={isSignup} onSwitch={this.toggleView} />
        <Logo alt={isSignup} /> {/* <Close onClick={this.onClose} /> */}
      </section>
    );
  }
}

Auth.propTypes = {
  isSignup: PropTypes.bool,
};

Auth.defaultProps = {
  isSignup: false,
};

export default { Auth };
