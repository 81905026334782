import update from 'immutability-helper';
import {
  SET_TILE_TEMPLATES,
  UPDATE_TILE_TEMPLATES_SEARCH,
  ADD_TILE_TEMPLATE,
} from '_actions/tileTemplates';
import { SET_TILES } from '_actions/tiles';
const initialState = {
  byId: {},
  all: [],
  search: null,
};
function updateIds(state, ids = []) {
  if (ids.length === 0) return state.all;
  return [...new Set(state.all.concat(ids))];
}
function updateById(state, items, ids) {
  let newState = state;
  ids.forEach(id => {
    const incomingItem = items[id];
    if (!state[id]) {
      newState = update(newState, {
        [id]: { $set: incomingItem },
      });
    } else {
      newState = update(newState, {
        [id]: { $merge: incomingItem },
      });
    }
  });
  return newState;
}

export default function tileTemplates(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TILE_TEMPLATES:
    case SET_TILES:
      return update(state, {
        all: { $set: updateIds(state, action.tileTemplateIds) },
        byId: {
          $set: updateById(
            state.byId,
            action.tileTemplates,
            action.tileTemplateIds,
          ),
        },
      });
    case UPDATE_TILE_TEMPLATES_SEARCH:
      return update(state, { search: { $set: action.value } });
    case ADD_TILE_TEMPLATE:
      return update(state, {
        all: { $push: [action.id] },
        byId: {
          [action.id]: {
            $set: action.tileTemplate,
          },
        },
      });
    default:
      return state;
  }
}
