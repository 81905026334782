import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router';
import Notifications from 'react-notification-system-redux';
import routes from './routes';
import AmplifyCustomUi from 'aws-amplify-react-custom-ui';
import Auth from '@aws-amplify/auth';
import Api from '@aws-amplify/api';
import SharedApi from 'shared-code/lib/utils/api';
SharedApi.configure({ Auth, Api });

function Main(props) {
  return (
    <div className="has-navbar-fixed-top">
      <Notifications notifications={props.alerts || []} />
      <div className="main">
        <Switch>
          {routes.map((route, i) => <Route key={i} {...route} />)}
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

Main.propTypes = {
  alerts: PropTypes.array,
};

export default AmplifyCustomUi.withAuthenticator(Main);
