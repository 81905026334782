import React from 'react'
import { DropTarget } from 'react-dnd'
import classNames from 'classnames';
import ItemTypes from '../boards/itemTypes';
import { attemptToDeleteTop6Item } from '../../store/thunks/top6';

const DeleteTop6DropZone = React.forwardRef(
	(props, ref) => {
		const {  isOverCurrent, connectDropTarget, top6Title, children } = props;
		const shouldRenderTop6Title = top6Title && (top6Title !== 'https://www.google.com/api');
		return connectDropTarget(
			<div className={classNames('delete-top6-drop-zone', {'is-over': isOverCurrent})}>
        <div className="delete-zone-overlay">
					{isOverCurrent ? <h2 className="instructions">
						Drop card here to remove from {shouldRenderTop6Title ? top6Title : 'Top 6'}.
					</h2> : null }
				</div>
				<div>{children}</div>
			</div>,
		)
	},
)

export default DropTarget(
	ItemTypes.TOP6_ITEM,
	{
		drop(
			props,
			monitor,
		) {
			const item = monitor.getItem();
			props.dispatch(attemptToDeleteTop6Item(props.packId, props, item));
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		isOverCurrent: monitor.isOver({ shallow: true }),
	}),
)(DeleteTop6DropZone)
