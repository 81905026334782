import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialogPaper: {
    maxHeight: '95vh !important',
    height: '680px !important',
    width: '1200px !important',
    maxWidth: '95vw !important',
    margin: 'auto !important',
    borderRadius: '40px !important',
    backgroundColor: 'rgb(227, 237, 247) !important',
  },
  backDrop: {
    backgroundColor: 'rgba(227, 237, 247, 0.5) !important',
  },
};

class Modal extends Component {
  state = {
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    fullscreen: PropTypes.bool,
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { open, children, fullscreen, classes } = this.props;
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          open={open}
          fullScreen={fullscreen}
          className={classNames('modal-custom', {
            'is-fullscreen': fullscreen,
            'is-default': !fullscreen,
          })}
          TransitionComponent={fullscreen ? Transition : undefined}
          classes={{ paper: classes.dialogPaper }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
        >
          {children}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(Modal);
