import React, { Component } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import flow from 'lodash/flow';

class _PlaceholderTile extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }
  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      isOverflow,
    } = this.props;

    const opacity = isDragging ? 1 : 1;

    return connectDragSource(
      connectDropTarget(
        <div
          ref={c => (this.tile = c)}
          className={classNames('tile-placeholder tile', {
            overflow: isOverflow,
          })}
          style={{ opacity }}
        >
          <div className="tile-container" />
        </div>,
      ),
    );
  }
}

const cardSource = {
  canDrag: function(props, monitor) {
    return !props.placeholder;
  },
  beginDrag(props) {},

  endDrag(props, monitor) {},
};

const cardTarget = {
  canDrop(props, monitor) {
    if (props.listType === 'SEARCH') {
      return false;
    }

    return true;
  },
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return {};
    }

    return { moved: true, placeholder: true, targetProps: props };
  },
};

function selector(state, props) {
  return {};
}

export const PlaceholderTile = flow(
  connect(selector),
  DropTarget('TILE', cardTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  })),
  DragSource('TILE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  })),
)(_PlaceholderTile);
