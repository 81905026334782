import Notifications from 'react-notification-system-redux';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

export const requestPublic = (key, path, options = {}) => {
  return API[key]('sixtilesPublicAPI', path, Object.assign({ ...options }))
    .then(handleSuccess)
    .catch(handleError);
};

export const request = (key, path, options = {}) =>
  Auth.currentSession().then(session => {
    const config = {
      headers: Object.assign({ Authorization: session.getIdToken().jwtToken }),
    };

    return API[key](
      'sixtilesAPI',
      path,
      Object.assign({ ...options, ...config }),
    )
      .then(handleSuccess)
      .catch(handleError);
  });

export const handleSuccess = resp => {
  if (resp.errno) {
    return handleError(resp);
  } else return resp;
};

export const handleError = error => {
  if (error.response) {
    throw error.response;
  } else {
    const response = {
      status: 500,
      body: { message: 'Internal Server error' },
    };
    throw response;
  }
};

export const errorNotification = message => dispatch =>
  dispatch(
    Notifications.error({
      title: `Error`,
      message,
      position: 'tr',
      autoDismiss: 5,
    }),
  );

export const dispatchError = dispatch => res => {
  const { message = 'error' } = res.data || {};

  dispatch(
    Notifications.error({
      title: `Error: ${res.status}`,
      message,
      position: 'tr',
      autoDismiss: 5,
    }),
  );
};
export const successNotification = res => dispatch => {
  const { message = 'Success' } = res;

  dispatch(
    Notifications.success({
      title: `Success`,
      message,
      position: 'tr',
      autoDismiss: 5,
    }),
  );
};
