import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    s: '%ds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export const getTime = timestamp => {
  return moment(timestamp).format('hh:mm');
};

export const getDay = timestamp => {
  return moment(timestamp).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY',
  });
};

export const getDateTimeInSentence = timestamp => {
  if (timestamp) {
    try {
      return moment(timestamp).format('Do MMM YY [at] h.ma');
    } catch (err) {
      return null;
    }
  }
  return null;
};

export const getTimeFromNowInWords = timestamp => {
  if (!timestamp) return '';
  return moment(timestamp.millis || timestamp).fromNow(true);
};

export const getTimeFromNowInMinutes = timestamp => {
  if (!timestamp) return null;

  return moment().diff(timestamp, 'minutes');
};

export const getRandomDate = (rangeOfDays, startHour, hourRange) => {
  const today = new Date(Date.now());
  return new Date(
    today.getYear() + 1900,
    today.getMonth(),
    today.getDate() + Math.random() * rangeOfDays,
    Math.random() * hourRange + startHour,
    Math.random() * 60,
  );
};
