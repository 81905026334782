import * as React from 'react';
import { DragLayer } from 'react-dnd';
import { Card, Top6Item } from 'shared-code';
import itemTypes from '../boards/itemTypes';

function getItemStyles(props) {
  const { initialOffset, currentOffset } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
class _CardDragLayer extends React.PureComponent {
  renderItem = () => {
    const { item, itemType } = this.props;
    switch (itemType) {
      case itemTypes.CARD:
        return (
          <div className="card-container preview">
            <Card
              data={item}
              bgColor={item.bgColor}
              nounColor={item.nounColor}
            />
          </div>
        );
        case itemTypes.TOP6_ITEM:
        return (
          <div className="card-container preview">
            <Top6Item
              id={item.cardTemplateId}
              data={{ ...item, imgurl: item.imgurlCustom }}
              bgColor={item.bgColor}
              nounColor={item.nounColor}
            />
          </div>
        );

      default:
        return null;
    }
  };
  render() {
    const { isDragging } = this.props;

    if (!isDragging) {
      return null;
    }

    return (
      <div className="drag-layer">
        <div style={getItemStyles(this.props)}>
          {this.renderItem()}
        </div>
      </div>
    );
  }
}
export const CardDragLayer = DragLayer(monitor => {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
})(_CardDragLayer);

export default { CardDragLayer };
