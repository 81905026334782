import {
  getPublicShelves,
  getPublicShelf,
  searchPublicShelves,
} from '_api/publicShelves';
import {
  setPublicShelves,
  fetchPublicShelvesStatus,
  updateSearchTerm,
} from '_actions/publicShelves';
import { dispatchError, handleError } from '_utils/api';
import { loadPage } from './pagination';

export const attemptGetPublicShelves = () => dispatch =>
  getPublicShelves()
    .then(data => {
      dispatch(setPublicShelves(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptGetPublicShelf = id => dispatch =>
  getPublicShelf(id).then(data => {
    dispatch(setPublicShelves([data]));
    return data;
  });

export const fetchSearchPublicShelves = (query, nextPageUrl) => dispatch => {
  return searchPublicShelves(query, nextPageUrl)
    .then(data => {
      const normalizedActionResp = setPublicShelves(data);
      dispatch(normalizedActionResp);

      dispatch(
        fetchPublicShelvesStatus(
          'SUCCESS',
          query,
          normalizedActionResp.publicShelfIds,
        ),
      );

      return data;
    })
    .catch(e => {
      dispatch(handleError(e));
      dispatch(fetchPublicShelvesStatus('FAILURE', query, e));
    });
};

export const attemptSearchPublicShelves = query => dispatch => {
  dispatch(updateSearchTerm(query));
  dispatch(
    loadPage(null, 'publicShelves', query, null, fetchSearchPublicShelves),
  );
};
