import { request } from '_utils/api';

export const getCategoryImages = id => request('get', `/category/${id}/image`);

export const createCategoryImage = ({ id, userImage }) => {
  return request('post', `/category/${id}/image`, { body: userImage });
};

export const removeCategoryImage = ({ id, imageId }) =>
  request('del', `/category/${id}/image/${imageId}`);
