import { normalizeTilePositions } from '_normalize/tilePositions';

export const SET_BUCKETS = 'SET_BUCKETS';
export const UPDATE_BUCKET_TILE_POSITIONS = 'UPDATE_BUCKET_TILE_POSITIONS';
export const REMOVE_BUCKET_TILE_POSITION = 'REMOVE_BUCKET_TILE_POSITION';
export const SET_BUCKET_TILE_POSITIONS = 'SET_BUCKET_TILE_POSITIONS';
export const SET_BUCKET_DATA = 'SET_BUCKET_DATA';

export function setBuckets(buckets) {
  buckets = buckets.map(b => {
    return {
      ...b,
      ...normalizeTilePositions(b.tilePositions || []),
    };
  });
  return {
    type: SET_BUCKETS,
    buckets,
  };
}

export const setBucketTilePositions = ({ id, tilePositions }) => ({
  type: SET_BUCKET_DATA,
  id,
  data: {
    ...normalizeTilePositions(tilePositions || []),
  },
});

export const updateBucketTilePositions = (id, data = []) => ({
  type: UPDATE_BUCKET_TILE_POSITIONS,
  id,
  ...normalizeTilePositions(data || []),
});

export const removeBucketTilePosition = ({ id, position }) => ({
  type: REMOVE_BUCKET_TILE_POSITION,
  id,
  tilePosition: position,
});
