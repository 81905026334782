import React, { PureComponent } from 'react';
import { getTimeFromNowInWords } from '_utils/date';
import { PublicShelf, NestedAuth } from '_components';

export class Container extends PureComponent {
  state = {
    loading: {
      shelf: true,
    },
  };

  componentWillMount() {
    this.props
      .getShelf(this.props.id)
      .then(data => {
        if (!data.id) this.props.history.push('/404');
        this.setState({ publishTimestamp: data.publishTimestamp });
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <div className="public-shelf-page">
        <div className="home-content shelf">
          <div className="lhs-wrapper">
            <PublicShelf id={this.props.id} />
            <span className="last-published">
              {`Last Published: ${getTimeFromNowInWords(
                this.state.publishTimestamp,
              )} ago`}
            </span>
          </div>
          <div className="rhs-wrapper">
            <NestedAuth {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default { Container };
