import update from 'immutability-helper';
import * as R from 'ramda';

import {
  SET_SHELVES,
  UPDATE_SHELF,
  UPDATE_SHELF_TILE_POSITIONS,
  SET_SHELF_DATA,
} from '_actions/shelves';
import { REMOVE_TILE } from '_actions/tiles';

export function addTilePosition(state, index, tilePosition) {
  if (!index || index === -1) return state;
  const action = state[index].tilePositions ? '$push' : '$set';
  return update(state, {
    [index]: {
      tilePositions: { [action]: [tilePosition] },
    },
  });
}

export function removeTile(state, id) {
  let newState = state;

  state.forEach((shelf, index) => {
    const delPosition = state[index].tilePositionIds.find(
      e => state[index].tilePositions[e].id === id,
    );

    if (!delPosition) return;

    newState = update(newState, {
      [index]: {
        tilePositionIds: {
          $set: state[index].tilePositionIds.filter(pos => pos !== delPosition),
        },
        tilePositions: {
          [delPosition]: { $set: undefined },
        },
      },
    });
  });

  return newState;
}

export function updatedShelfData(state, index, data) {
  return update(state, {
    [index]: {
      tilePositions: { $set: data.tilePositions },
      tilePositionIds: { $set: data.tilePositionIds },
    },
  });
}

export function updateShelf(state, index, data) {
  return update(state, {
    [index]: {
      $set: {
        ...state[index],
        ...data,
        tilePositions: state[index].tilePositions,
      },
    },
  });
}

export function updateTilePositions(
  state,
  index,
  tilePositions,
  tilePositionIds,
) {
  return update(state, {
    [index]: {
      tilePositions: { $set: tilePositions },
      tilePositionIds: { $set: tilePositionIds },
    },
  });
}

export default function shelves(state = [], action) {
  const index = R.findIndex(R.propEq('id', action.id), state);

  switch (action.type) {
    case SET_SHELVES:
      return update(state, { $set: action.shelves });
    case UPDATE_SHELF:
      return updateShelf(state, index, action.shelf);
    case SET_SHELF_DATA:
      return updatedShelfData(state, index, action.data);
    case UPDATE_SHELF_TILE_POSITIONS:
      return updateTilePositions(
        state,
        index,
        action.tilePositions,
        action.tilePositionIds,
      );
    case REMOVE_TILE:
      return removeTile(state, action.id);
    default:
      return state;
  }
}
