import { schema } from 'normalizr';

export const parseNormalizedData = (data, key, byIdKey) => {
  const byId = byIdKey || `${key}s`;
  const ids = `${key}Ids`;

  return {
    entities: data.entities,
    [byId]: data.entities[byId],
    [ids]: data.result,
  };
};

export function createEntitySchema(key, id) {
  return new schema.Entity(key, {}, { idAttribute: [id] });
}

export function createSchema(key, id, inclArray = false) {
  const entity = new schema.Entity(key, {}, { idAttribute: [id] });
  if (!inclArray) {
    return entity;
  }

  return new schema.Array(entity);
}

export default { parseNormalizedData, createEntitySchema, createSchema };
