import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TileContainer } from '_components';

class _TileDragPreview extends PureComponent {
  render() {
    const { tile } = this.props;

    return (
      <div
        className="tile grab"
        style={{
          '--hex-color': tile.hexColour,
          '--hex-texture-wrap': tile.hexTextureWrap,
        }}
      >
        <TileContainer item={tile} />
      </div>
    );
  }
}

function selector(state, props) {
  if (props.tileSelector) {
    return {
      tile: props.tileSelector(state, props.item),
    };
  }
  return { tile: props.item };
}

export const TileDragPreview = connect(selector)(_TileDragPreview);

export default { TileDragPreview };
