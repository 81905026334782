import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import flow from 'lodash/flow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

class _SuggestionTile extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }
  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      isPreview,
    } = this.props;

    const opacity = isDragging ? 0 : 1;

    return connectDragSource(
      connectDropTarget(
        <div
          ref={c => (this.tile = c)}
          className={classNames('suggestion tile', { preview: isPreview })}
          style={{ opacity }}
        >
          <div className="tile-container">
            <span style={{ opacity }}>
              <FontAwesomeIcon
                icon={faPlus}
                style={{ color: '#ffffff' }}
                size={'2x'}
              />
            </span>
            <span className="tile-text">Create</span>
          </div>
        </div>,
      ),
    );
  }
}

const cardSource = {
  canDrag: function(props) {
    return true;
  },
  beginDrag(props) {
    return {
      ...props,
      listType: 'SEARCH',
      isSuggestion: true,
    };
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult() || {};

    // The suggestion tile was dragged onto a shelf or bucket
    if (dropResult.moved)
      props.moveCard({ source: item, target: dropResult.targetProps });
  },
};

const cardTarget = {
  canDrop() {
    // no tile can be dragged on to the suggestion tile
    return false;
  },
};

function selector() {
  return {
    listType: 'SEARCH',
  };
}

export const SuggestionTile = flow(
  connect(selector),
  DropTarget('TILE', cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
  })),
  DragSource('TILE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),

    connectDragPreview: connect.dragPreview(),
  })),
)(_SuggestionTile);
