import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';
import 'shared-code/lib/assets/styles/index.scss';
import '_assets/styles/index.scss';
import '_utils/polyfill';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';

import { Router } from '_routes';
import configureStore from '_store/configureStore';

const history = createBrowserHistory();
const store = configureStore(history);
const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

render(
  <StylesProvider generateClassName={generateClassName}>
    <Router store={store} history={history} />
  </StylesProvider>,
  document.getElementById('app'),
);
