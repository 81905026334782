import React, { Component } from 'react';
import classNames from 'classnames';
import validate from 'validate.js';
import Auth from '@aws-amplify/auth';
import { Error, Input, Button } from '_components';
import { loginConstraints } from '../constraints';
import R from '_utils/ramda';

const INITIAL_STATE = {
  username: '',
  password: '',
  error: {
    username: null,
    password: null,
    general: null,
  },
};

export class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  changeState = (type, event) => {
    const { changeAuthState } = this.props;
    changeAuthState(type, event);
  };

  onError = error => {
    if (typeof error === 'string') {
      this.setState({
        error: {
          ...this.state.error,
          general: error,
        },
      });
      return;
    }

    switch (error.code) {
      case 'UserNotFoundException':
        this.setState({
          error: {
            ...this.state.error,
            username: ['Username cannot be found.'],
          },
        });
        break;
      case 'NotAuthorizedException':
        this.setState({
          error: {
            ...this.state.error,
            general: error.message,
          },
        });
        break;
      default:
        this.setState({
          error: {
            ...this.state.error,
            general: error.message,
          },
        });
    }
  };

  onSubmit = event => {
    const { username, password } = this.state;
    const validations = validate({ username, password }, loginConstraints);

    if (!validations || R.isEmpty(validations)) {
      Auth.signIn(username, password)
        .then(user => {
          this.setState(() => ({ ...INITIAL_STATE }));
          // console.log('user', user);
          if (
            user.challengeName === 'SMS_MFA' ||
            user.challengeName === 'SOFTWARE_TOKEN_MFA'
          ) {
            this.changeState('confirmSignIn', user);
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.changeState('requireNewPassword', user);
          } else if (user.challengeName === 'MFA_SETUP') {
            this.changeState('TOTPSetup', user);
          } else {
            if (this.props.nested) {
              return this.props.history.push('/');
            }
            this.changeState('signedIn', user);
          }
        })
        .catch(err => {
          // console.log('err', err);
          if (err.code === 'UserNotConfirmedException') {
            this.changeState('confirmSignUp');
          } else if (err.code === 'PasswordResetRequiredException') {
            this.changeState('requireNewPassword');
          } else {
            this.onError(err);
          }
        });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          ...validations,
        },
      });
    }

    event.preventDefault();
  };

  togglePassword = () => {
    this.setState({ password: !this.state.password });
  };

  handleUsernameChange = event => {
    const { updateByPropertyName } = this.props;
    this.setState(updateByPropertyName('username', event.target.value));
  };

  handlePasswordChange = event => {
    const { updateByPropertyName } = this.props;
    this.setState(updateByPropertyName('password', event.target.value));
  };

  handleOnFocus = event => {
    const property = event.currentTarget.dataset.property;

    this.setState({
      error: {
        ...this.state.error,
        [`${property}`]: null,
      },
    });
  };

  render() {
    const { isSignup } = this.props;
    const { error } = this.state;
    return (
      <div
        className={classNames('content-wrapper', {
          'hidden-element': isSignup,
        })}
      >
        <h1 className="auth-content-title title is-1">Sign In</h1>
        {this.props.nested ? (
          <h4 className="auth-content-subtitle subtitle is-4">
            If you already have an account, just sign in. We've missed you!
          </h4>
        ) : null}
        {error.general ? <Error message={error.general} /> : null}
        <form className="form" noValidate="">
          <fieldset className="form-fieldset">
            <Input
              className="with--margin"
              error={error.username && error.username[0]}
              type="text"
              property="username"
              placeholder="Username"
              onChange={this.handleUsernameChange}
              onFocus={this.handleOnFocus}
            />
            <div className="sign-up-pass-wrapper">
              <Input
                error={error.password && error.password[0]}
                type={this.state.password ? 'password' : 'text'}
                property="password"
                placeholder="Password"
                onChange={this.handlePasswordChange}
                onFocus={this.handleOnFocus}
              />

              <div onClick={this.togglePassword} className="sign-up-pass-eye" />
            </div>
          </fieldset>
          <Button onClick={this.onSubmit} label="Sign In" />
        </form>
      </div>
    );
  }
}

export default { LoginForm };
