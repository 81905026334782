import { connect } from 'react-redux';
import { attemptGetPublicShelf } from '_thunks/publicShelves';
import { Container } from './container';

const mapDispatchToProps = dispatch => ({
  getShelf: id => dispatch(attemptGetPublicShelf(id)),
});

const mapStateToProps = (state, props) => ({
  id: props.match.params.id,
});

export const PublicShelfPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);

export default { PublicShelfPage };
