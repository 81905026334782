import { UNMOUNT_UI_STATE } from './consts';

export function unmountUI(key) {
  return {
    type: UNMOUNT_UI_STATE,
    payload: {
      key,
    },
  };
}

export default { unmountUI };
