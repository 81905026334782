export const UPDATE_TILE_TEMPLATE_TABLE_STATE =
  'UPDATE_TILE_TEMPLATE_TABLE_STATE';

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';

export function setTileTemplatesTableState(state) {
  return {
    type: UPDATE_TILE_TEMPLATE_TABLE_STATE,
    state,
  };
}

export function setFormData(key, payload) {
  return {
    type: UPDATE_FORM_DATA,
    key,
    payload,
  };
}
