import { normalizeTiles } from '../normalize/tiles';

export const SET_TILE = 'SET_TILE';
export const SET_TILES = 'SET_TILES';
export const ADD_TILE = 'ADD_TILE';
export const UPDATE_TILE = 'UPDATE_TILE';
export const REMOVE_TILE = 'REMOVE_TILE';
export const ADD_BUCKET_TILE = 'ADD_BUCKET_TILE';

export function setTiles(tiles) {
  const { fields, cards, packs } = normalizeTiles(tiles);
  return {
    type: SET_TILES,
    ...normalizeTiles(tiles),
    fieldsAll: fields ? Object.keys(fields) : [],
    cardsAll: cards ? Object.keys(cards) : [],
    packsAll: packs ? Object.keys(packs) : [],
  };
}

export const addTile = tile => ({
  type: ADD_TILE,
  id: tile.id,
  tile,
});

export const addBucketTile = (id, tile) => ({
  type: ADD_BUCKET_TILE,
  id,
  tile,
});

export const updateTile = tile => ({
  type: UPDATE_TILE,
  tile,
  id: tile.id,
});

export const removeTile = (id, packTemplateIds) => ({
  type: REMOVE_TILE,
  id,
  packTemplateIds,
});
