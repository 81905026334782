import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { loadBoards, loadTop6 } from '_store/actions/cardBoard';
import { BoardContainer } from '_components';
// import SimpleBar from 'simplebar-react';
// import SimpleBarDef from 'simplebar';
import DeleteTop6DropZone from './deleteTop6DropZone';

class _DeckBoards extends Component {
  componentWillMount() {
    const { parent, data, dispatch } = this.props;
    dispatch(loadBoards(parent, data));
    dispatch(loadTop6(parent, data));
  }

  componentDidMount() {
    // this.simpleBar = new SimpleBarDef(document.getElementById('db'));

    this.recalculateScroll();
  }

  recalculateScroll = () => {
    if (this.simpleBar) {
      setTimeout(() => {
        this.simpleBar.recalculate();
      }, 2000);
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { parent, data, dispatch } = nextProps;
    if (parent !== this.props.parent) {
      dispatch(loadBoards(parent, data));
      dispatch(loadTop6(parent, data));
      this.recalculateScroll();
    }
  }

  render() {
    const {
      parent,
      tileTemplateId,
      boards = [],
      tileId,
      bgColor,
      nounColor,
      top6Title,
      isTop6,
      dispatch
    } = this.props;

    return (
      <DeleteTop6DropZone
        packId={parent}
        tileId={tileId}
        top6Title={top6Title}
        tileTemplateId={tileTemplateId}
        dispatch={dispatch}
      >
      <div
        forcevisible="x"
        id={'db'}
        className={classNames('deck-boards', {'top-6': !!isTop6})}
        data-parent={parent}
        ref={this.simpleBar}
      >
        {boards.map((card, i) =>
          <BoardContainer
            isTop6={isTop6}
            key={card.id}
            packId={parent}
            sequence={i}
            isLast={boards.length - 1 === i}
            data={card}
            numPositions={3}
            bgColor={bgColor}
            nounColor={nounColor}
            tileId={tileId}
            tileTemplateId={tileTemplateId}
          />,
        )}
      </div>
      </DeleteTop6DropZone>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    boards: state.boards[props.parent],
  };
};

export const DeckBoards = connect(mapStateToProps)(_DeckBoards);

export default { DeckBoards };
