import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router } from './router';

export class RouterContainer extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {
    const { store, history } = this.props;
    return <Router store={store} history={history} />;
  }
}

export default { RouterContainer };
