export const MASS_UPDATE_UI_STATE = 'MASS_UPDATE_UI_STATE';
export const UPDATE_UI_STATE = 'UPDATE_UI_STATE';
export const SET_DEFAULT_UI_STATE = 'SET_DEFAULT_UI_STATE';

export const MOUNT_UI_STATE = 'MOUNT_UI_STATE';
export const UNMOUNT_UI_STATE = 'UNMOUNT_UI_STATE';

export default {
  MASS_UPDATE_UI_STATE,
  UPDATE_UI_STATE,
  SET_DEFAULT_UI_STATE,
  MOUNT_UI_STATE,
  UNMOUNT_UI_STATE,
};
