import React, { Component } from 'react';
import classnames from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput, ColourPicker } from '_components';

export class SuggestionForm extends Component {
  componentDidMount() {}

  handleFieldChange = (key, value) => {
    const values = {
      ...this.props.data,
      [key]: value === '' ? null : value,
    };

    this.props.updateFormData(values);
  };

  handleColourChange = (key, { hex: value }) => {
    const values = {
      ...this.props.data,
      [key]: value,
    };
    this.props.updateFormData(values);
  };

  render() {
    const { noun, hexColour, nounHexColour } = this.props.data;
    return (
      <div className={classnames('suggestion-form', {})}>
        <div className="modal-header">
          <FontAwesomeIcon
            onClick={this.props.handleClose}
            icon={faTimes}
            size={'1x'}
            className="modal-header-close"
          />
        </div>
        <div className="modal-body">
          <div className="form-group form-group-input">
            <div className="form-label">Noun</div>
            <TextInput
              id="noun"
              key="noun"
              onChange={this.handleFieldChange}
              value={noun}
              placeholder="Enter Noun"
            />
          </div>

          <div className="form-group form-group-color-picker">
            <div className="form-label">Background</div>
            <ColourPicker
              id="hexColour"
              onChange={this.handleColourChange}
              value={hexColour}
            />
          </div>
          <div className="form-group form-group-color-picker">
            <div className="form-label">Text</div>
            <ColourPicker
              id="nounHexColour"
              onChange={this.handleColourChange}
              value={nounHexColour}
            />
          </div>
          <div className="buttons">
            <div
              className="button button-light"
              onClick={this.props.handleClose}
            >
              Cancel
            </div>
            <div className="button" onClick={this.props.createSuggestion}>
              Create
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default { SuggestionForm };
