//attemptMoveBucketTile
import { moveCard } from '../actions/cardBoard';
import { updateCard, createCard } from '../../api/cards';
import { attemptCreatePack } from '../thunks/packs';
import { updateCards } from '../actions/cards';
import { to } from 'shared-code';

export const attemptMoveCard = (
  packId,
  oldSequence,
  newSequence,
  newCardPosition,
  props,
  item,
) => async (dispatch, getState) => {
  dispatch(moveCard(packId, oldSequence, newSequence, newCardPosition));
  const byId = getState().cards.byId;
  const item1 = byId[item.cardTemplateId];
  const item2 = byId[props.boardId];

  let pack = getState().packs.byId[packId];
  let updatedPassiveItem;

  const tileId = props.tileId;
  const tileTemplateId = props.tileTemplateId;
  let updatedCards = [];
  // // subscribe to pack
  if (!pack || pack.status === 'UNSUBSCRIBED') {
    pack = await dispatch(
      attemptCreatePack(tileId, {
        id: pack && pack.id,
        tileTemplateId,
        tileId,
        packTemplateId: packId,
        status: 'SUBSCRIBED',
      }),
    );
  }

  const updatedIncomingItem = {
    ...item1,
    packId: pack && pack.id,
    packTemplateId: props.packId,
    cardTemplateId: item.cardTemplateId,
    tileId,
    tileTemplateId,
    sequence: newSequence + 1,
    priority: newCardPosition[1] + 1,
  };
  if (props.boardId !== item.boardId) {
    updatedPassiveItem = {
      ...props.card,
      ...item2,
      id: (item2 && item2.id) || null,
      packTemplateId: props.packId,
      tileId,
      tileTemplateId,
      cardTemplateId: props.boardId,
      packId: pack && pack.id,
      sequence: oldSequence + 1,
      // priority: oldCardPosition[1] + 1, for swap action
    };
  }

  let apiAction = updatedIncomingItem.id ? updateCard : createCard;
  const [, cardTemplate1] = await to(apiAction(tileId, updatedIncomingItem));

  updatedCards.push(cardTemplate1);
  if (updatedPassiveItem) {
    apiAction = updatedPassiveItem.id ? updateCard : createCard;

    const [, cardTemplate2] = await to(apiAction(tileId, updatedPassiveItem));

    updatedCards.push(cardTemplate2);
  }
  // dispatch(
  //   moveCard(props.packId, item.sequence, props.sequence, [props.x, props.y]),
  // );

  dispatch(updateCards(props.packId, updatedCards));
};
