import update from 'immutability-helper';

import { UPDATE_FIELD, ADD_CARD_FIELDS } from '_actions/fields';
import { SET_TILE } from '_actions/tiles';
import { SET_PACK } from '_actions/packs';

function updateFields(state, action) {
  let newState = state;

  action.fieldAll.forEach(id => {
    const field = action.fields[id];
    newState = update(newState, {
      [id]: { $set: field },
    });
  });
  return newState;
}

function updateIds(state, ids = []) {
  if (ids.length === 0) return state;
  return [...new Set(state.concat(ids))];
}

export default function fields(state = { all: [], byId: {} }, action) {
  switch (action.type) {
    case SET_TILE:
    case SET_PACK:
      return update(state, {
        all: { $set: action.fieldsAll },
        byId: { $set: action.fields || {} },
      });
    case ADD_CARD_FIELDS:
      return update(state, {
        all: { $set: updateIds(state.all, action.fieldAll) },
        byId: { $set: updateFields(state.byId, action) },
      });
    case UPDATE_FIELD:
      return update(state, {
        byId: { $set: updateFields(state.byId, action) },
      });
    default:
      return state;
  }
}
