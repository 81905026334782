import { Map } from 'immutable';
import invariant from 'invariant';
import {
  UPDATE_UI_STATE,
  MASS_UPDATE_UI_STATE,
  SET_DEFAULT_UI_STATE,
  MOUNT_UI_STATE,
  UNMOUNT_UI_STATE,
} from './actions/consts';

export const defaultState = new Map({});

export function reducer(state = defaultState, action) {
  let key = action.payload && (action.payload.key || []);

  if (!Array.isArray(key)) {
    key = [key];
  }

  switch (action.type) {
    case UPDATE_UI_STATE:
      const { name, value } = action.payload;
      if (typeof value === 'function') {
        state = state.updateIn(key.concat(name), value);
      } else {
        state = state.setIn(key.concat(name), value);
      }
      break;

    case MASS_UPDATE_UI_STATE:
      const { uiVars, transforms } = action.payload;
      state = state.withMutations(s => {
        Object.keys(transforms).forEach(k => {
          const path = uiVars[k];
          invariant(
            path,
            `Couldn't find variable ${k} within the component's UI state ` +
              `context. Define ${k} before using it in the @ui decorator`,
          );

          s.setIn(path.concat(k), transforms[k]);
        });
      });
      break;

    case SET_DEFAULT_UI_STATE:
      state = state.setIn(key, new Map(action.payload.value));
      break;

    case MOUNT_UI_STATE:
      const { defaults } = action.payload;
      state = state.withMutations(s => {
        s.setIn(key, new Map(defaults));
        return s;
      });
      break;

    case UNMOUNT_UI_STATE:
      state = state.withMutations(s => {
        s.deleteIn(key);
      });
      break;
    default:
      break;
  }

  return state;
}

export default { reducer };
