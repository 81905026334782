/* eslint-disable no-undef */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

class SearchBox extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setInputValue(this.props.value);
    }
  }

  getInputValue = () => {
    return this.input && this.input.value;
  };

  setInputValue = val => {
    // Generally mutating DOM is a bad idea in React components,
    // but doing this for a single uncontrolled field is less fuss
    // than making it controlled and maintaining a state for it.
    if (this.input) this.input.value = val;
  };

  handleKeyUp = e => {
    if (e.keyCode === 13) {
      this.handleGoClick();
    }
  };

  handleGoClick = () => {
    if (this.props.hidden || this.props.view === 'PEOPLE') {
      this.props.activateSearch(this.getInputValue());
    }
    this.props.onChange(this.getInputValue());
    // this.setState({});
  };

  onClose = () => {
    this.setInputValue('');
    this.props.onChange('');
    this.props.onClose();
  };

  componentWillUnmount() {
    this.props.onChange('');
  }
  onSwitch = e => {
    const { index } = e.currentTarget.dataset;
    if (index === this.props.view) {
      this.setInputValue('');
    }

    return this.props.onSwitch(e);
  };

  render() {
    return (
      <div
        className={classnames('rhs-search-header-box search-content', {
          hide: !this.props.showBar,
        })}
      >
        {(this.props.hidden && !this.props.view) || !this.props.hidden
          ? <div className="search-box control has-icons-left">
              <input
                type="text"
                placeholder="Search users or tiles"
                className="input"
                ref={input => (this.input = input)}
                defaultValue={this.props.value}
                onKeyUp={this.handleKeyUp}
              />
              {this.props.isFetching &&
              this.getInputValue() &&
              this.getInputValue() !== ''
                ? <CircularProgress size={20} />
                : null}
              <span className="icon is-small is-left">
                <i className="fas fa-search" />
              </span>
            </div>
          : null}
        <div
          className={classnames('search-switch', {
            active: this.props.view === 'PEOPLE',
          })}
          data-index={'PEOPLE'}
          onClick={this.onSwitch}
          title="People"
        >
          <i className="fas fa-users" />
        </div>
        <div
          className={classnames('search-switch', {
            active: this.props.view === 'TEMPLATES',
          })}
          data-index={'TEMPLATES'}
          onClick={this.onSwitch}
          title="Tiles"
        >
          <img
            src={require('../../assets/images/tile.svg')}
            height="24"
            alt=""
          />
        </div>
        {!this.props.hidden
          ? <div
              className="search-close"
              onClick={this.onClose}
              title="Hide Results"
            >
              <i className="icon fas fa-eye-slash" />
            </div>
          : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    onChange: query => props.search(query),
  };
}

export default connect(null, mapDispatchToProps)(SearchBox);
