import { connect } from 'react-redux';
import Container from './modal';

const mapStateToProps = () => {
  return {};
};

const mapStateToDispatch = () => ({});

export const Modal = connect(
  mapStateToProps,
  mapStateToDispatch,
)(Container);
export default { Modal };
