import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { avatar as defaultAvatar } from '_assets';

export function Avatar({ size, user }) {
  return (
    <img
      src={(user && user.imgUrl) || defaultAvatar}
      alt=""
      className={classNames('avatar', { [`avatar-${size}`]: size })}
    />
  );
}

Avatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  src: PropTypes.string,
};

Avatar.defaultAvatar = {
  size: null,
  src: null,
  user: {},
};

export default { Avatar };
