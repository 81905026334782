import {
  getShelves,
  getShelf,
  editShelfTilePositions,
  getShelfTilePositions,
  publishShelf,
  unpublishShelf,
  uploadShelfImage,
  editShelf,
} from '_api/shelves';
import { attemptDeleteTile } from '_thunks/tiles';
import { getNewTilePositions, updateTilePositions } from '_thunks/utils';
import {
  setShelves,
  updateShelfTilePositions,
  setShelfTilePositions,
  updateShelf,
} from '_actions/shelves';
import { updateTileViewPos } from '_actions/tileViews';
import { removePublicShelf } from '_actions/publicShelves';

import { dispatchError } from '_utils/api';

export const attemptGetShelves = () => dispatch =>
  getShelves()
    .then(data => {
      dispatch(setShelves(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptGetShelfTilePositions = id => async dispatch => {
  const tilePositionsResponse = await getShelfTilePositions(id);
  if (tilePositionsResponse.error) dispatchError(dispatch);
  else {
    dispatch(
      setShelfTilePositions({
        id,
        tilePositions: tilePositionsResponse,
      }),
    );
  }
};
export const attemptGetShelf = () => dispatch =>
  getShelf()
    .then(data => {
      dispatch(setShelves([data]));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptMoveShelfTile = ({
  source,
  target,
  tilePositionIds,
}) => async (dispatch, getState) => {
  const ids = getState().buckets[0].tilePositionIds;

  const results = getNewTilePositions(source, target, ids);
  dispatch(
    updateTileViewPos({
      results,

      status: true,
    }),
  );
  const resp = await dispatch(updateTilePositions(results));

  dispatch(
    updateTileViewPos({
      status: false,
    }),
  );
  return resp;
};

export const attemptUpdateShelfTilePositions = ({
  id,
  tilePositions,
}) => dispatch =>
  editShelfTilePositions({
    id,
    tilePositions,
  })
    .then(data => {
      dispatch(updateShelfTilePositions(id, data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptRemoveTileFromShelf = source => async dispatch => {
  const positions = [
    {
      id: null,
      position: source.item.position,
    },
  ];

  const tilePositions = [
    {
      id: source.item.id,
      position: null,
    },
  ];

  dispatch(
    updateTileViewPos({
      results: { source: { positions, type: source.listType }, target: {} },
      status: true,
    }),
  );

  await dispatch(
    attemptUpdateShelfTilePositions({
      id: source.listId,
      tilePositions,
    }),
  );
  dispatch(
    updateTileViewPos({
      status: false,
    }),
  );
};

export const attemptDeleteShelfTile = ({
  source,
  shelfOnly = false,
}) => dispatch => {
  if (shelfOnly) {
    return dispatch(attemptRemoveTileFromShelf(source));
  }
  return dispatch(attemptDeleteTile({ id: source.item.id }))
    .then(data => {
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptUpdateShelf = shelf => dispatch =>
  editShelf(shelf)
    .then(data => {
      dispatch(updateShelf(data));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptPublishShelf = id => dispatch =>
  publishShelf(id)
    .then(res => {
      dispatch(updateShelf(res));
      return { ...res };
    })
    .catch(dispatchError(dispatch));

export const attemptPublishShelfWithImage = ({ id, image }) => dispatch =>
  uploadShelfImage({ id, image })
    .then(result => {
      return publishShelf(id, result.version).then(res => {
        dispatch(updateShelf(res));
        return { ...res, ...result };
      });
    })
    .catch(dispatchError(dispatch));

export const attemptUnpublishShelf = (id, props) => dispatch =>
  unpublishShelf(id)
    .then(res => {
      dispatch(updateShelf(res));
      dispatch(removePublicShelf(id));
      return res;
    })
    .catch(dispatchError(dispatch));
