import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import classNames from 'classnames';
import ItemTypes from './itemTypes';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Card } from 'shared-code';
import { connect } from 'react-redux';

import { attemptMoveCard } from '_store/thunks/cards';

class BoardCard extends Component {
  state = {};
  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const movePopupAway = this.props.isDragging;
    if (this.state.movePopupAway !== movePopupAway) {
      setTimeout(() => {
        this.setState({ movePopupAway });
      }, 1);
    }
  }

  cardClick = () => {
    const {
      priorityPosition,
      dispatch,
      sequence,
      data: { packTemplateId },
      tileTemplateId,
      tileId,
      boardId,
    } = this.props;
    if (priorityPosition === 0) {
      dispatch(
        attemptMoveCard(
          packTemplateId,
          sequence,
          sequence,
          [0, 1],
          {
            boardId,
            ...this.props.data,
            tileTemplateId,
            tileId,
            packId: packTemplateId,
          },
          {
            boardId,
            tileTemplateId,
            tileId,
            ...this.props.data,
            packId: packTemplateId,
          },
        ),
      );
    }
  };

  render() {
    const {
      connectDragSource,
      isDragging,
      data,
      priorityPosition,
      bgColor,
      nounColor,
    } = this.props;

    return connectDragSource(
      <div
        className={classNames(
          'card-container',
          { 'is-dragging': isDragging },
          { 'change-height': this.state.movePopupAway },
        )}
        onClick={this.cardClick}
      >
        <Card
          id={data.cardTemplateId}
          data={{ ...data, imgurl: data.imgurlCustom }}
          isPriority={priorityPosition === 0}
          isTruncated={priorityPosition === 0 || priorityPosition === 2}
          bgColor={bgColor}
          nounColor={nounColor}
        />
      </div>,
    );
  }
}

const draggedItem = DragSource(
  ItemTypes.CARD,
  {
    beginDrag(props) {
      return {
        boardId: props.data.cardTemplateId,
        ...props.data,
        imgurl: props.data.imgurlCustom,
        sequence: props.sequence,
        bgColor: props.bgColor,
        nounColor: props.nounColor,
        itemType: 'CARD',
      };
    },
    endDrag(props) {},
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
)(BoardCard);

function mapStateToProps(state, props) {
  return {
    data: {
      ...props.data,
      ...state.cards.byId[props.data.cardTemplateId],
    },
  };
}

export default connect(mapStateToProps)(draggedItem);
