import { PublicShelfPage } from '_pages';

export default [
  {
    exact: true,
    path: '/:id/:timestamp?',
    tab: 'shelf',
    component: PublicShelfPage,
    title: 'Shelf',
  },
];
