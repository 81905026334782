import { normalizeTilePositions } from '_normalize/tilePositions';
import { normalizePublicShelves } from '_normalize/publicShelves';

export const SET_PUBLIC_SHELVES = 'SET_PUBLIC_SHELVES';
export const SET_PUBLIC_SHELF_DATA = 'SET_PUBLIC_SHELF_DATA';
export const PUBLIC_SHELVES_REQUEST = 'PUBLIC_SHELVES_REQUEST';
export const PUBLIC_SHELVES_SUCCESS = 'PUBLIC_SHELVES_SUCCESS';
export const PUBLIC_SHELVES_FAILURE = 'PUBLIC_SHELVES_FAILURE';
export const PUBLIC_SHELVES_RESET = 'PUBLIC_SHELVES_RESET';
export const UPDATE_PUBLIC_SHELVES_SEARCH = 'UPDATE_PUBLIC_SHELVES_SEARCH';
export const REMOVE_PUBLIC_SHELF = 'REMOVE_PUBLIC_SHELF';

export function setPublicShelves(shelves) {
  shelves = shelves.map(s => {
    return {
      ...s,
      ...normalizeTilePositions(s.tiles || []),
    };
  });
  return {
    type: SET_PUBLIC_SHELVES,
    ...normalizePublicShelves(shelves),
  };
}

export const removePublicShelf = id => ({
  type: REMOVE_PUBLIC_SHELF,
  id,
});

export const setPublicShelfTiles = ({ id, tiles }) => ({
  type: SET_PUBLIC_SHELVES,
  id,
  data: {
    ...normalizeTilePositions(tiles || []),
  },
});

export function updateSearchTerm(value = '') {
  return { type: UPDATE_PUBLIC_SHELVES_SEARCH, value };
}

export function fetchPublicShelvesStatus(status, value = '', result) {
  return {
    type: `PUBLIC_SHELVES_${status}`,
    value,
    payload: {
      value,
      result,
    },
  };
}

export const resetPublicShelves = () => ({
  type: PUBLIC_SHELVES_RESET,
  value: '',
  payload: {
    value: '',
    result: [],
  },
});
