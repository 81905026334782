import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popper } from 'react-popper';
import { DropdownContext } from './dropdownContext';

export class DropdownMenu extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    modifiers: PropTypes.object,
    className: PropTypes.string,
    persist: PropTypes.bool,
  };

  static contextType = DropdownContext;

  render() {
    const { className, modifiers, persist, ...attrs } = this.props;
    const classes = classNames(className, 'dropdown-menu', {
      show: this.context.isOpen,
    });

    if (persist || this.context.isOpen) {
      return (
        <Popper placement="below-center" modifiers={modifiers}>
          {({ ref, style, placement }) => (
            <div
              tabIndex="-1"
              role="menu"
              ref={ref}
              style={style}
              {...attrs}
              aria-hidden={!this.context.isOpen}
              className={classes}
              x-placement={placement}
            />
          )}
        </Popper>
      );
    }

    return (
      <div
        tabIndex="-1"
        role="menu"
        {...attrs}
        aria-hidden={!this.context.isOpen}
        className={classes}
        x-placement="below-center"
      />
    );
  }
}

export default { DropdownMenu };
