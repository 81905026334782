import React, { Component } from 'react';
import classnames from 'classnames';
import { TileContainer } from './container';

export class DisplayTile extends Component {
  componentDidMount() {}

  render() {
    const { customClass } = this.props;
    return (
      <div
        className={classnames(
          'tile display-tile',
          {
            'tile-placeholder': this.props.placeholder,
          },
          customClass,
        )}
      >
        <TileContainer listType="SEARCH" {...this.props} />
      </div>
    );
  }
}

export default { DisplayTile };
