import update from 'immutability-helper';
import * as R from 'ramda';
import { ADD_COLLECTION_TILE_TEMPLATE } from '_actions/tileTemplates';
import {
  SET_COLLECTIONS,
  UPDATE_COLLECTION,
  ADD_COLLECTION,
  REMOVE_COLLECTION,
  SET_COLLECTION_DATA,
  REMOVE_COLLECTION_TILE_TEMPLATE,
} from '_actions/collections';

export function collection(state = {}, action) {
  switch (action.type) {
    case ADD_COLLECTION:
      return update(state, { $set: action.collection });
    case UPDATE_COLLECTION:
      return update(state, { $merge: action.collection });
    default:
      return state;
  }
}

export function updatedCollectionData(state, index, data) {
  return update(state, {
    [index]: {
      tileTemplates: { $set: data.tileTemplates },
    },
  });
}

export function addTileTemplate(state, index, tileTemplate) {
  if (!index || index === -1) return state;
  const action = state[index].tileTemplates ? '$push' : '$set';
  return update(state, {
    [index]: {
      tileTemplates: { [action]: [tileTemplate] },
    },
  });
}

export function removeTileTemplate(state, index, tileTemplateId) {
  return update(state, {
    [index]: {
      tileTemplates: {
        $set: state[index].tileTemplates.filter(x => x.id !== tileTemplateId),
      },
    },
  });
}

export default function collections(state = [], action) {
  const index = R.findIndex(R.propEq('id', action.id), state);
  const updatedAtIndex = {
    $splice: [[index, 1, collection(state[index], action)]],
  };

  const removeAtIndex = {
    $splice: [[index, 1]],
  };

  switch (action.type) {
    case SET_COLLECTIONS:
      return update(state, { $set: action.collections });
    case ADD_COLLECTION:
      return update(state, { $push: [collection(undefined, action)] });
    case UPDATE_COLLECTION:
      return update(state, updatedAtIndex);
    case REMOVE_COLLECTION:
      return update(state, removeAtIndex);
    case SET_COLLECTION_DATA:
      return updatedCollectionData(state, index, action.data);
    case ADD_COLLECTION_TILE_TEMPLATE:
      return addTileTemplate(state, index, action.tileTemplate);
    case REMOVE_COLLECTION_TILE_TEMPLATE:
      return removeTileTemplate(state, index, action.tileTemplateId);
    default:
      return state;
  }
}
