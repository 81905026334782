export const ADD_FIELD = 'ADD_FIELD';
export const REMOVE_FIELD = 'REMOVE_FIELD';
export const SET_FIELD = 'SET_FIELD';
export const SET_FIELDS = 'SET_FIELDS';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const ADD_CARD_FIELDS = 'ADD_CARD_FIELDS';
export const REMOVE_CARD_FIELDS = 'REMOVE_CARD_FIELDS';

export const addField = (tileId, field) => ({
  type: ADD_FIELD,
  id: field.id,
  tileId,
  field,
});

export const removeField = (tileId, field) => ({
  type: REMOVE_FIELD,
  id: field.id,
  tileId,
});
