import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export function SwitchContent({ isSignup, onSwitch }) {
  return (
    <section
      className={classNames('auth-image-container', 'image-container_login', {
        in: isSignup,
      })}
    >
      <div className="container-wrapper">
        <div className="animate-text">
          <div
            className={classNames('container-text__wrapper', {
              'hidden-element': isSignup,
            })}
          >
            <h1 className="image-container-title title is-2">New Here?</h1>
            <div className="image-container-text">
              Create a free account and discover your tribe today!
            </div>
          </div>
          <div
            className={classNames('container-text__wrapper', {
              'hidden-element': !isSignup,
            })}
          >
            <h1 className="image-container-title title is-2">
              Already a member?
            </h1>
            <div className="image-container-text subtitle is-4">
              If you already have an account, just sign in. We've missed you!
            </div>
          </div>
        </div>
        <button onClick={onSwitch} className="image-container-btn button">
          <span className={classNames({ 'hidden-element': isSignup })}>
            Sign Up
          </span>
          <span className={classNames({ 'hidden-element': !isSignup })}>
            Sign In
          </span>
        </button>
      </div>
    </section>
  );
}

SwitchContent.propTypes = {
  isSignup: PropTypes.bool.isRequired,
  onSwitch: PropTypes.func.isRequired,
};

export default { SwitchContent };
