import { schema } from 'normalizr';

import { normalize } from 'normalizr';
import { parseNormalizedData } from '_utils/normalize';

export const tilePosition = new schema.Entity(
  'tilePositions',
  {},
  { idAttribute: 'position' },
);

export const normalizeTilePositions = (data = []) => {
  if (!data || !Array.isArray(data)) {
    return {};
  }
  data = data
    .filter(x => x.position !== -1 && x.position !== 0)
    .sort((a, b) => a.position - b.position);

  const normalizedData = normalize(data, [tilePosition]);

  const { tilePositionIds, tilePositions } = parseNormalizedData(
    normalizedData,
    'tilePosition',
  );

  return { tilePositionIds, tilePositions };
};
