import { schema } from 'normalizr';

import { normalize } from 'normalizr';
import { parseNormalizedData } from '_utils/normalize';

export const top6Position = new schema.Entity(
  'top6Positions',
  {},
  { idAttribute: 'top6Position' },
);

export const normalizeTop6Positions = (data = []) => {
  if (!data || !Array.isArray(data)) {
    return {};
  }
  data = [...data]
    .filter(x => x.top6Position !== -1 && x.top6Position !== 0)
    .sort((a, b) => a.top6Position - b.top6Position);

  const normalizedData = normalize(data, [top6Position]);

  const { top6PositionIds, top6Positions } = parseNormalizedData(
    normalizedData,
    'top6Position',
  );

  return { top6PositionIds, top6Positions };
};
