import validate from 'validate.js';

export const parseNewImagePaths = ({
  type,
  editImage,
  imgResponse,
  entity,
}) => {
  if (!imgResponse) return {};

  const {
    imageHasChanged,
    isImgSelected,
    isImgUploaded,
    imgurlReference,
  } = editImage;

  const { categoryImage = {}, imgurlCustom, cropImgInfo } = imgResponse;

  let cropped;
  let reference;

  const newImagePaths = {};

  if (isImgUploaded) {
    // image is uploaded, use public category images (if exist)
    cropped = imgurlCustom;
    reference = imgResponse.imgurlReference;
  } else if (isImgSelected) {
    // image has been selected from an existing category image
    reference = imgurlReference;

    if (imageHasChanged) {
      // use new cropped image
      cropped = imgurlCustom;
    } else {
      // use category image
      cropped = categoryImage.imgurl;
    }
  } else if (imageHasChanged) {
    // use new cropped image
    cropped = imgurlCustom;
  }

  const validationErrors =
    validate(
      { cropped, reference },
      {
        cropped: { url: true, presence: true },
        reference: { url: true, presence: true },
      },
    ) || {};

  if (!validationErrors.cropped)
    newImagePaths[
      type === 'tileTemplate' ? 'imgurlDefault' : 'imgurlCustom'
    ] = cropped;
  if (!validationErrors.reference) newImagePaths.imgurlReference = reference;
  if (cropImgInfo !== undefined) newImagePaths.cropImgInfo = cropImgInfo;

  return newImagePaths;
};

export default { parseNewImagePaths };
