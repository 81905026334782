import React, { Component } from 'react';
import { Avatar } from 'shared-code';
export class ShelfUser extends Component {
  render() {
    return (
      <div className="shelf-user">
        <div className="shelf-avatar">
            <Avatar user={{ imgUrl: this.props.shelf.imgUrl }} isIcon />
        </div>
        <div className="user-name tile-text">
          {this.props.shelf.userName}
        </div>
      </div>
    );
  }
}
