import { combineReducers } from 'redux';
import { reducer as alerts } from 'react-notification-system-redux';
import collections from './collections';
import collectionTypes from './collectionTypes';
import user from './user';
import users from './users';
import shelves from './shelves';
import publicShelves from './publicShelves';
import buckets from './buckets';
import tiles from './tiles';
import packs from './packs';
import cards from './cards';
import fields from './fields';
import tileTemplates from './tileTemplates';
import pagination from './pagination';
import suggestion from './suggestion';
import tileViews from './tileViews';
import boards from './boards';
import form from './form';
import top6 from './top6';
import { reducer as ui } from '../../decorators/ui';
import { editImage, categories } from 'shared-code';

const rootReducer = combineReducers({
  alerts: process.env.SIXTILES_ENV === 'development' ? alerts : null,
  collections,
  tileTemplates,
  categories,
  shelves,
  publicShelves,
  buckets,
  tiles,
  user,
  users,
  collectionTypes,
  pagination,
  suggestion,
  packs,
  cards,
  fields,
  form,
  tileViews,
  boards,
  ui,
  editImage,
  top6,
});

export default rootReducer;
