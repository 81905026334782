import update from 'immutability-helper';

import { UPDATE_FORM_DATA } from '_actions/views';
import { UPDATE_EDIT_IMAGE } from 'shared-code';
const DEFAULT_DATA = {
  tile: {
    name: null,
    imgurl: null,
    hexColour: '#d71f30',
    nounHexColour: '#ffffff',
    textHexColour: undefined,
    text: null,
    typeId: null,
    textShow: true,
    nounShow: true,
  },
};

const DEFAULT_STATUS = {
  isSelected: false,
  isActive: false, // if adding or editing
  isAdding: false,
  isEditing: false,
};

const DEFAULT_INITIAL_STATE = {
  status: DEFAULT_STATUS,
  isValid: false,
  validation: {},
  validated: false,
  isDirty: false,
};

const initialState = {
  tile: {
    data: {
      ...DEFAULT_DATA['tile'],
    },
    ...DEFAULT_INITIAL_STATE,
  },
};

function resetData(state, action) {
  const newState = Object.assign({}, initialState[action.key]);
  if (action.payload.activeItem) {
    newState.data = {
      ...newState.data,
      ...action.payload.activeItem,
    };
    newState.isValid = true;
    newState.status = {
      ...state[action.key].status,
      ...(action.payload.status || {}),
    };
  }
  return update(state, {
    [action.key]: {
      $set: newState,
    },
  });
}

function updateChildren(state, action) {
  return update(state, {
    [action.key]: {
      isDirty: { $set: true },
      data: {
        $set: {
          ...state[action.key].data,
          ...action.payload,
        },
      },
    },
  });
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FORM_DATA:
      if (action.payload.reset) {
        return resetData(state, action);
      }
      if (action.payload.children) {
        return updateChildren(state, action);
      }
      return update(state, {
        [action.key]: {
          $set: {
            ...state[action.key],
            ...action.payload,
          },
        },
      });
    case UPDATE_EDIT_IMAGE:
      return update(state, {
        [action.key]: {
          isDirty: {
            $set: true,
          },
        },
      });
    case '@@router/LOCATION_CHANGE':
      return initialState;
    default:
      return state;
  }
}
