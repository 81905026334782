import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { InvalidMessagePopover } from '_components';

export function TextInput({
  onChange,
  isValid,
  onBlur,
  onFocus,
  value,
  placeholder,
  id,
  validated,
  showError,
  validation,
}) {
  const classes = classNames({
    'form-input': true,
    // 'is-success': validated && value && isValid,
    'is-danger': validated && !isValid,
    placeholder: !value,
  });

  function _handleOnChange(event) {
    const { value } = event.currentTarget;
    onChange(id, value);
  }

  return (
    <React.Fragment>
      <input
        className={classes}
        id={id}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={_handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {/* {showError ? (
        <InvalidMessagePopover validation={validated && validation} />
      ) : null} */}
    </React.Fragment>
  );
}

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  validated: PropTypes.bool,
  showError: PropTypes.bool,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  onBlur: undefined,
  onFocus: undefined,
  isValid: false,
  validated: false,
  showError: true,
};

export default { TextInput };
