// Fetches a page of stargazers for a particular repo.
// Bails out if page is cached and user didn't specifically request next page.
export const loadPage = (
  defaultNextPageUrl,
  entityKey,
  value,
  nextPage,
  fetchAction,
) => (dispatch, getState) => {
  const { nextPageUrl = defaultNextPageUrl, pageCount = 0 } =
    getState().pagination[entityKey][value] || {};

  if (pageCount > 0 && !nextPage) {
    return { type: 'SEARCH_VOIDED' };
  } else {
    return dispatch(fetchAction(value, nextPageUrl));
  }
};
