import { request } from '_utils/api';

export const getTileTemplates = () => request('get', '/tileTemplate');

export const searchTileTemplates = query =>
  request('get', `/search?expand=all${query ? `&q=${query}` : ''}`);

export const getTileTemplate = id => request('get', `/tileTemplate/${id}`);

export const createTileTemplate = data => {
  return request('post', '/tileTemplate', { body: data });
};

export const updateTileTemplate = selected =>
  request('put', `/tileTemplate/${selected.id}`, { body: selected });

export const deleteTileTemplate = id => request('del', `/tileTemplate/${id}`);
