import R from '_utils/ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as awsAmplifyReact from 'aws-amplify-react/dist/Auth';
import Amplify from '@aws-amplify/core';
import AmplifyCustomUi from 'aws-amplify-react-custom-ui';
import { Auth } from 'aws-amplify';
import Api from '@aws-amplify/api';
import SharedApi from 'shared-code/lib/utils/api';

import {
  API_HOST,
  AUTH_USER_POOL_ID,
  AUTH_USER_POOL_WEB_CLIENT_ID,
  PUBLIC_API_HOST,
} from '_utils/env';
import { attemptSetCurrentUser } from '_thunks/user';

import { MainContainer } from './mainContainer';

Amplify.configure({
  Auth: {
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'sixtilesAPI',
        endpoint: API_HOST,
      },
      {
        name: 'sixtilesPublicAPI',
        endpoint: PUBLIC_API_HOST,
      },
    ],
  },
});

AmplifyCustomUi.configure(awsAmplifyReact);
SharedApi.configure({ Auth, Api });

const mapStateToProps = R.pick(['alerts', 'user']);

const mapDispatchToProps = dispatch => ({
  setCurrentUser: () => dispatch(attemptSetCurrentUser()),
});

export const Main = connect(mapStateToProps, mapDispatchToProps)(
  withRouter(MainContainer),
);

export default { Main };
