import { connect } from 'react-redux';
import { attemptSearchPublicShelves } from '_thunks/publicShelves';

import { SearchContainer } from './container';

function tileSelector(state, id) {
  return state.tileTemplates.byId[id];
}

const mapStateToProps = (state, props) => {
  const query = state.publicShelves.search || '';

  return {
    tileSelector,
    query,
    items:
      (state.pagination.publicShelves[query] &&
        state.pagination.publicShelves[query].ids) ||
      [],
    isFetching:
      state.pagination.publicShelves[query] &&
      state.pagination.publicShelves[query].isFetching,
  };
};

const mapDispatchToProps = dispatch => ({
  search: data => dispatch(attemptSearchPublicShelves(data)),
});

export const PublicShelvesSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchContainer);

export default { PublicShelvesSearch };
