export const SET_TILE_EDIT_FORM = 'SET_TILE_EDIT_FORM';
export const UPDATE_TILE_EDIT_DATA = 'UPDATE_TILE_EDIT_DATA';
export const UPDATE_TILE_EDIT_IMAGE = 'UPDATE_TILE_EDIT_IMAGE';
export const CLEAR_TILE_EDIT_FORM = 'CLEAR_TILE_EDIT_FORM';
export const RESET_TILE_EDIT_IMAGE = 'RESET_TILE_EDIT_IMAGE';

export const setTileEditForm = activeItem => ({
  type: 'UPDATE_FORM_DATA',
  key: 'tile',
  payload: {
    activeItem,
  },
});

export const updateTileEditImage = data => ({
  type: UPDATE_TILE_EDIT_IMAGE,
  data,
});

export const updateTileEditData = data => ({
  type: 'UPDATE_FORM_DATA',
  key: 'tile',
  payload: {
    data,
  },
});

export const clearTileEditForm = activeItem => ({
  type: 'UPDATE_FORM_DATA',
  key: 'tile',
  payload: { reset: true, activeItem },
});
