import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import classNames from 'classnames';
import { attemptMoveTop6Item } from '_store/thunks/top6';
import ItemTypes from './itemTypes';

class PriorityPositionTop6 extends Component {
  render() {
    const { connectDropTarget, isOver, canDrop, children } = this.props;
    const filled = !!children;
    return connectDropTarget(
      <div className={classNames('priority-position-container', {
        filled
      })}>
        <div
          className={classNames('position', {
            'drop-zone': !isOver && canDrop,
            'drop-over': isOver && canDrop,
            filled: filled && !canDrop,
          })}
          style={{
            width: '134px',
            height: '96px',
          }}
        >
          {children}
        </div>
      </div>,
    );
  }
}

export default DropTarget(
  [ItemTypes.TOP6_ITEM, ItemTypes.CARD],
  {
    canDrop(props, monitor) {
      const item = monitor.getItem();
      if (item.boardId === props.boardId && props.y === item.top6Position)
        return false;
      return true;
    },

    drop(props, monitor) {
      const item = monitor.getItem();
      props.dispatch(
        attemptMoveTop6Item(
          item.packTemplateId,
          item.top6Position, // current pos
          props.y, // new pos
          props,
          item,
        ),
      );
    },
  },
  (connect, monitor) => {
    const item = monitor.getItem();
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      isDragging: !!item,
    };
  },
)(PriorityPositionTop6);
