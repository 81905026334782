import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Journeys } from '_components';
import { TwoWayModalHeader } from '../modal/twoWayHeader';
import { boundMethod } from 'autobind-decorator';

class _TileJourneysModal extends Component {
  constructor(props) {
    super(props);
    this.journey = React.createRef();
  }

  @boundMethod
  buildHeader() {
    const { onClose, title } = this.props;

    return <TwoWayModalHeader title={title} onClose={onClose} />;
  }

  @boundMethod
  buildJourneyContent() {
    return (
      <div className="modal-content no-scroll">
        <Journeys
          {...this.props}
          ref={this.journey}
          onClose={this.props.onClose}
        />
      </div>
    );
  }

  render() {
    const { tile, ...rest } = this.props;
    return (
      <Modal maxWidth={1280} {...rest}>
        {this.buildHeader()}
        {this.buildJourneyContent()}
      </Modal>
    );
  }
}

export const TileJourneysModal = connect()(_TileJourneysModal);

export default { TileJourneysModal };
