import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Popover from '@material-ui/core/Popover';
import { TileContainer } from './container';
import { SuggestionForm } from '_components';
import {
  attemptClearSuggestion,
  attemptCompleteCreateSuggestion,
  attemptUpdateSuggestionData,
} from '_store/thunks/tileTemplates';

class _CustomTile extends Component {
  componentDidMount() {
    this.setState({ anchorEl: this.customTile });
  }
  state = {
    anchorEl: null,
  };

  togglePopout = () => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : this.customTile,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.props.clearSuggestion();
  };

  createSuggestion = () => {
    return this.props.createSuggestion({
      position: this.props.position,
      bucketId: this.props.listId,
      item: this.props.data,
    });
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div
        variant="contained"
        aria-owns={'custom-tile'}
        aria-haspopup="true"
        ref={c => (this.customTile = c)}
        onClose={this.handleClose}
        className={classnames('tile custom-tile', {})}
      >
        <TileContainer {...this.props} item={this.props.data} />
        <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          transformOrigin={{
            // horizontal: '10px',
            vertical: 'top',
          }}
          style={{ left: 10 }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <SuggestionForm
            handleClose={this.handleClose}
            data={this.props.data}
            createSuggestion={this.createSuggestion}
            updateFormData={this.props.updateFormData}
          />
        </Popover>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearSuggestion: () => dispatch(attemptClearSuggestion()),
    createSuggestion: data => dispatch(attemptCompleteCreateSuggestion(data)),
    updateFormData: data => dispatch(attemptUpdateSuggestionData(data)),
  };
};

const mapStateToProps = (state, data) => {
  return {
    data: state.suggestion.tileTemplate || {},
  };
};

export const CustomTile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_CustomTile);
