import { schema } from 'normalizr';

import { normalize } from 'normalizr';
import { parseNormalizedData } from '_utils/normalize';

export const tileTemplate = new schema.Entity('tileTemplates');

export const normalizeTileTemplates = data => {
  const normalizedData = normalize(data, [tileTemplate]);

  const { tileTemplateIds = [], tileTemplates = {} } = parseNormalizedData(
    normalizedData,
    'tileTemplate',
  );

  return { tileTemplateIds, tileTemplates };
};
