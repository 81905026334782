/* eslint-disable no-undef */
import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

function debounce(func) {
  let timeout;
  return function(...args) {
    const context = this;

    const lastCall = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(lastCall, 100);
  };
}

export const withScrollMonitor = TheComponent => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isScrolling: false,
        direction: null,
        isScrolledToBottom: false,
        isScrolledToTop: true,
        isCloseToTop: true,
      };
      this.container = new React.createRef();
    }

    componentWillUnmount() {
      if (!this.container || !this.container.content) return;
      const element = this.container.current;
      element.removeEventListener('ps-scroll-up', this.scrollingUp);
      element.removeEventListener('ps-scroll-down', this.scrollingDown);
      element.removeEventListener('ps-y-reach-start', this.startReached);
      element.removeEventListener(`ps-scroll-right`, this.scrollingRight);
      element.removeEventListener(`ps-scroll-left`, this.scrollingLeft);
      element.removeEventListener(`ps-x-reach-start`, this.startReached);
    }

    setEventListeners = (isHorizontal = false) => {
      if (!this.container || !this.container.current) return null;
      const element = this.container.current;
      this.scrollbar = new PerfectScrollbar(element, {
        maxScrollbarLength: 200,
        suppressScrollY: isHorizontal,
      });
      if (isHorizontal) {
        element.addEventListener(`ps-scroll-right`, this.scrollingRight);
        element.addEventListener(`ps-scroll-left`, this.scrollingLeft);
        element.addEventListener(`ps-x-reach-start`, this.startReached);
      } else {
        element.addEventListener('ps-scroll-up', this.scrollingUp);
        element.addEventListener(`ps-scroll-down`, this.scrollingDown);
        element.addEventListener(`ps-y-reach-start`, this.startReached);
      }
    };

    scrollingUp = () => {
      this.setState({
        isScrolledToTop: false,
        isScrolling: true,
        direction: 'up',
      });
      this.setScrollOff();
    };

    scrollingDown = () => {
      if (
        this.scrollbar.lastScrollTop > 30 &&
        (!this.state.isScrolling || this.state.direction !== 'down')
      ) {
        this.setState({
          isScrolledToTop: false,
          isScrolling: true,
          direction: 'down',
        });
        this.setScrollOff();
      }
    };
    scrollingRight = () => {
      this.setState({
        isScrolledToTop: false,
        isScrolling: true,
        direction: 'down',
      });
      this.setScrollOff();
    };

    scrollingLeft = () => {
      if (
        this.scrollbar.lastScrollTop > 30 &&
        (!this.state.isScrolling || this.state.direction !== 'left')
      ) {
        this.setState({
          isScrolledToTop: false,
          isScrolling: true,
          direction: 'left',
        });
        this.setScrollOff();
      }
    };

    startReached = () => {
      this.setState({ isScrolledToTop: true, isScrolling: false });
      this.setScrollOff();
    };

    setScrollOff = debounce(() => {
      if (this.state.isScrolling) {
        this.setState({
          isScrolling: false,
          lastScrollTop: null,
        });
      }
    });

    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <TheComponent
          {...rest}
          scrollRef={this.container}
          isScrolling={this.state.isScrolling}
          isScrollingDown={this.state.direction === 'down'}
          isScrollingUp={this.state.direction === 'up'}
          isScrolledToTop={this.state.isScrolledToTop}
          setEventListeners={this.setEventListeners}
          direction={this.state.direction}
        />
      );
    }
  }
  return HOC;
};

export default { withScrollMonitor };
