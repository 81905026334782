import { connect } from 'react-redux';

import { attemptCreatePack, attemptUnsubscribeFromPack } from '_thunks/packs';

import { Pack as PackContainer } from 'shared-code';

const mapStateToProps = (state, props) => {
  const {
    pack = {},
    ui: { activeJourney },
    activePack,
    onClick,
    onSubscribe,
  } = props;

  const tilePack = state.packs.byId[pack.id] || {};

  return {
    ...pack,
    'data-id': pack.id,
    imgurl: pack.imgurlCustom,
    isActive: activePack && activePack.id === pack.id,
    bgColor: activeJourney.hexColour,
    nounColor: activeJourney.nounHexColour,
    onClick,
    onSubscribe,
    tilePack,
    packId: tilePack.id,
    isSubscribed: tilePack.status === 'SUBSCRIBED',
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onSubscribe: params => {
    const { id, isSubscribed, packId } = params;

    if (!isSubscribed) {
      return dispatch(
        attemptCreatePack(props.ui.activeJourney.id, {
          id: packId,
          packTemplateId: id,
          status: 'SUBSCRIBED',
        }),
      );
    } else {
      return dispatch(
        attemptUnsubscribeFromPack(props.ui.activeJourney.id, {
          id: packId,
          packTemplateId: id,
          status: 'UNSUBSCRIBED',
        }),
      );
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PackContainer);
