import { createPack, updatePack } from '_api/packs';
import { addPack, removePack } from '_actions/packs';

import { dispatchError } from '_utils/api';

export const attemptCreatePack = (tileId, params) => (dispatch, getState) => {
  const tilePack = getState().packs.byId[params.id];
  if (params.id) {
    const data = { ...tilePack, ...params };
    return updatePack(tileId, data)
      .then(data => {
        dispatch(addPack(tileId, data));
        return data;
      })
      .catch(dispatchError(dispatch));
  }
  return createPack(tileId, params)
    .then(data => {
      dispatch(addPack(tileId, data));
      return data;
    })
    .catch(dispatchError(dispatch));
};

export const attemptUnsubscribeFromPack = (tileId, data) => dispatch =>
  updatePack(tileId, data)
    .then(data => {
      dispatch(removePack(tileId, data));
      return data;
    })
    .catch(dispatchError(dispatch));
