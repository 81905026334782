import update from 'immutability-helper';
import * as R from 'ramda';

import {
  SET_COLLECTION_TYPES,
  UPDATE_COLLECTION_TYPE,
  ADD_COLLECTION_TYPE,
  REMOVE_COLLECTION_TYPE,
} from '_actions/collectionTypes';

export function collectionType(state = {}, action) {
  switch (action.type) {
    case ADD_COLLECTION_TYPE:
      return update(state, { $set: action.collectionType });
    case UPDATE_COLLECTION_TYPE:
      return update(state, { $merge: action.collectionType });
    default:
      return state;
  }
}

export default function collectionTypes(state = [], action) {
  const index = R.findIndex(R.propEq('id', action.id), state);
  const updatedAtIndex = {
    $splice: [[index, 1, collectionType(state[index], action)]],
  };

  const removeAtIndex = {
    $splice: [[index, 1]],
  };

  switch (action.type) {
    case SET_COLLECTION_TYPES:
      return update(state, { $set: action.collectionTypes });
    case ADD_COLLECTION_TYPE:
      return update(state, { $push: [collectionType(undefined, action)] });
    case UPDATE_COLLECTION_TYPE:
      return update(state, updatedAtIndex);
    case REMOVE_COLLECTION_TYPE:
      return update(state, removeAtIndex);
    default:
      return state;
  }
}
