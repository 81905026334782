import update from 'immutability-helper';
import { SET_TILES, UPDATE_TILE, ADD_TILE, REMOVE_TILE } from '_actions/tiles';
import { ADD_PACK } from '_actions/packs';

function updateIds(state = [], ids = []) {
  if (ids.length === 0) return state;
  return [...new Set(state.concat(ids))];
}

export default function tiles(state = {}, action = {}) {
  switch (action.type) {
    case SET_TILES:
      return update(state, { $set: action.tiles });
    case ADD_TILE:
      return update(state, {
        [action.id]: { $set: action.tile },
      });
    case UPDATE_TILE:
      return update(state, {
        [action.id]: {
          $merge: action.tile,
        },
      });
    case ADD_PACK:
      return update(state, {
        [action.tileId]: {
          packs: {
            $set: updateIds(state[action.tileId].packs, [action.id]),
          },
        },
      });
    // case UPDATE_PACK:
    //   return update(state, {
    //     [action.tileId]: {
    //       packs: {
    //         $set: [...(state[action.tileId].packs || []), action.pack],
    //       },
    //     },
    //   });
    // case REMOVE_PACK:
    //   return update(state, {
    //     [action.tileId]: {
    //       packs: {
    //         $set: state[action.tileId].packs.filter(x => x.id !== action.id),
    //       },
    //     },
    //   });
    case REMOVE_TILE:
      return update(state, {
        $unset: [action.id],
      });
    default:
      return state;
  }
}
