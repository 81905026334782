import { schema } from 'normalizr';

import { normalize } from 'normalizr';
import { parseNormalizedData } from '_utils/normalize';

export const publicShelf = new schema.Entity('publicShelves');

export const normalizePublicShelves = data => {
  const normalizedData = normalize(data, [publicShelf]);

  const { publicShelfIds = [], publicShelves = {} } = parseNormalizedData(
    normalizedData,
    'publicShelf',
    'publicShelves',
  );

  return { publicShelfIds, publicShelves };
};
