import React, { Component } from 'react';
import routes from './routes';
import { Switch, Route, Redirect } from 'react-router';
import AmplifyCustomUi from 'aws-amplify-react-custom-ui';
import {
  AuthLogin,
  AuthSignup,
  AuthConfirmSignup,
  AuthConfirmSignin,
} from '_components';
import { Auth } from 'aws-amplify';
// import Api from '@aws-amplify/api';
import publicRoutes from './publicRoutes';
import Main from './main';

export class MainContainer extends Component {
  async componentDidMount() {
    this.props.setCurrentUser();
  }

  componentWillMount() {
    AmplifyCustomUi.setSignIn(AuthLogin);
    AmplifyCustomUi.setSignUp(AuthSignup);
    AmplifyCustomUi.setConfirmSignUp(AuthConfirmSignup);
    AmplifyCustomUi.setConfirmSignIn(AuthConfirmSignin);
  }

  componentDidUpdate() {
    this.scrollToTop();
  }

  signOut = () => {
    const { onStateChange } = this.props;
    Auth.signOut().then(() => {
      onStateChange('signIn');
    });
  };

  getTitle = () =>
    routes.find(
      route =>
        route.path.replace('/', '') ===
        this.props.location.pathname.split('/')[1],
    ).title || '';

  scrollToTop = () => window.scrollTo(0, 0);

  pageTitle = () =>
    <span>
      {this.getTitle()}
    </span>;

  render() {
    return (
      <Switch>
        {!this.props.user &&
          publicRoutes.map((publicRoute, i) =>
            <Route key="1" {...publicRoute} {...this.props} />,
          )}
        <Route
          key="2"
          path="*"
          signOut={this.signOut}
          render={routeProps => <Main {...routeProps} {...this.props} />}
        />
        <Redirect to="/" />
      </Switch>
    );
  }
}

export default { MainContainer };
