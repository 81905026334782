import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export function Logo({ alt }) {
  return (
    <Link to="/">
      <div className={classNames('page-logo', { 'page-logo-alt': alt })} />
    </Link>
  );
}

export default { Logo };
