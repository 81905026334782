// got from https://gist.github.com/bgrins/6194623
export function isDataURL(s) {
  return !!s.match(isDataURL.regex);
}
isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
/* eslint-enable */

export function safelyParseJSON(json) {
  // This function cannot be optimised, it's best to
  // keep it small!
  let parsed;

  try {
    parsed = JSON.parse(json);
  } catch (e) {
    // error
  }

  return parsed; // Could be undefined!
}
