import { normalizeTilePositions } from '_normalize/tilePositions';

export const SET_SHELVES = 'SET_SHELVES';
export const ADD_SHELF = 'ADD_SHELF';
export const UPDATE_SHELF = 'UPDATE_SHELF';
export const REMOVE_SHELF = 'REMOVE_SHELF';
export const SET_SHELF_DATA = 'SET_SHELF_DATA';
export const UPDATE_SHELF_TILE_POSITIONS = 'UPDATE_SHELF_TILE_POSITIONS';
export const REMOVE_SHELF_TILE_POSITION = 'REMOVE_SHELF_TILE_POSITION';
export const GET_SHELF_TILE_POSITIONS = 'GET_SHELF_TILE_POSITIONS';
export const UPDATE_SHElF = 'UPDATE_SHElF';

export function setShelves(shelves) {
  shelves = shelves.map(s => {
    return {
      ...s,
      ...normalizeTilePositions(s.tilePositions || []),
    };
  });
  return {
    type: SET_SHELVES,
    shelves,
  };
}

export const addShelf = shelf => ({
  type: ADD_SHELF,
  id: shelf.id,
  shelf,
});

export const removeShelf = id => ({
  type: REMOVE_SHELF,
  id,
});

export const updateShelf = shelf => ({
  type: UPDATE_SHELF,
  id: shelf.id,
  shelf,
});

export const getShelfTilePositions = (id, data) => ({
  type: GET_SHELF_TILE_POSITIONS,
  id,
  data: {
    ...normalizeTilePositions(data),
  },
});

export const setShelfTilePositions = ({ id, tilePositions }) => ({
  type: SET_SHELF_DATA,
  id,
  data: {
    ...normalizeTilePositions(tilePositions),
  },
});

export const updateShelfTilePositions = (id, data) => ({
  type: UPDATE_SHELF_TILE_POSITIONS,
  id,
  ...normalizeTilePositions(data),
});

export const removeShelfTilePosition = (id, tilePosition) => ({
  type: REMOVE_SHELF_TILE_POSITION,
  id,
  tilePosition,
});
