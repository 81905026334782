import validate from 'validate.js';

// validate.js regex method (format) is broken with complex regex expressions, custom version to fix it
validate.validators.regex = function(value, options) {
  let regExp = new RegExp(options.pattern);

  if (!regExp.test(value)) {
    return options.message;
  }
};

const authDefault = {
  username: {
    presence: {
      allowEmpty: false,
    },
    length: {
      minimum: 4,
      maximum: 32,
    },
  },
  password: {
    presence: true,
    regex: {
      pattern: '(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])',
      message: 'Must contain a capital, lowercase, and number',
    },
    length: {
      minimum: 8,
    },
  },
};

export const loginConstraints = {
  ...authDefault,
};

export const signupConstraints = {
  ...authDefault,
  name: {
    presence: {
      allowEmpty: false,
    },
  },
  email: {
    email: true,
  },
  termsChecked: {
    presence: {
      message: '^You need to accept the terms',
    },
    inclusion: {
      within: [true],
      message: '^You need to accept the terms',
    },
  },
};

export default {
  loginConstraints,
  signupConstraints,
};
