import React, { Component } from 'react';
import { connect } from 'react-redux';
import { convertHex } from '../../utils';
import {
  ImageUploadModal,
  getImageScaledToCanvas,
  updateEditImage,
  imageDimensions,
  TileImageCropper,
} from 'shared-code';
import {
  // setTileEditForm,
  updateTileEditData,
  updateTileEditImage,
} from '../../../store/actions/tileEdit';
import { attemptUpdateTile } from '../../../store/thunks/tiles';
const modalStyles = {
  dialogPaper: {
    borderRadius: '40px !important',
    backgroundColor: 'rgb(227, 237, 247) !important',
    boxShadow: '12px 8px 16px rgba(136, 165, 191, 0.1)',
  },
  backDrop: {
    backgroundColor: 'rgba(227, 237, 247, 0.5) !important',
  },
};
class TileEdit extends Component {
  state = {};

  updateFormImage = data => {
    this.props.dispatch(updateTileEditImage(data));
  };

  updateCroppedImage = () => {
    const {
      newImage: { canvasImage, scale },
      dispatch,
      entityKey,
    } = this.props;

    if (!canvasImage || (canvasImage && !canvasImage.resource)) {
      return;
    }
    let croppedImage = null;
    // calculate cropped image
    const canvas = getImageScaledToCanvas({
      ...imageDimensions[entityKey],
      image: canvasImage,
      rotate: 0,
      scale: !scale && scale !== 0 ? 1 : scale,
    });

    croppedImage = canvas.toDataURL();
    dispatch(updateEditImage(entityKey, { croppedImage }));
  };

  onSave = () => {
    const { dispatch, onClose } = this.props;

    this.updateCroppedImage();
    return dispatch(attemptUpdateTile())
      .then(() => {
        onClose();
      })
      .catch(err => {});
  };

  onClose = () => {
    this.props.onClose();
  };

  updateFormData = data => {
    this.props.dispatch(updateTileEditData(data));
  };

  render() {
    const { form, newImage, tile, images, dispatch } = this.props;
    const tileStyle = {};
    tileStyle['--hex-color'] = form.data.hexColour;
    tileStyle['--rgb-color'] = convertHex(form.data.hexColour);
    let defaultImg = tile.imgurlReference || tile.imgurlDefault || '';
    if (!defaultImg.indexOf('xhr') === -1) defaultImg = `${defaultImg}`;

    return (
      <ImageUploadModal
        ImageCropper={TileImageCropper}
        onClose={this.onClose}
        images={images}
        newImage={newImage}
        data={form.data}
        open={this.props.open}
        dispatch={dispatch}
        entityKey={'tile'}
        isDirty={newImage.imageHasChanged}
        onSave={this.onSave}
        modalStyles={modalStyles}
        showCheckboxes={newImage.canMakePublic}
        updateFormData={this.updateFormData}
      />
    );
  }
}

function select(state, props) {
  const tile = props.tile || {};
  return {
    ...(state.categories.find(x => x.id === tile.categoryId) || {
      images: [],
    }),
    newImage: state.editImage.tile,
    categoryId: tile.categoryId,
    form: state.form.tile,
    tile,
    entityKey: 'tile',
  };
}

export default connect(select, null, null, { withRef: true })(TileEdit);
