import React, { Component } from 'react';
import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import flow from 'lodash/flow';
import { TrashIcon } from 'shared-code';
import insetImage from "../../assets/images/inset.png";
import overlayImage from "../../assets/images/overlay.png";

class _TrashTile extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }
  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      isOver,
    } = this.props;

    const opacity = isDragging ? 1 : 1;

    return connectDragSource(
      connectDropTarget(
        <div
          ref={c => (this.tile = c)}
          className={classNames('trash tile', { isOver })}
          style={{ opacity, color: isOver ? 'red' : null }}
        >
            <div className="tile-container">
                <div className="tile-image-inset" style={{ backgroundImage: `url(${insetImage})`}} />
                <div className={classNames("tile-image-overlay", {active: isOver})} style={{ backgroundImage: `url(${overlayImage})`}} />
              <TrashIcon pressed={isOver} />
            </div>
        </div>,
      ),
    );
  }
}

const cardSource = {
  canDrag: function(props, monitor) {
    return false;
  },
  beginDrag: function(props, monitor) {},
};

const cardTarget = {
  canDrop(props, monitor) {
    const item = monitor.getItem();

    if (item.listType === 'SEARCH') {
      return false;
    }

    return true;
  },
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      return {};
    }

    const item = monitor.getItem();

    if (item.listType === 'SEARCH') return {};

    return { delete: true };
  },
  hover(props, monitor, component) {
    const item = monitor.getItem();

    if (item.listType === 'SEARCH') {
      return {
        isOver: false,
      };
    }

    return {
      isOver: monitor.isOver(),
    };
  },
};

export const TrashTile = flow(
  DropTarget('TILE', cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver:
      monitor.getItem() &&
      monitor.getItem().listType !== 'SEARCH' &&
      monitor.isOver(),
  })),
  DragSource('TILE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  })),
)(_TrashTile);
