import { attemptUpdateShelfTilePositions } from './shelves';
import { attemptUpdateBucketTilePositions } from './buckets';
import { getNextAvailblePosition } from '_utils/tileMovement';

export const getTilePosUpdateAction = type => {
  switch (type) {
    case 'SHELF':
      return attemptUpdateShelfTilePositions;
    case 'BUCKET':
      return attemptUpdateBucketTilePositions;
    default:
      return null;
  }
};

export const updateTilePositions = ({ source, target }) => async dispatch => {
  const resp = await dispatch(
    getTilePosUpdateAction(target.type)({
      id: target.id,
      tilePositions: target.positions,
    }),
  );

  if (source.positions.length > 0) {
    await dispatch(
      getTilePosUpdateAction(source.type)({
        id: source.id,
        tilePositions: source.positions,
      }),
    );
  }
  return resp;
};

export const getNewTilePositions = (source, target, ids) => {
  if (target.placeholder) {
    return moveToPlaceholder(source, target);
  }

  return moveToOccupied(source, target, ids);
};

const moveToPlaceholder = (source, target) => {
  let newPositions = {
    target: {
      type: target.listType,
      id: target.listId,
      positions: [],
    },
    source: {
      type: source.listType,
      id: source.listId,
      positions: [],
    },
  };

  const isSameList = source.listType === target.listType;
  if (!isSameList) {
    // move to new list
    newPositions.target.positions = [
      { id: source.item.id, position: target.position },
    ];
    if (source.listType === 'SHELF') {
      // delete or update source pos to null
      newPositions.source.positions = [
        { id: null, position: source.item.position },
        { id: source.item.id, position: -1 },
      ];
      newPositions.target.positions = [
        { id: source.item.id, position: target.position },
        { id: null, position: source.bucketPos },
      ];
    }
  } else {
    newPositions.target.positions = [
      { position: target.position, id: source.item.id },
      { position: source.item.position || source.position, id: null },
    ];
  }

  return newPositions;
};

const moveToOccupied = (source, target, ids) => {
  let newPositions = {
    target: {
      type: target.listType,
      id: target.listId,
      positions: [],
    },
    source: {
      type: source.listType,
      id: source.listId,
      positions: [],
    },
  };
  const isSameList = source.listType === target.listType;

  if (isSameList) {
    // swap
    newPositions.target.positions = [
      { position: target.position, id: source.item.id || source.id },
      { position: source.item.position || source.position, id: target.item.id },
    ];
  } else {
    const nextAvailPos = getNextAvailblePosition(
      ids,
      target.position,
      target.listType,
    );

    newPositions.target.positions = [
      { position: target.position, id: source.item.id },
      {
        position: nextAvailPos, // might be null if shelf and full
        id: target.item.id,
      },
    ];

    if (source.listType === 'SHELF') {
      // clear original bucket position
      newPositions.target.positions = [
        ...newPositions.target.positions,
        { position: target.bucketPos, id: source.item.id },
      ];
      // delete or update source pos to null
      newPositions.source.positions = [
        { id: null, position: source.item.position || source.position },
        { id: source.item.id, position: -1 },
      ];
      // and clear bucket
    }
  }

  return newPositions;
};
