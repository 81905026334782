import React from 'react';

export function Button({ onClick, label }) {
  return (
    <button onClick={onClick} className="btn button">
      {label}
    </button>
  );
}

export default { Button };
