import update from 'immutability-helper';

import {
  SET_CARDS,
  UPDATE_CARD,
  ADD_CARD,
  REMOVE_CARD,
  UPDATE_CARDS,
} from '_actions/cards';
import { SET_TILES, REMOVE_TILE } from '_actions/tiles';
import { SET_PACKS } from '_actions/packs';
import { ADD_CARD_FIELDS, REMOVE_CARD_FIELDS } from '_actions/fields';

function updateIds(state, ids = []) {
  if (ids.length === 0) return state;
  return [...new Set(state.concat(ids))];
}

function removeField(state, { id, field }) {
  let newState = state;
  field.forEach(fieldTemplate => {
    newState = update(newState, {
      byId: {
        [id]: {
          field: {
            $set: state.byId[id].field.filter(x => x !== fieldTemplate),
          },
        },
      },
    });
  });
  return newState;
}

function updateCardsById(state, { cardIds, cards }) {
  let newState = state;

  cardIds.forEach(cardId => {
    newState = update(newState, {
      [cardId]: { $set: cards[cardId] },
    });
  });
  return newState;
}

function removeIds(state, ids = []) {
  const newState = state;
  if (ids.length === 0) return state;
  return newState.filter(x => !ids.includes(x));
}

function removeCardsById(state, ids = []) {
  let newState = state;
  ids.forEach(id => {
    newState = update(newState, {
      [id]: {
        $set: undefined,
      },
    });
  });
  return newState;
}

function removeAllByPackId(state, action) {
  const packTemplateIds = action.packTemplateIds || [];
  let cardTemplateIds = [];
  packTemplateIds.forEach(packTemplatId => {
    cardTemplateIds = state.all.filter(
      x => !packTemplateIds.includes(state.byId[x].packTemplateId),
    );
  });

  return update(state, {
    all: { $set: removeIds(state.all, cardTemplateIds) },
    byId: { $set: removeCardsById(state.byId, cardTemplateIds) },
  });
}

export default function card(state = { all: [], byId: {} }, action) {
  switch (action.type) {
    case SET_TILES:
    case SET_PACKS:
    case SET_CARDS:
      return update(state, {
        all: { $set: action.cardsAll },
        byId: { $set: action.cards || {} },
      });
    case REMOVE_CARD:
      return update(state, {
        all: { $set: state.all.filter(x => x !== action.id) },
        byId: { [action.id]: { $set: undefined } },
      });
    case ADD_CARD:
      return update(state, {
        all: { $push: [action.id] },
        byId: {
          [action.id]: {
            $set: action.card,
          },
        },
      });
    case UPDATE_CARDS: {
      return update(state, {
        all: { $set: updateIds(state.all, action.cardIds) },
        byId: { $set: updateCardsById(state.byId, action) },
      });
    }
    case ADD_CARD_FIELDS:
      return update(state, {
        byId: {
          [action.id]: {
            field: {
              $set: updateIds(
                state.byId[action.id].field || [],
                action.fieldAll,
              ),
            },
          },
        },
      });
    case REMOVE_CARD_FIELDS:
      return removeField(state, action);
    case REMOVE_TILE:
      return removeAllByPackId(state, action);
    case UPDATE_CARD:
      return update(state, {
        byId: {
          [action.id]: {
            $set: {
              ...action.card,
              field: state.byId[action.id].field,
            },
          },
        },
      });
    default:
      return state;
  }
}
