import { moveTop6Item, removeTop6Item } from '../actions/cardBoard';
import { updateCard, createCard } from '../../api/cards';
import { attemptCreatePack } from '../thunks/packs';
import { updateCards } from '../actions/cards';
import { to } from 'shared-code';

export const attemptToDeleteTop6Item = (packId, props, item) => async (dispatch, getState) => {
  dispatch(
    removeTop6Item(packId, item.top6Position, item.cardTemplateId),
  );
  const byId = getState().cards.byId;
  const item1 = byId[item.cardTemplateId];
  const tileId = props.tileId;
  const tileTemplateId = props.tileTemplateId;
  let pack = getState().packs.byId[packId];
  const updatedIncomingItem = {
    ...item,
    ...item1,
    id: (item1 && item1.id) || null,
    sequence: item.sequence + 1 || item1.sequence,
    packId: pack && pack.id,
    packTemplateId: props.packId,
    cardTemplateId: item.cardTemplateId,
    tileId,
    tileTemplateId,
    top6Position: 0,
  };
  const [, updatedCard] = await to(updateCard(tileId, updatedIncomingItem));

  dispatch(updateCards(packId, [updatedCard]));

}

export const attemptMoveTop6Item = (
  packId,
  currentPosition,
  newPosition,
  props,
  item,
) => async (dispatch, getState) => {
  dispatch(
    moveTop6Item(packId, currentPosition, newPosition, item.cardTemplateId),
  );
  const byId = getState().cards.byId;
  const item1 = byId[item.cardTemplateId];
  const item2 = byId[props.boardId];
  let pack = getState().packs.byId[packId];
  let updatedPassiveItem;

  const tileId = props.tileId;
  const tileTemplateId = props.tileTemplateId;
  let updatedCards = [];

  // // subscribe to pack
  if (!pack || pack.status === 'UNSUBSCRIBED') {
    pack = await dispatch(
      attemptCreatePack(tileId, {
        id: pack && pack.id,
        tileTemplateId,
        tileId,
        packTemplateId: packId,
        status: 'SUBSCRIBED',
      }),
    );
  }

  const updatedIncomingItem = {
    ...item,
    ...item1,
    id: (item1 && item1.id) || null,
    sequence: item.sequence + 1 || item1.sequence,
    packId: pack && pack.id,
    packTemplateId: props.packId,
    cardTemplateId: item.cardTemplateId,
    tileId,
    tileTemplateId,
    top6Position: newPosition,
  };

  let apiAction = updatedIncomingItem.id ? updateCard : createCard;
  const [, cardTemplate1] = await to(apiAction(tileId, updatedIncomingItem));

  if (props.cardTemplate && props.cardTemplate.id !== item.id) {
    updatedPassiveItem = {
      ...props.cardTemplate,
      ...item2,
      sequence: item2.sequence || props.cardTemplate.sequence,
      packTemplateId: props.packId,
      id: (item2 && item2.id) || null,
      tileId,
      tileTemplateId,
      cardTemplateId: props.boardId,
      packId: pack && pack.id,
      top6Position: currentPosition,
    };
  }

  updatedCards.push(cardTemplate1);
  if (updatedPassiveItem) {
    apiAction = updatedPassiveItem.id ? updateCard : createCard;

    const [, cardTemplate2] = await to(apiAction(tileId, updatedPassiveItem));

    updatedCards.push(cardTemplate2);
  }

  dispatch(updateCards(packId, updatedCards));
};
