import { request } from '_utils/api';
import { uploadImage } from './uploadImage';

export const getShelves = () => request('get', `/shelf`);

export const getShelf = id => request('get', `/shelf/${id}`);

export const createShelf = data => {
  return request('post', '/shelf', { body: data });
};

export const editShelf = selected =>
  request('put', `/shelf/${selected.id}`, { body: selected });

export const publishShelf = id => {
  return request('post', `/shelf/${id}/publish`);
};

export const unpublishShelf = id => {
  return request('del', `/shelf/${id}/publish`);
};

export const deleteShelf = id => request('del', `/shelf/${id}`);

export const getShelfTilePositions = id =>
  request('get', `/shelf/${id}/tileposition`);

export const editShelfTilePositions = ({ id, tilePositions }) =>
  request('post', `/shelf/${id}/tileposition`, {
    body: tilePositions,
  });

export const getShelfTilePosition = ({ id, position }) =>
  request('get', `/shelf/${id}/tileposition/${position}`);

export const deleteShelfTilePosition = ({ id, position }) =>
  request('del', `/shelf/${id}/tileposition/${position}`);

export const uploadShelfImage = async ({ id, image }) => {
  const res = await getUploadImageUrl(id);

  const { uploadUrl } = res;

  return uploadImage({ uploadUrl, image }).then(() => {
    return res;
  });
};

const getUploadImageUrl = id => request('get', `/shelf/${id}/upload`);
