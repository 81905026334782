import React, { Component } from 'react';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';

export class Menu extends Component {
  state = {
    open: false,
  };

  @boundMethod
  toggleOpen() {
    this.setState({
      open: !this.state.open,
    });
  }

  handleMenuClick(option) {
    const { onClickMenu } = this.props;
    this.setState({
      open: false,
    });
    onClickMenu(option);
  }

  render() {
    const { open } = this.state;
    const { options } = this.props;

    return (
      <div className={classNames('action-menu', { active: open })}>
        <div
          className="menu-item toggler"
          icon="ellipsis-v"
          text="Menu toggled"
          color="default"
          onClick={this.toggleOpen}
        >
          <div className="inner">
            <span className="fa fa-ellipsis-v" />
          </div>
        </div>
        {options.map(opt => (
          <div
            key={opt.id}
            className={classNames('menu-item', opt.className)}
            onClick={() => this.handleMenuClick(opt.key)}
          >
            <div className="inner">
              <span className={classNames('fa', opt.icon)} />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
